import { QuizQuestionType } from '@wishi_fashion/web-capsule-sdk';

import { TextEdit } from '../form/TextEdit';
import { QuestionCategorySelector } from '../QuestionCategorySelector';
import { QuestionDetails } from '../QuestionDetailsHook';
import { QuestionTypeSelector } from '../QuestionTypeSelector';

import { DetailsSectionTemplate } from './DetailsSectionTemplate';

export const MainDetailsSection = (props: { questionDetails: QuestionDetails; disabled?: boolean }) => {
	const mainSectionElements = [
		<QuestionCategorySelector
			key="question-category-selector"
			questionCategory={props.questionDetails.question.category}
			setQuestionCategory={props.questionDetails.questionSetters.setQuestionCategory}
			disabled={props.disabled}
		/>,
		<QuestionTypeSelector
			key="question-type-selector"
			questionType={props.questionDetails.question.type}
			setQuestionType={props.questionDetails.questionSetters.setQuestionType}
			questionCategory={props.questionDetails.question.category}
			disabled={props.disabled}
		/>,
		<TextEdit
			key="question-name"
			id="question-name"
			label="Name"
			value={props.questionDetails.question.name}
			setValue={props.questionDetails.questionSetters.setQuestionName}
			disabled={props.disabled}
			tooltip="Internal name for the question"
		/>,
		<TextEdit
			key="question-title"
			id="question-title"
			label="Question"
			value={props.questionDetails.question.title}
			setValue={props.questionDetails.questionSetters.setQuestionTitleHandler}
			disabled={props.disabled}
			tooltip="Title for the question, as shown to the end-user"
		/>,
		<TextEdit
			key="question-subtitle"
			id="question-subtitle"
			label="Sub-copy"
			value={props.questionDetails.question.subtitle}
			setValue={props.questionDetails.questionSetters.setQuestionSubtitle}
			disabled={props.disabled}
		/>
	];

	if (QuestionTypesSupportingTextInputPlaceholder.has(props.questionDetails.question.type)) {
		mainSectionElements.push(
			<TextEdit
				key="question-input-placeholder"
				id="question-input-placeholder"
				label="Input placeholder"
				value={props.questionDetails.question.textInputPlaceholder}
				setValue={props.questionDetails.questionSetters.setQuestionTextInputPlaceholder}
				disabled={props.disabled}
			/>
		);
	}

	return (
		<DetailsSectionTemplate
			title={props.questionDetails.question.id === undefined ? 'Add Question' : 'Edit Question'}
			elementSections={[
				{
					key: 'main-details-section',
					elements: mainSectionElements
				}
			]}
		/>
	);
};

export const QuestionTypesSupportingTextInputPlaceholder = new Set<QuizQuestionType>([
	QuizQuestionType.FreeText,
	QuizQuestionType.FreeNumber,
	QuizQuestionType.SelectManyTags
]);
