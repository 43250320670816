import React from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

const PlansContainer = styled.section`
	margin-top: 61px;

	#pricing {
		position: absolute;
		margin-top: -100px;
		visibility: hidden;
	}

	h3 {
		font-size: 32px;
		line-height: 39px;
		text-align: center;
	}

	@media (max-width: 1024px) {
		background-color: #ffffff;
		padding-top: 32px;
		margin-top: 0;

		h3 {
			font-size: 18px;
			line-height: 22px;
		}
	}
`;

const PlansGrid = styled.section`
	margin-top: 20px;
`;

const stripeElement = `<stripe-pricing-table 
			pricing-table-id="prctbl_1O1hpjJtR6pTkhxOFkaW80oj" 
			publishable-key="pk_live_q3EVOKApRMkKKD9a0grafAEG">
	</stripe-pricing-table>`;

export const Plans: React.FC = () => {
	return (
		<PlansContainer>
			<Helmet>
				<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
			</Helmet>
			<a id="pricing" href="#pricing">
				&nbsp;
			</a>
			<h3>Choose the plan that's best for your business:</h3>
			<PlansGrid dangerouslySetInnerHTML={{ __html: stripeElement }} />
		</PlansContainer>
	);
};
