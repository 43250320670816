import styled from '@emotion/styled';

import { TextEdit } from './form/TextEdit';

export const QuestionImageEdit = (props: {
	id: string;
	label?: string;
	placeholder?: string;
	imageUrlValue: string;
	setImageUrlValue: (imageUrlValue: string) => void;
	disabled?: boolean;
}) => (
	<ImageWithPreviewContainer>
		<TextEdit
			id={props.id}
			label={props.label}
			value={props.imageUrlValue}
			setValue={props.setImageUrlValue}
			placeholder={props.placeholder}
			disabled={props.disabled}
		/>
		{props.imageUrlValue && <StyledImg src={props.imageUrlValue} alt={props.label} />}
	</ImageWithPreviewContainer>
);

const ImageWithPreviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
`;

const StyledImg = styled.img`
	margin-top: 12px;
	max-height: 196px;
	max-width: 100%;
	height: auto;
	width: auto;
`;
