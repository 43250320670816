const theme = {
	primaryFont: 'sans-serif',
	secondaryFont: 'sans-serif',
	mainColor: '#000000',
	white: '#FFFFFF',
	black: '#000000'
};

export const config = {
	fonts: [
		{
			family: 'Moneta',
			weight: 100,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/SantiReyMonetaLight.otf'
		},
		{
			family: 'Moneta',
			weight: 400,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/SantiReyMonetaRegular.otf'
		},
		{
			family: 'Moneta',
			weight: 700,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/SantiReyMonetaBold.otf'
		},
		{
			family: 'Moneta',
			weight: 900,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/SantiReyMonetaBlack.otf'
		},
		{
			family: 'TradeGothicNextLTPro',
			weight: 100,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/TradeGothicLTStdLight.otf'
		},
		{
			family: 'TradeGothicNextLTPro',
			weight: 400,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/TradeGothicLTStdRegular.otf'
		},
		{
			family: 'TradeGothicNextLTPro',
			weight: 900,
			url: 'https://web-chat-widget-saks-fonts.s3.amazonaws.com/TradeGothicLTStdBold.otf'
		}
	],
	quizStyle: {
		title: {
			font: theme.primaryFont,
			fontSize: '18px',
			fontWeight: 500
		},
		progressBar: {
			backgroundColor: '#EDEDED',
			completedColor: theme.mainColor,
			height: '4px',
			borderRadius: '2px'
		},
		nextButton: {
			backgroundColor: theme.mainColor,
			color: theme.white,
			border: `1px solid ${theme.mainColor}`
		},
		skipButton: {
			backgroundColor: theme.white,
			color: theme.mainColor,
			border: `1px solid ${theme.mainColor}`
		},
		tagButton: {
			borderRadius: '4px',
			border: `1px solid ${theme.mainColor}`,
			color: theme.mainColor,
			margin: '6px'
		},
		tagButtonSelected: {
			backgroundColor: theme.mainColor,
			color: theme.white
		},
		radioButtons: {
			radioLabelFontFamily: theme.primaryFont,
			radioLabelFontSize: '14px',
			radioLabelFontWeight: 'normal',
			radioButtonCircleColor: theme.mainColor,
			radioButtonFillerColor: theme.mainColor
		},
		questionButton: {
			questionButtonFontFamily: theme.primaryFont,
			questionButtonFontSize: '14px',
			questionButtonFontWeight: '500',
			questionButtonSelectedColor: theme.mainColor,
			questionButtonSelectedTextColor: theme.white
		},
		questionCheckbox: {
			questionCheckboxLabelFontFamily: theme.primaryFont,
			questionCheckboxLabelFontSize: '14px',
			questionCheckboxLabelFontWeight: '500'
		}
	},
	quizTexts: {
		skipButton: `Skip`,
		nextButton: `Next`,
		finishQuizButton: `Finish Quiz`
	},
	quizProgressBar: true,
	quizProgressCount: true,
	quizBack: true,
	quizFixedButtons: true
};
