import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { selectQuestions } from 'services/quiz';
import { useGetPartnerQuery } from 'services/user';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { SdkWrapper } from './SdkWrapper';
interface QuizPreviewProps {
	show: boolean;
	setShow: (show: boolean) => void;
}

export const QuizPreview = (props: QuizPreviewProps) => {
	const handleClose = () => props.setShow(false);

	const { data: partner } = useGetPartnerQuery();

	const { idToQuestion } = useSelector(selectQuestions);

	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});
	const firstQuestionId = selectedVersion?.firstQuestionId;

	return (
		<>
			<style>
				{`
        .preview-dialog {
          width: 512px;
          max-height: 690px;
        }
        .preview-dialog-content {
          height: 100%;
        }
        .preview-dialog-body {
          padding: 0;
        }
      `}
			</style>
			<Modal
				show={props.show}
				onHide={handleClose}
				scrollable
				dialogClassName="preview-dialog"
				contentClassName="preview-dialog-content"
			>
				<Modal.Header closeButton>
					<Modal.Title>Preview</Modal.Title>
				</Modal.Header>
				<Modal.Body
					id="demo-modal-body-container-id"
					className="preview-dialog-body"
					style={{ position: 'relative' }}
				>
					{partner == null ? (
						<ErrorContainer>Partner not set</ErrorContainer>
					) : firstQuestionId == null ? (
						<ErrorContainer>First question not set</ErrorContainer>
					) : idToQuestion == null ? (
						<ErrorContainer>Error setting up</ErrorContainer>
					) : (
						<Container>
							<SdkWrapper firstQuestionId={firstQuestionId} idToQuestion={idToQuestion} />
						</Container>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
`;

const ErrorContainer = (props: { children: React.ReactNode }) => (
	<Container>
		<div
			style={{
				fontSize: '2em',
				textAlign: 'center'
			}}
		>
			{props.children}
		</div>
	</Container>
);
