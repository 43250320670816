import React from 'react';
import styled from '@emotion/styled';

const HeaderContainer = styled.section`
	padding-top: 115px;

	@media (max-width: 1024px) {
		padding-top: 28px;
	}
`;

const Title = styled.div`
	text-align: center;
	max-width: 838px;
	margin: 0 auto;

	h2 {
		font-size: 22px;
		line-height: 34px;
	}

	h1 {
		font-size: 55px;
		line-height: 67px;
		margin-top: 8px;
	}

	@media (max-width: 1024px) {
		padding: 0 20px;

		h2 {
			font-size: 18px;
			line-height: 28px;
		}

		h1 {
			font-size: 30px;
			line-height: 36px;
			margin-top: 15px;
		}
	}
`;

const HeroContainer = styled.div`
	margin-top: 64px;
	height: 560px;
	background: url(/images/frontPage/hero_background.webp) center bottom no-repeat;

	@media (max-width: 1024px) {
		margin-top: 33px;
		background: none;
		height: 280px;
	}
`;

const Hero = styled.div`
	position: relative;
	margin: 0 auto;
	width: 450px;
	height: 528px;
	background: url(/images/frontPage/hero.png) center / contain no-repeat;

	&:before {
		position: absolute;
		width: 354px;
		height: 188px;
		right: -287px;
		top: 12px;
		background: url(/images/frontPage/hero_text.png) center bottom / contain no-repeat;
		content: '';
	}

	&:after {
		position: absolute;
		width: 229px;
		height: 213px;
		left: -139px;
		bottom: -29px;
		background: url(/images/frontPage/hero_graph.png) center bottom / contain no-repeat;
		content: '';
	}

	@media (max-width: 1024px) {
		width: 239px;
		height: 279px;

		&:before {
			display: none;
		}

		&:after {
			background-image: url(/images/frontPage/hero_graph_small.png);
			width: 140px;
			height: 120px;
			bottom: -23px;
			left: -78px;
		}
	}
`;

export const Header = () => {
	return (
		<HeaderContainer>
			<Title>
				<h2>Assisted E-Commerce at Scale</h2>
				<h1>Wishi supercharges online sales and customer retention</h1>
			</Title>
			<HeroContainer>
				<Hero />
			</HeroContainer>
		</HeaderContainer>
	);
};
