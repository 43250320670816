import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { useQuizEditAllowed } from 'routes/quiz/hooks/QuizEditAllowedHook';
import { useDeleteQuestionMutation, useDuplicateQuestionMutation, useLazyGetQuestionsQuery } from 'services/quiz';
import { selectSelectedVersionId, useGetQuizVersionsQuery, useSetFirstQuizQuestionMutation } from 'services/versions';

export const SingleQuestionActions = (props: { questionId: number }) => {
	const [setFirstQuestion] = useSetFirstQuizQuestionMutation();
	const [duplicateQuestion] = useDuplicateQuestionMutation();
	const [deleteQuestion] = useDeleteQuestionMutation();
	const [fetchQuiz] = useLazyGetQuestionsQuery();
	const quizEditAllowed = useQuizEditAllowed();
	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const onDuplicateClick = (quizVersion: number, questionId: number) => {
		duplicateQuestion({ quizVersion, questionId })
			.unwrap()
			.then(() => toast.success('Question duplicated'))
			.catch(() => toast.error('Duplicating question failed'));
	};

	const onDeleteClick = (quizVersion: number, questionId: number) => {
		deleteQuestion({ quizVersion, questionId })
			.unwrap()
			.then(() => {
				toast.success('Question Deleted');
				fetchQuiz({ quizVersion });
			})
			.catch(() => toast.error('Deleting question failed'));
	};

	const onSetFirstClick = (versionId: number, firstQuestionId: number) => {
		setFirstQuestion({ versionId, firstQuestionId })
			.unwrap()
			.then(() => toast.success('First question updated'))
			.catch(() => toast.error('Updating first question failed'));
	};

	return (
		<>
			{quizEditAllowed && !selectedVersion?.isLocked && (
				<Dropdown.Toggle as={QuestionButton} split style={{ flex: '0 0' }} variant="plain" />
			)}

			{selectedVersion && (
				<DropdownMenu>
					<Dropdown.Item onClick={() => onDuplicateClick(selectedVersion.id, props.questionId)}>
						Duplicate
					</Dropdown.Item>
					{quizEditAllowed && (
						<Dropdown.Item onClick={() => onDeleteClick(selectedVersion.id, props.questionId)}>
							Delete
						</Dropdown.Item>
					)}
					{selectedVersion.firstQuestionId !== props.questionId && (
						<Dropdown.Item onClick={() => onSetFirstClick(selectedVersion.id, props.questionId)}>
							Set first question
						</Dropdown.Item>
					)}
				</DropdownMenu>
			)}
		</>
	);
};

const QuestionButton = styled(Button)`
	background-color: #fff;
	border: 0;
	border-radius: 0;

	text-align: left;

	color: #6a5644;
	font-size: 11px;

	&:hover {
		background-color: #eee;
		border: 0;
	}
`;

const DropdownMenu = styled(Dropdown.Menu)`
	background-color: #f3f0ed;
	font-size: 14px;
`;
