import { Role } from 'services/teams';
import { useGetUserQuery } from 'services/user';

export const useVersionEditAllowed = () => {
	const { data: user } = useGetUserQuery();

	const userRole = user?.role;

	return userRole === Role.admin || userRole === Role.editor;
};
