import { partnerKeyPlaceholder, rootSplitApi } from 'redux/base-api';

import { FetchShoppersResponse } from './types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Shoppers'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getShoppers: build.query<FetchShoppersResponse, void>({
			query: () => ({
				url: `/partner/${partnerKeyPlaceholder}/sales?limit=100`
			}),
			providesTags: () => ['Shoppers']
		})
	})
});

export const { useGetShoppersQuery } = extendedApi;
