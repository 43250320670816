import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { TableBackgroundColor, TableBorderColor } from 'constants/colors';
import { Role, RoleToName, TeamMember, useDeleteTeamMemberMutation, useGetTeamMembersQuery } from 'services/teams';
import { useGetUserQuery } from 'services/user';

import { ActionButton } from 'components/general/ActionButton';
import { AddButton } from 'components/general/AddButton';
import { PageSubtitleSpan } from 'components/Page';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

import { MemberOffCanvas, MemberOffCanvasVariant } from './MemberOffcanvas';
import { PageSection, PageSubtitleContainer } from './Settings';

export const TeamManagementTable = () => {
	const [deleteMember] = useDeleteTeamMemberMutation();
	const { data: user } = useGetUserQuery();

	const { data: teamMembers = [], isFetching } = useGetTeamMembersQuery();
	const viewingUserUuid = user?.id;

	const [memberMenu, setMemberMenu] = useState<
		| { variant: MemberOffCanvasVariant.Edit; member: TeamMember }
		| { variant: MemberOffCanvasVariant.Invite; member: undefined }
		| undefined
	>(undefined);

	const onDeleteClick = (userId: string) => {
		deleteMember(userId)
			.unwrap()
			.then(() => toast.success('Deleted team member'))
			.catch(() => toast.error('Failed to delete member'));
	};

	const isActionAllowed = (member: TeamMember) => member.role === Role.admin || viewingUserUuid === member.uuid;

	if (isFetching || !teamMembers) {
		return <></>;
	}

	return (
		<PageSection>
			<PageSubtitleContainer>
				<PageSubtitleSpan>Team Management</PageSubtitleSpan>
				<AddButton
					text="New Member"
					onClick={() => {
						setMemberMenu({
							variant: MemberOffCanvasVariant.Invite,
							member: undefined
						});
					}}
				/>
			</PageSubtitleContainer>
			<TableContainer>
				<WishiTable>
					<thead>
						<tr>
							<StyledTh>Member</StyledTh>
							<StyledTh>Email</StyledTh>
							<StyledTh>Last Login</StyledTh>
							<StyledTh>Role</StyledTh>
							<StyledTh>Edit</StyledTh>
						</tr>
					</thead>
					<tbody>
						{teamMembers?.map((teamMember) => (
							<StyledTr key={teamMember.uuid}>
								<StyledTd>{`${teamMember.firstName} ${teamMember.lastName}`}</StyledTd>
								<StyledTd>{teamMember.emailAddress}</StyledTd>
								<StyledTd>
									{teamMember.lastLogin && teamMember.lastLogin.toLocaleDateString('en-US')}
								</StyledTd>
								<StyledTd>{RoleToName[teamMember.role]}</StyledTd>
								<StyledTd>
									<EditButtonsContainer>
										<ActionButton
											variant="link"
											style={{ padding: 4 }}
											onClick={() =>
												setMemberMenu({
													variant: MemberOffCanvasVariant.Edit,
													member: teamMember
												})
											}
											disabled={isActionAllowed(teamMember)}
										>
											<EditIcon height={20} width={20} stroke="black" />
										</ActionButton>
										<ActionButton
											variant="link"
											style={{ padding: 4 }}
											onClick={() => onDeleteClick(teamMember.uuid)}
											disabled={isActionAllowed(teamMember)}
										>
											<DeleteIcon height={20} width={20} />
										</ActionButton>
									</EditButtonsContainer>
								</StyledTd>
							</StyledTr>
						))}
					</tbody>
				</WishiTable>
			</TableContainer>
			<MemberOffCanvas close={() => setMemberMenu(undefined)} mode={memberMenu} />
		</PageSection>
	);
};

const TableContainer = styled.div`
	border-radius: 18px;
	border: 1px solid ${TableBorderColor};
	overflow: hidden;
`;

const WishiTable = styled(Table)`
	background-color: ${TableBackgroundColor};
	font-size: 16px;
	margin: 0;
`;

const CellAttributes = `
padding: 20px !important;

border-bottom: 1px solid ${TableBorderColor};

&:first-of-type {
  padding-left: 40px !important;
}

&:last-child {
  padding-right: 40px !important;
}
`;

const StyledTh = styled.th`
	font-weight: 400;

	${CellAttributes}
`;

const StyledTr = styled.tr``;

const StyledTd = styled.td`
	${CellAttributes}
`;

const EditButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
