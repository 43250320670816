import React from 'react';
import { AnswerGroup, AnswerOption, QuizQuestionType } from 'services/quiz';
import { v4 as uuidv4 } from 'uuid';

import { AnswerOptionEdit } from '../AnswerOptionEdit';
import { QuizQuestionCategory } from '../categories';

import { AnswerGroupsSection, QuestionTypesSupportingGroups } from './AnswerGroupsSection';
import { AnswersSectionTemplate } from './AnswersSectionTemplate';

interface IAnswerOptionsEdit {
	questionId?: number;
	questionParentsIds?: Set<number>;
	questionType: QuizQuestionType;
	questionCategory: QuizQuestionCategory;
	answerOptions: AnswerOption[];
	questionIncludeAdditionalComments: boolean;
	setAnswerOptions: (options: AnswerOption[]) => void;
	answerGroups: AnswerGroup[];
	setAnswerGroups: (groups: AnswerGroup[]) => void;
	disabled?: boolean;
	answerKeyToCategoryKeyMapping: Record<string, string>;
	setAnswerKeyToCategoryKeyMapping: (mapping: Record<string, string>) => void;
	setQuestionIncludeAdditionalComments: (val: boolean) => void;
}

export const AnswersSection: React.FC<IAnswerOptionsEdit> = (props: IAnswerOptionsEdit) => {
	if (props.questionType == null) {
		return null;
	} else if (QuestionTypesSupportingGroups.has(props.questionType)) {
		return (
			<AnswerGroupsSection
				questionId={props.questionId}
				questionParentsIds={props.questionParentsIds}
				questionType={props.questionType}
				answerOptions={props.answerOptions}
				setAnswerOptions={props.setAnswerOptions}
				answerGroups={props.answerGroups}
				setAnswerGroups={props.setAnswerGroups}
				questionCategory={props.questionCategory}
				answerKeyToCategoryKeyMapping={props.answerKeyToCategoryKeyMapping}
				setAnswerKeyToCategoryKeyMapping={props.setAnswerKeyToCategoryKeyMapping}
				questionIncludeAdditionalComments={props.questionIncludeAdditionalComments}
				setQuestionIncludeAdditionalComments={props.setQuestionIncludeAdditionalComments}
				disabled={props.disabled}
			/>
		);
	} else if (!QuestionTypesSupportingAnswerList.has(props.questionType)) {
		return null;
	}

	return (
		<AnswersSectionTemplate
			title="Answers"
			titleButtonText="Add Answer"
			titleButtonOnClick={() => props.setAnswerOptions([...props.answerOptions, { key: uuidv4() }])}
			elements={props.answerOptions.map((answer, index) => (
				<AnswerOptionEdit
					key={answer.key}
					index={index}
					answer={answer}
					updateAnswer={(updateFields: Partial<AnswerOption>) => {
						const options = [...props.answerOptions];
						options[index] = {
							...props.answerOptions[index],
							...updateFields
						};
						props.setAnswerOptions(options);
					}}
					removeAnswer={() => {
						props.setAnswerOptions(
							props.answerOptions.filter((_, currIndex) => {
								return currIndex !== index;
							})
						);
					}}
					disabled={props.disabled}
					questionId={props.questionId}
					questionType={props.questionType}
					questionCategory={props.questionCategory}
					questionParentsIds={props.questionParentsIds}
					answerKeyToCategoryKeyMapping={props.answerKeyToCategoryKeyMapping}
					setAnswerKeyToCategoryKeyMapping={props.setAnswerKeyToCategoryKeyMapping}
				/>
			))}
			questionIncludeAdditionalComments={props.questionIncludeAdditionalComments}
			setQuestionIncludeAdditionalComments={props.setQuestionIncludeAdditionalComments}
			disabled={props.disabled}
		/>
	);
};

const QuestionTypesSupportingAnswerList = new Set<QuizQuestionType>([
	QuizQuestionType.SelectManyButtons,
	QuizQuestionType.SelectManyTags,
	QuizQuestionType.SelectManyCheckbox,
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneRadio,
	QuizQuestionType.SelectOneTags
]);
