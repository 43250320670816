import React from 'react';
import styled from '@emotion/styled';

const InTheNewsContainer = styled.section`
	margin: 128px var(--fpMinSectionSideMargin) 0;

	@media (max-width: 1024px) {
		font-size: 18px;
		line-height: 23px;
		background: #ffffff;
		padding-top: 37px;
		margin-top: 68px;
	}
`;

const Title = styled.h3`
	font-size: 32px;
	line-height: 39px;
	text-align: center;

	@media (max-width: 1024px) {
		font-size: 18px;
		line-height: 22px;
	}
`;

const ArticlesGrid = styled.div`
	--fpArticlesGridGap: 28px;
	display: grid;
	grid-template-columns: calc(50% - var(--fpArticlesGridGap) / 2) 2fr;
	gap: var(--fpArticlesGridGap);
	margin-top: 33px;

	@media (max-width: 1024px) {
		display: block;
		margin-top: 30px;
	}
`;

const Article = styled.div`
	display: flex;
	flex-direction: column;
	padding: 50px;
	border-radius: var(--fpSectionBorderRadius);
	background: #ffffff;

	@media (max-width: 1024px) {
		text-align: center;
		align-items: center;
		padding: 20px;
	}
`;

const ArticleLogo = styled.img`
	align-self: flex-start;
	height: 34px;
	@media (max-width: 1024px) {
		align-self: center;
	}
`;

const ArticleTitle = styled.h4`
	font-size: 32px;
	line-height: 48px;
	margin-top: 14px;
`;

const ArticleParagraph = styled.p`
	font-size: 16px;
	line-height: 28px;
	margin-top: 11px;
	width: 90%;
`;

const ArticleLink = styled.a`
	align-self: flex-start;
	font-size: 16px;
	line-height: 25px;
	margin: 22px 0 36px 0;
	padding: 14px 28px;
	border: 1px solid var(--fpAccentColor);
	border-radius: 100px;
	color: var(--fpAccentColor);
	text-decoration: none;

	&:hover {
		background: var(--fpAccentColor);
		color: white;
	}

	@media (max-width: 1024px) {
		display: none;
	}
`;

const ArticleImage = styled.img`
	width: 100%;
	border-radius: 4px;
	margin-top: auto;

	@media (max-width: 1024px) {
		margin-top: 26px;
	}
`;

const newsItems = [
	{
		logoSrc: '/images/frontPage/forbes_logo.png',
		logoAlt: 'Forbes',
		title: 'Saks Fifth Avenue Styles A Partnership With Wishi For New Service',
		paragraph:
			'As the ultimate personalized e-commerce platform, Wishi brings high-touch styling to the Saks consumers online with shopping recommendations and guides users to the most relevant inventory',
		linkUrl:
			'https://www.forbes.com/sites/sharonedelson/2022/01/20/saks-fifth-avenue-styles-a-partnership-with-wishi-for-new-service',
		imageSrc: '/images/frontPage/forbes_article_image.webp'
	},
	{
		logoSrc: '/images/frontPage/wwd_logo.png',
		logoAlt: 'WWD',
		title: 'Farfetch Gets More Personalized With Style Adviser Service',
		paragraph:
			'Farfetch is flexing its multitasking muscle, linking with personal styling start-up Wishi to bring AI and human-vetted style recommendations to shoppers.',
		linkUrl:
			'https://wwd.com/business-news/business-features/farfetch-wishi-karla-welch-style-advisor-stephanie-phair-1234736949/',
		imageSrc: '/images/frontPage/wwd_article_image.webp	'
	}
];

export const InTheNews = () => {
	return (
		<InTheNewsContainer>
			<Title>In the news</Title>
			<ArticlesGrid>
				{newsItems.map((item, index) => (
					<Article key={index}>
						<ArticleLogo src={item.logoSrc} alt={item.logoAlt} />
						<ArticleTitle>{item.title}</ArticleTitle>
						<ArticleParagraph>{item.paragraph}</ArticleParagraph>
						<ArticleLink href={item.linkUrl} target="_blank" rel="noreferrer">
							Read Article
						</ArticleLink>
						<ArticleImage src={item.imageSrc} alt={item.title} />
					</Article>
				))}
			</ArticlesGrid>
		</InTheNewsContainer>
	);
};
