import { MutableRefObject, useState } from 'react';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CanvasRef } from 'reaflow';

import { QuizPreview } from '../../preview/QuizPreview';
import { QuizBounceModal } from '../../QuizBounceModal';
import { QuizVersionsOffcanvas } from '../../settings/quiz/QuizVersionsOffcanvas';

import { AddQuestionButton } from './AddQuestionButton';
import { GraphMenuButtonStyled } from './GraphMenuButton';
import { QuestionsGraphZoomMenu } from './QuestionsGraphZoomMenu';
import { QuestionsList } from './QuestionsList';
import { QuizDetails } from './QuizDetails';

const Container = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	padding: 16px 16px 0 16px;
	pointer-events: none;
	height: 100%;
`;

export const QuestionsGraphTopMenu = (props: { zoom: number; canvasRef: MutableRefObject<CanvasRef | null> }) => {
	const { partnersNewQuizFunnelModal } = useFlags();
	const [showFunnel, setShowFunnel] = useState<boolean>(false);
	const [showVersions, setShowVersions] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);

	return (
		<>
			<Container>
				<LeftActionsContainer>
					<QuizDetails />
					<QuestionsList />
				</LeftActionsContainer>

				<RightActionsContainer>
					{partnersNewQuizFunnelModal && (
						<GraphMenuButtonStyled style={{ marginRight: 8 }} onClick={() => setShowFunnel(true)}>
							Funnel
						</GraphMenuButtonStyled>
					)}
					<AddQuestionButton style={{ marginRight: 8 }} />
					<QuestionsGraphZoomMenu zoom={props.zoom} canvasRef={props.canvasRef} style={{ marginRight: 8 }} />
					<GraphMenuButtonStyled style={{ marginRight: 8 }} onClick={() => setShowPreview(true)}>
						Preview
					</GraphMenuButtonStyled>
					<GraphMenuButtonStyled onClick={() => setShowVersions(true)}>Versions</GraphMenuButtonStyled>
				</RightActionsContainer>
			</Container>
			<QuizVersionsOffcanvas show={showVersions} handleClose={() => setShowVersions(false)} />
			<QuizPreview show={showPreview} setShow={setShowPreview} />
			<QuizBounceModal show={showFunnel} setShow={setShowFunnel} />
		</>
	);
};

const RightActionsContainer = styled.div`
	display: flex;
	flex: 0 0;
`;

const LeftActionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
`;
