import { FC, useMemo } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useURLState } from 'hooks';
import { useGetDashboardQuery } from 'services/insights';
import { useGetPartnerQuery } from 'services/user';

import { PixelAlert } from 'components/general/PixelAlert';

import { ArrowLikeSection, CombinedSection, PageHeader, RegularSection } from './components';
import { dashboardDataMapper } from './mappers';

const Container = styled.div`
	// Variables to control <Metric /> component appearance according to section design, set here to defaults
	--metricItemPadding: 28px 40px;
	--metricTitleFontSize: 16px;
	--metricValueFontSize: 50px;
	--metricValueMargin: 6px 0 30 px 0;
	--metricSecondaryValueMargin: 0 0 15px 15px;
	--metricSeparatorMargin: 26px -15px 20px -15px;
	--metricComparisonMargin: 0;

	padding: 0 20px;
	margin: 0 auto;
	width: 1284px;
	line-height: 1;
`;

const Sections = styled.div<{ isLoading: boolean }>`
	opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
	transition: opacity 0.5s;
`;

const MetricsLoader = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
`;

export const InsightsComponent: FC = () => {
	const navigate = useNavigate();

	const { urlState } = useURLState();
	const { datePeriod, comparePeriod } = urlState;
	const { data: rawData, isFetching } = useGetDashboardQuery({ datePeriod, comparePeriod }, { skip: !datePeriod });
	const dashboardData = useMemo(() => rawData && dashboardDataMapper(rawData), [rawData]);
	const items =
		dashboardData?.conversionFunnel.items.map((i) =>
			i.header === 'Shoppers' ? { ...i, onClick: () => navigate(`${window.location.pathname}/shoppers`) } : i
		) ?? [];
	const funnelData = { title: dashboardData?.conversionFunnel.title ?? '', items };
	const { data: partner } = useGetPartnerQuery();
	const displayPixelAlert = !partner?.activePixel;
	return (
		<>
			{displayPixelAlert && <PixelAlert />}
			<Container>
				<PageHeader />
				{isFetching && (
					<MetricsLoader>
						<Spinner animation="border" />
					</MetricsLoader>
				)}
				{dashboardData && (
					<Sections isLoading={isFetching}>
						<ArrowLikeSection {...funnelData} />
						<RegularSection {...dashboardData.salesReport} />
						<CombinedSection {...dashboardData.adoptionPerformance} />
						<RegularSection {...dashboardData.sla} />
						<RegularSection {...dashboardData.boardsFeedback} />
						<RegularSection {...dashboardData.recommendations} />
					</Sections>
				)}
			</Container>
		</>
	);
};
