import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';
import { TextFieldBackgroundColor } from 'constants/colors';

import { FormGroup } from './FormGroup';
import { FormLabel } from './FormLabel';

const EmptyValue = '__empty-value__';

export const Dropdown = (
	props: {
		id: string;
		label?: string;
		selectedValue: string | undefined;
		options: { value: string; name: string; disabled?: boolean }[];
		disabled?: boolean;
		tooltip?: string;
	} & (
		| { setValue: (value: string) => void; undefinedOptionText: null }
		| {
				setValue: (value: string | undefined) => void;
				undefinedOptionText: string;
		  }
	)
) => {
	return (
		<FormGroup controlId={props.id}>
			{props.label && <FormLabel text={props.label} tooltip={props.tooltip} />}
			<FormSelect
				value={props.selectedValue}
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
					if (props.undefinedOptionText !== null && e.target.value === EmptyValue) {
						props.setValue(undefined);
					} else {
						props.setValue(e.target.value);
					}
				}}
				disabled={props.disabled}
			>
				{props.undefinedOptionText != null && (
					<option value={EmptyValue} key={EmptyValue}>
						{props.undefinedOptionText}
					</option>
				)}
				{props.options.map((option) => (
					<option value={option.value} key={option.value} disabled={option.disabled}>
						{option.name}
					</option>
				))}
			</FormSelect>
		</FormGroup>
	);
};

const FormSelect = styled(Form.Select)`
	font-size: 13px;
	background-color: ${TextFieldBackgroundColor};
	border-radius: 10px;
	border: unset;
	width: 100%;
`;
