import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from '@emotion/styled';
import { QuestionFormSectionBorder } from 'constants/colors';

interface ElementsSection {
	key: string;
	elements: React.ReactElement[];
}

export const DetailsSectionTemplate = (props: {
	elementSections: ElementsSection[];
	title: React.ReactNode;
	disabled?: boolean;
}) => {
	return (
		<SectionContainer>
			<SectionTitleContainer>{props.title}</SectionTitleContainer>
			<FormContainer fluid="true">
				{props.elementSections.map((elementSection) => (
					<Row xs={1} xl={2} className="gx-5 align-items-stretch" key={elementSection.key}>
						{elementSection.elements.map((element) => (
							<FieldCol key={element.key}>{element}</FieldCol>
						))}
					</Row>
				))}
			</FormContainer>
		</SectionContainer>
	);
};

const FieldCol = (props: { children: React.ReactNode }) => (
	<Col style={{ paddingTop: 16, paddingBottom: 16 }}>{props.children}</Col>
);

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		border-bottom: 1px solid ${QuestionFormSectionBorder};
		margin-bottom: 32px;
	}
`;

const SectionTitleContainer = styled.div`
	font-size: 26px;
	margin-bottom: 16px;
`;

const FormContainer = styled(Container)`
	margin-bottom: 32px;
`;
