import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectQuestions } from 'services/quiz';

import { Dropdown } from './form/Dropdown';
const tooltipText = "A follow-up question appears only if the user selects the corresponding answer. Each follow-up question must be individually positioned in the quiz flow using the 'lead-to' field";
export const QuestionFollowUpSelector = (props: {
	id: string;
	questionId?: number;
	questionParentsIds?: Set<number>;
	questionFollowUp: number | null;
	setQuestionFollowUp: (questionfollowUp: number | null) => void;
	disabled?: boolean;
}) => {
	const { questionIds, idToQuestion } = useSelector(selectQuestions);

	// Don't allow selecting ancestors to avoid loops in the quiz.
	// Having no loops will be verified again on server when marking a version active (but not on this call).
	const questionId = props.questionId;
	const questionParents = props.questionParentsIds;
	const currentFollowUp = props.questionFollowUp;
	const allowedFollowupQuestions = useMemo(() => {
		if (questionId == null || idToQuestion == null || questionIds == null) {
			return questionIds;
		}
		const currentFollowUps = Object.entries(idToQuestion).filter(([k, v]) => !!v.followUpTo).map(([k, v]) => +k);
		const ancestors = new Set<number>(questionParents);
		const stack: number[] = Array.from(questionParents ?? []);

		let currIndex = stack.pop();
		while (currIndex) {
			let currQuestion = idToQuestion[currIndex];

			currQuestion.parentsIds?.forEach((currQuestionParentId) => {
				if (!ancestors.has(currQuestionParentId)) {
					stack.push(currQuestionParentId);
					ancestors.add(currQuestionParentId);
				}
			});

			currIndex = stack.pop();
		}
		return questionIds?.filter((id) => id === currentFollowUp || (!ancestors.has(id) && !currentFollowUps.includes(id) && id !== questionId));
	}, [questionId, currentFollowUp, questionIds, questionParents, idToQuestion]);

	if (!idToQuestion) {
		return null;
	}

	return (
		<Dropdown
			id={props.id}
			label="Follow up question"
			tooltip={tooltipText}
			selectedValue={props.questionFollowUp?.toString() ?? undefined}
			setValue={(value: string | undefined) => {
				props.setQuestionFollowUp(value === undefined ? null : +value);
			}}
			options={
				allowedFollowupQuestions?.map((questionId) => ({
					value: questionId.toString(),
					name: idToQuestion[questionId].name ?? idToQuestion[questionId].title
				})) ?? []
			}
			disabled={props.disabled}
			undefinedOptionText="None"
		/>
	);
};
