import { ReactElement } from 'react';
import styled from '@emotion/styled';
import { ErrorColor, SuccessColor } from 'constants/colors';

import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';

export enum LoginMessageVariant {
	Success,
	Error
}

export const LoginMessage = (props: { message: string | ReactElement; variant: LoginMessageVariant }) => (
	<MessageContainer
		style={{
			backgroundColor: props.variant === LoginMessageVariant.Success ? SuccessColor : ErrorColor
		}}
	>
		{props.variant === LoginMessageVariant.Success ? <SuccessIconStyled /> : <AlertIconStyled />}
		{props.message}
	</MessageContainer>
);

const StyledIconProps = `
  height: 28px;
  width: 28px;
  flex-shrink: 0;
  margin-right: 8px;
  padding: 4px;
`;

const SuccessIconStyled = styled(SuccessIcon)`
	${StyledIconProps}
`;

const AlertIconStyled = styled(AlertIcon)`
	${StyledIconProps}
`;

const MessageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	border-radius: 2px;

	padding: 8px 12px;

	font-size: 16px;

	color: white;
`;
