import { NeutralColor } from 'constants/colors';

import { displayConditions, IdToQuestionDict, QuizQuestionUi } from './quiz.types';

export const hex2rgba = (hex = NeutralColor, alpha = 1) => {
	const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) ?? [];
	return !r ? 'rgba(187, 168, 137, 0.20)' : `rgba(${r},${g},${b},${alpha})`;
};

export const getConditionLabel = (question: QuizQuestionUi, idToQuestion: IdToQuestionDict) => {
	switch (question.condition) {
		case 'None':
		case 'DisplayOnce':
		case 'DisplayUnanswered':
			return displayConditions[question.condition];
		case 'FollowUp':
			if (question.followUpTo) {
				const qTitle = idToQuestion[question.followUpTo].name;
				const aTitle =
					idToQuestion[question.followUpTo].answers?.find((a) => a.followUpQuestionId === question.id)
						?.text ?? 'unnamed';
				return `Follow up to: ${qTitle} → ${aTitle}`;
			}
			return displayConditions[question.condition];
		default:
			return '';
	}
};
