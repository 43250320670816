import React, { useEffect, useState } from 'react';
import { AnswerGroup, AnswerOption, QuizQuestionType } from 'services/quiz';
import { v4 as uuidv4 } from 'uuid';

import { AddButton } from 'components/general/AddButton';

import { AnswerContainer, AnswerOptionEdit, AnswerSectionElementContainer, DeleteButton } from '../AnswerOptionEdit';
import { QuizQuestionCategory } from '../categories';
import { TextEdit } from '../form/TextEdit';

import { AnswersSectionTemplate } from './AnswersSectionTemplate';

interface IDropdownsOptionsEdit {
	questionId?: number;
	questionParentsIds?: Set<number>;

	questionType: QuizQuestionType | undefined;
	answerOptions: AnswerOption[];
	setAnswerOptions: (options: AnswerOption[]) => void;
	answerGroups: AnswerGroup[];
	setAnswerGroups: (groups: AnswerGroup[]) => void;
	questionIncludeAdditionalComments: boolean;
	setQuestionIncludeAdditionalComments: (val: boolean) => void;

	questionCategory: QuizQuestionCategory;
	answerKeyToCategoryKeyMapping: Record<string, string>;
	setAnswerKeyToCategoryKeyMapping: (mapping: Record<string, string>) => void;

	disabled?: boolean;
}

interface AnswerInfo {
	option: AnswerOption;
	answerIndex: number;
}

export const AnswerGroupsSection: React.FC<IDropdownsOptionsEdit> = (props: IDropdownsOptionsEdit) => {
	const [answersByGroup, setAnswersByGroup] = useState<{
		[key: string]: AnswerInfo[];
	}>({});

	const questionType = props.questionType;
	const answerOptions = props.answerOptions;
	const answerGroups = props.answerGroups;
	const setAnswerOptions = props.setAnswerOptions;
	const setAnswerGroups = props.setAnswerGroups;
	useEffect(() => {
		const newAnswersByGroup: {
			[key: string]: AnswerInfo[];
		} = {};

		if (questionType && QuestionTypesSupportingGroups.has(questionType)) {
			let newAnswers: AnswerOption[] = [];
			let newGroupKey: string | undefined;

			answerOptions.forEach((answer, answerIndex) => {
				let currAnswer = answer;
				if (currAnswer.answerGroupKey == null) {
					newGroupKey = newGroupKey ?? uuidv4();
					currAnswer = { ...answer };
					currAnswer.answerGroupKey = newGroupKey;
				}
				newAnswers.push(currAnswer);

				if (newAnswersByGroup[currAnswer.answerGroupKey] != null) {
					newAnswersByGroup[currAnswer.answerGroupKey].push({
						option: answer,
						answerIndex
					});
				} else {
					newAnswersByGroup[currAnswer.answerGroupKey] = [{ option: answer, answerIndex }];
				}
			});

			if (newGroupKey != null) {
				// Set actual on next iteration.
				setAnswersByGroup({});

				setAnswerOptions(newAnswers);
				setAnswerGroups([...answerGroups, { key: newGroupKey, description: '' }]);
			}
		}

		setAnswersByGroup(newAnswersByGroup);
	}, [questionType, answerOptions, answerGroups, setAnswerOptions, setAnswerGroups]);

	if (props.questionType == null || !QuestionTypesSupportingGroups.has(props.questionType)) {
		return null;
	}

	return (
		<AnswersSectionTemplate
			title="Answer Groups"
			titleButtonText="Add group"
			titleButtonOnClick={() => {
				const newGroupKey = uuidv4();
				props.setAnswerGroups([...props.answerGroups, { key: newGroupKey, description: '' }]);
			}}
			elements={props.answerGroups.map((group, groupIndex) => (
				<AnswerContainer key={group.key}>
					<AnswerSectionElementContainer>
						<TextEdit
							label={'Group #' + (groupIndex + 1)}
							id={`group-${group.key}`}
							value={group.description ?? ''}
							setValue={(value: string) => {
								const groups = [...props.answerGroups];
								groups[groupIndex] = {
									...props.answerGroups[groupIndex],
									description: value
								};
								props.setAnswerGroups(groups);
							}}
							disabled={props.disabled}
						/>
					</AnswerSectionElementContainer>

					{answersByGroup[group.key]?.map((answer: AnswerInfo, index: number) => (
						<AnswerSectionElementContainer key={answer.option.key}>
							<AnswerOptionEdit
								key={answer.option.key}
								index={index}
								answer={answer.option}
								updateAnswer={(updateFields: Partial<AnswerOption>) => {
									const options = [...props.answerOptions];
									options[answer.answerIndex] = {
										...props.answerOptions[answer.answerIndex],
										...updateFields
									};
									props.setAnswerOptions(options);
								}}
								removeAnswer={() => {
									props.setAnswerOptions(
										props.answerOptions.filter((_, currIndex) => {
											return currIndex !== answer.answerIndex;
										})
									);
								}}
								disabled={props.disabled}
								questionId={props.questionId}
								questionType={props.questionType}
								questionCategory={props.questionCategory}
								questionParentsIds={props.questionParentsIds}
								answerKeyToCategoryKeyMapping={props.answerKeyToCategoryKeyMapping}
								setAnswerKeyToCategoryKeyMapping={props.setAnswerKeyToCategoryKeyMapping}
							/>
						</AnswerSectionElementContainer>
					))}
					<AnswerSectionElementContainer>
						<AddButton
							text="Add Answer"
							onClick={() =>
								props.setAnswerOptions([
									...props.answerOptions,
									{ key: uuidv4(), answerGroupKey: group.key }
								])
							}
							disabled={props.disabled}
						/>
					</AnswerSectionElementContainer>
					{(answersByGroup[group.key] == null || answersByGroup[group.key].length === 0) && (
						<AnswerSectionElementContainer>
							<DeleteButton
								variant="link"
								onClick={() => {
									props.setAnswerGroups(
										props.answerGroups.filter((_, currIndex) => {
											return currIndex !== groupIndex;
										})
									);
								}}
								disabled={props.disabled}
							>
								Delete group
							</DeleteButton>
						</AnswerSectionElementContainer>
					)}
				</AnswerContainer>
			))}
			questionIncludeAdditionalComments={props.questionIncludeAdditionalComments}
			setQuestionIncludeAdditionalComments={props.setQuestionIncludeAdditionalComments}
			disabled={props.disabled}
		/>
	);
};

export const QuestionTypesSupportingGroups = new Set<QuizQuestionType>([QuizQuestionType.DropDowns]);
