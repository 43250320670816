import React, { CSSProperties, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';
import { TextFieldBackgroundColor } from 'constants/colors';

import { FormGroup } from './FormGroup';
import { FormLabel } from './FormLabel';

export const TextEdit = (props: {
	id: string;
	label?: string;
	value: string;
	setValue: (value: string) => void;
	placeholder?: string;
	disabled?: boolean;
	tooltip?: string;
	type?: 'password';
	style?: CSSProperties;
	fieldStyle?: CSSProperties;
	passwordAutoCompleteOff?: boolean;
	autoFocus?: boolean;
}) => {
	const inputElement: React.RefObject<HTMLInputElement> | null = useRef(null);
	const autoFocus = props.autoFocus;
	useEffect(() => {
		if (autoFocus && inputElement.current) {
			inputElement.current.focus();
		}
	}, [autoFocus]);

	return (
		<FormGroup controlId={props.id} style={props.style}>
			{props.label && <FormLabel text={props.label} tooltip={props.tooltip} />}
			<Field
				ref={inputElement}
				autoFocus={props.autoFocus}
				value={props.value}
				type={props.type}
				placeholder={props.placeholder}
				style={props.fieldStyle}
				autoComplete={props.passwordAutoCompleteOff ? 'new-password' : undefined}
				onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					props.setValue(e.target.value);
				}}
				disabled={props.disabled}
			/>
		</FormGroup>
	);
};

const Field = styled(Form.Control)`
	font-size: 13px;
	background-color: ${TextFieldBackgroundColor};
	border-radius: 10px;
	border: unset;

	&:focus {
		background-color: ${TextFieldBackgroundColor};
	}
`;
