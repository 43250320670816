import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Login, ProtectedRoute, RedirectHandler } from '../components';

import { InsightsComponent } from './insights/InsightsComponent';
import { InsightsWrapper } from './insights/InsightsWrapper';
import { Settings } from './settings/Settings';
import { FrontPage } from './frontPage';
import { Looks } from './looks';
import { Quiz } from './quiz';
import { Shoppers } from './shoppers';

export const RedirectPath = '/redirect';

export const AppRoutes: FC = () => {
	return (
		<Routes>
			<Route path="/" element={<FrontPage />} />
			<Route path="/login" element={<Login />} />
			<Route path={RedirectPath} element={<RedirectHandler />} />
			<Route element={<ProtectedRoute />}>
				<Route path="/quiz" element={<Quiz />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/insights" element={<InsightsWrapper />}>
					<Route path="" element={<InsightsComponent />} />
					<Route path="shoppers/" element={<Shoppers />} />
				</Route>
				<Route path="/looks" element={<Looks />} />
				<Route path="*" element={<Navigate replace to="quiz" />} />
			</Route>
		</Routes>
	);
};
