import React, { FunctionComponent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Role } from 'services/teams';
import { useGetUserQuery } from 'services/user';

import { ReactComponent as EditIcon } from '../assets/icons/edit-left-nav.svg';
import { ReactComponent as InsightsIcon } from '../assets/icons/insights.svg';
import { ReactComponent as LooksIcon } from '../assets/icons/looks.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';

const LeftNav: React.FC = () => {
	const { data: user } = useGetUserQuery();
	const userRole = user?.role;

	return (
		<LeftNavContainer>
			<WishiNavbar>
				<NavItem internalLink="/insights" description="Insights" image={InsightsIcon} />

				<NavItem internalLink="/quiz" description="Quiz" image={EditIcon} />
				<NavItem internalLink="/looks" description="Looks" image={LooksIcon} />
				{userRole === Role.admin && (
					<NavItem internalLink="/settings" description="Settings" image={SettingsIcon} />
				)}
			</WishiNavbar>
		</LeftNavContainer>
	);
};

const NavItem = (
	props: {
		description: string;
		image: FunctionComponent<React.SVGProps<SVGSVGElement>>;
	} & ({ internalLink: string; onClick?: never } | { onClick: () => void; internalLink?: never })
) => {
	const [isHover, setIsHover] = useState(false);
	const isSelected = props.internalLink != null && window.location.pathname.startsWith(props.internalLink);

	const itemBody = (
		<NavItemContainer onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
			<props.image height={28} width={28} stroke={isSelected || isHover ? 'white' : 'black'} />
			<NavItemSpan style={{ color: isSelected || isHover ? 'white' : 'black' }}>{props.description}</NavItemSpan>
		</NavItemContainer>
	);

	return (
		<WishiNavItem
			as={Button}
			variant="plain"
			onClick={props.onClick}
			disabled={isSelected}
			style={{ backgroundColor: isSelected ? '#424242' : undefined }}
		>
			{props.internalLink ? <WishiLink to={props.internalLink}>{itemBody}</WishiLink> : itemBody}
		</WishiNavItem>
	);
};

const NavWidth = 80;
const BorderWidth = 1;
const BorderColor = '#cfccca';

const LeftNavContainer = styled.div`
	width: ${NavWidth + BorderWidth}px;

	background-color: white;

	border-top: 1px solid ${BorderColor};
	border-right: 1px solid ${BorderColor};
`;

const WishiNavItem = styled(Nav.Item)`
	width: ${NavWidth}px;
	height: ${NavWidth}px;

	padding: 0;
	border-radius: 0;

	border: ${BorderWidth}px solid ${BorderColor};

	&:hover {
		color: white;
		background-color: #000;
		border: ${BorderWidth}px solid #000;
	}

	&:disabled {
		opacity: 1;
	}
`;

const WishiNavbar = styled(Nav)`
	display: flex;
	flex-direction: column;
`;

const NavItemContainer = styled(Nav)`
	height: 100%;
	padding: 8px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

const NavItemSpan = styled.span`
	font-size: 11px;
`;

const WishiLink = styled(Link)`
	background-color: inherit;
	text-decoration: none;
`;

export default LeftNav;
