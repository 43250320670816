import { useSelector } from 'react-redux';
import { DisplayCondition, displayConditions, selectQuestions } from 'services/quiz';
import { getConditionLabel } from 'services/quiz/quiz.utils';

import { Dropdown } from './form/Dropdown';

export const QuestionConditionsSelector = (props: {
	id: string;
	questionId?: number;
	questionCondition: DisplayCondition;
	setQuestionCondition: (condition: DisplayCondition) => void;
	disabled?: boolean;
}) => {
	const { idToQuestion } = useSelector(selectQuestions);

	const questionId = props.questionId;

	if (!idToQuestion || !questionId) {
		return null;
	}

	const isFollowUpQuestion = (questionId: number) => idToQuestion[questionId].condition === 'FollowUp';

	const getLabel = (condition: DisplayCondition) =>
		condition === 'FollowUp' && isFollowUpQuestion(questionId)
			? getConditionLabel(idToQuestion[questionId], idToQuestion)
			: displayConditions[condition];

	return (
		<Dropdown
			id={props.id}
			label="Display Condition"
			selectedValue={props.questionCondition.toString()}
			setValue={(value: string) => {
				props.setQuestionCondition(value as unknown as DisplayCondition);
			}}
			options={Object.keys(displayConditions).map((key) => {
				const lbl = getLabel(key as keyof typeof displayConditions);
				return {
					value: key,
					name: lbl,
					disabled: lbl === displayConditions['FollowUp']
				};
			})}
			disabled={props.disabled}
			undefinedOptionText={null}
			tooltip="Defining this question as a follow-up can only be done on the parent question"
		/>
	);
};
