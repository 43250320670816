import React from 'react';
import styled from '@emotion/styled';

const FeaturesContainer = styled.section`
	//
`;

const Feature = styled.div`
	display: flex;
	flex-direction: var(--fpContentFlexDirection);

	img {
		max-width: 100%;
	}

	&.quiz {
		img {
			width: 649px;
		}

		h3 {
			margin-top: 188px;
		}
	}

	&.insights {
		flex-direction: row-reverse;
		margin-top: 185px;

		img {
			width: 567px;
		}

		h3 {
			margin-top: 94px;
		}
	}

	&.expert {
		margin-top: 140px;

		img {
			width: 504px;
		}

		h3 {
			margin-top: 179px;
		}
	}

	@media (max-width: 1024px) {
		&.quiz,
		&.insights,
		&.expert {
			flex-direction: column;
			margin-top: 50px;

			img {
				width: 100%;
			}

			h3 {
				margin-top: 20px;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}
`;

const FeatureImageContainer = styled.div`
	width: 50%;
	padding-left: 70px;

	@media (max-width: 1024px) {
		width: auto;
		padding: 0 20px;
	}
`;

const FeatureText = styled.div`
	width: 50%;
	padding-left: 70px;

	h3 {
		font-size: 50px;
		line-height: 63px;
		letter-spacing: -0.005em;
		max-width: 542px;
	}

	p {
		margin-top: 14px;
		font-size: 16px;
		line-height: 28px;
		max-width: 542px;
	}

	@media (max-width: 1024px) {
		width: auto;
		padding: 0 20px;
		text-align: center;

		h3 {
			font-size: 30px;
			line-height: 38px;
			max-width: none;
		}

		p {
			margin-top: 10px;
			max-width: none;
		}
	}
`;

const features = [
	{
		className: 'quiz',
		title: 'Our quiz builder allows you to customize your user experience.',
		paragraph: 'Capture your customer’s needs and preferences with your tailored user journey.',
		imgSrc: '/images/frontPage/features_quiz.png',
		imgAlt: 'Quiz'
	},
	{
		className: 'insights',
		title: 'Deep customers insights become actionable.',
		paragraph: 'Our propriety app helps experts make the perfect recommendation with data-backed insights.',
		imgSrc: '/images/frontPage/features_deep_insights.png',
		imgAlt: 'Deep insights'
	},
	{
		className: 'expert',
		title: 'Your customers spend more with expert guidance.',
		paragraph: 'Embedding Wishi into your app or website means your customers find and buy items faster.',
		imgSrc: '/images/frontPage/features_expert_guidance.png',
		imgAlt: 'Expert guidance'
	}
];

export const Features = () => {
	return (
		<FeaturesContainer>
			{features.map((feature, index) => (
				<Feature key={index} className={feature.className}>
					<FeatureImageContainer>
						<img src={feature.imgSrc} alt={feature.imgAlt} />
					</FeatureImageContainer>
					<FeatureText>
						<h3>{feature.title}</h3>
						<p>{feature.paragraph}</p>
					</FeatureText>
				</Feature>
			))}
		</FeaturesContainer>
	);
};
