import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { TMessageState, TSetMessageStateFn } from '../types';

const MessageContainer = styled.div`
	@keyframes appear {
		0% {
			opacity: 0;
			visibility: hidden;
		}
		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	position: fixed;
	display: flex;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	top: calc(var(--fpTopBarHeight));
	color: white;
	padding: 18px 20px;
	z-index: 2;
	visibility: hidden;
	opacity: 0;
	animation: appear 0.3s ease-out reverse;

	&.visible {
		opacity: 1;
		visibility: visible;
		animation: appear 0.3s ease-in forwards;
	}

	&.success {
		background-color: var(--fpActiveColor);

		div:before {
			background-image: url(/images/frontPage/icon_sucess.png);
		}
	}

	&.fail {
		background-color: var(--fpAlertColor);
	}

	div {
		position: relative;

		&:before {
			position: absolute;
			width: 32px;
			height: 32px;
			left: -40px;
			top: 50%;
			transform: translateY(-50%);
			background-size: contain;
			content: '';
		}
	}

	@media (max-width: 1024px) {
		top: 0;
	}
`;

export const Message: React.VFC<{
	messageState: TMessageState;
	setMessageState: TSetMessageStateFn;
}> = ({ messageState, setMessageState }) => {
	const messageElementRef = useRef<HTMLDivElement>(null);
	const timerRef = useRef(0);

	useEffect(() => {
		clearTimeout(timerRef.current);

		if (messageState.show) {
			messageElementRef.current?.classList.add('visible');

			timerRef.current = window.setTimeout(() => {
				setMessageState({ show: false });
			}, 5000);
		} else {
			messageElementRef.current?.classList.remove('visible');
		}
	}, [messageState, setMessageState]);

	return (
		<MessageContainer className={messageState.type} ref={messageElementRef} role="alert">
			<div>{messageState.text}</div>
		</MessageContainer>
	);
};
