import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import PageLayout from 'components/page-layout';

export const InsightsWrapper: FC = () => {
	return (
		<PageLayout>
			<Outlet />
		</PageLayout>
	);
};
