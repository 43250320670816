import React from 'react';
import styled from '@emotion/styled';

const TestimonialsContainer = styled.section`
	--fpBoxLeftMargin: 223px;

	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	margin: 46px var(--fpMinSectionSideMargin) 0;
	padding: 57px;
	border-radius: var(--fpSectionBorderRadius);

	@media (max-width: 1024px) {
		--fpBoxLeftMargin: 0;

		margin-top: 0;
		padding: 39px 20px 20px 20px;
		text-align: center;
	}
`;

const Testimonial = styled.div`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Title = styled.h3`
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 0;
	margin-left: var(--fpBoxLeftMargin);

	@media (max-width: 1024px) {
		font-size: 18px;
		line-height: 23px;
	}
`;

const Paragraph = styled.p`
	font-size: 18px;
	line-height: 29px;
	max-width: 829px;
	margin: 19px 0 0 var(--fpBoxLeftMargin);

	span {
		color: var(--fpTextAccentColor);

		&:before {
			content: ' ';
		}
	}

	@media (max-width: 1024px) {
		order: 1;
		margin-top: 25px;
	}
`;

const HeroPicContainer = styled.div`
	position: relative;
	margin-left: var(--fpBoxLeftMargin);

	@media (max-width: 1024px) {
		margin-top: 25px;
	}
`;

const HeroPic = styled.img`
	width: 194px;
	height: 194px;
	position: absolute;
	transform: translate(-223px, -30px);

	@media (max-width: 1024px) {
		position: static;
		transform: none;
	}
`;

const HeroName = styled.div`
	font-size: 22px;
	line-height: 144%;
	margin-top: 62px;
	margin-left: var(--fpBoxLeftMargin);

	@media (max-width: 1024px) {
		margin-top: 25px;

		span {
			display: block;
		}
	}
`;

const Logo = styled.img`
	width: 120px;
	height: 15px;
	margin-top: 6px;
	margin-left: var(--fpBoxLeftMargin);

	@media (max-width: 1024px) {
		margin-top: 15px;
	}
`;

export const Testimonials = () => {
	return (
		<TestimonialsContainer>
			<Testimonial>
				<Title>What our partners are saying</Title>
				<HeroPicContainer>
					<HeroPic src="/images/frontPage/filipa_neto.png" />
				</HeroPicContainer>
				<Paragraph>
					In the crowded world of e-commerce, personalization is crucial. So when we decided to bring
					assisted-shopping to our customer base, we wanted a solution that’s easy to implement and drives
					superior customer experience.&nbsp;
					<span>We’ve seen clients that engage with Wishi yield a higher conversion rate.</span>
				</Paragraph>
				<HeroName>
					<span>Filipa Neto, </span>
					<span>Head of Open Innovation</span>
				</HeroName>
				<Logo src="/images/frontPage/farfetch_logo.png" />
			</Testimonial>
		</TestimonialsContainer>
	);
};
