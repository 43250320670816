import { configureStore } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux/middleware/api';
import rootReducer from 'redux/reducers/root';

import { rootSplitApi } from './base-api';

const isDev = process.env.NODE_ENV !== 'production';

const store = configureStore({
	reducer: rootReducer,
	devTools: isDev,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(rootSplitApi.middleware, apiMiddleware),
	preloadedState: {}
});

export default store;
export type RootState = ReturnType<typeof rootReducer>;
