import { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useQuizEditAllowed } from 'routes/quiz/hooks/QuizEditAllowedHook';
import { selectNewQuestion } from 'services/quiz';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';

import { GraphMenuButtonStyled } from './GraphMenuButton';

export const AddQuestionButton = (props: { style?: CSSProperties }) => {
	const dispatch = useDispatch();

	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const quizEditAllowed = useQuizEditAllowed();

	if (selectedVersion?.isLocked || !quizEditAllowed) {
		return null;
	}

	return (
		<AddQuestionButtonStyled
			style={props.style}
			onClick={() => {
				dispatch(selectNewQuestion());
			}}
		>
			<PlusIcon width={12} height={12} style={{ marginRight: 8 }} />
			<span
				style={{
					whiteSpace: 'nowrap'
				}}
			>
				Add a new question
			</span>
		</AddQuestionButtonStyled>
	);
};

const AddQuestionButtonStyled = styled(GraphMenuButtonStyled)`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
`;
