import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

import { TMappedDashboardItem } from '../mappers';

import { Metric } from './Metric';

const Container = styled.div`
	margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 16px;
`;

const Items = styled.div`
	display: flex;
	gap: 17px;
`;

const Item = styled.div`
	width: 392px;
	border-radius: 18px;
	border: 1px solid ${palette.elementBorderColor};
	background: ${palette.elementBackground};
`;

type TProps = {
	title: string;
	items: TMappedDashboardItem[];
};

export const RegularSection: FC<TProps> = ({ title, items }) => {
	return (
		<Container>
			<SectionTitle>{title}</SectionTitle>
			<Items>
				{items.map((item, index) => (
					<Item key={index}>
						<Metric {...item} />
					</Item>
				))}
			</Items>
		</Container>
	);
};
