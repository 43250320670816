import { FC, useRef } from 'react';
import styled from '@emotion/styled';

import infoImg from '../../assets/info.svg';

const Container = styled.div`
	position: relative;
	width: 10px;
	height: 10px;
	background: url(${infoImg}) no-repeat;
	cursor: pointer;

	&:hover {
		.tooltipBubble {
			visibility: visible;
			opacity: 1;
			transition: opacity 0.3s;
		}
	}
`;

export const TooltipBubble = styled.div`
	position: absolute;
	background: black;
	color: white;
	padding: 12px;
	max-width: 204px;
	width: max-content;
	left: calc(100% + 8px);
	top: calc(50% - 14px);
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0s 0.3s;

	&:before {
		position: absolute;
		content: '';
		border: 5px solid transparent;
		border-right-color: black;
		left: -10px;
		top: 10px;
	}

	&.topPosition {
		left: unset;
		top: unset;
		right: -10px;
		bottom: 18px;

		&:before {
			border: 5px solid transparent;
			border-top-color: black;
			left: unset;
			top: unset;
			right: 10px;
			bottom: -10px;
		}
	}
`;

type TProps = {
	text: string;
	className?: string;
};

export const Tooltip: FC<TProps> = ({ text, className }) => {
	const tooltipRef = useRef<HTMLDivElement>(null);

	const calculateTooltipPosition = () => {
		const element = tooltipRef.current;

		if (element) {
			element.classList.remove('topPosition');
			element.classList.toggle('topPosition', element.getBoundingClientRect().right > window.innerWidth);
		}
	};

	return (
		<Container className={className} onMouseEnter={calculateTooltipPosition}>
			<TooltipBubble ref={tooltipRef} className="tooltipBubble">
				{text}
			</TooltipBubble>
		</Container>
	);
};
