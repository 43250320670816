import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { truncate } from 'lodash';
import { useGetQuestionsQuery } from 'services/quiz';
import { selectSelectedVersionId } from 'services/versions';
import { TDatePeriod } from 'utils';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryZoomContainer } from 'victory';

import { WishiSpinner } from 'components/general/WishiSpinner';

import { useGetQuizProgressionQuery } from '../redux';

const MaxBarsPerPage = 16;

export const QuestionBounceGraph: FC<{ period: TDatePeriod }> = ({ period }) => {
	const selectedVersionId = useSelector(selectSelectedVersionId);

	const { data: quizProgression, isFetching: isFetchingProgression } = useGetQuizProgressionQuery(
		{ versionId: selectedVersionId as number, period },
		{ skip: !(selectedVersionId && period) }
	);

	const { data: quizQuestions, isFetching: isFetchingQuestions } = useGetQuestionsQuery(
		{ quizVersion: selectedVersionId as number },
		{ skip: !selectedVersionId }
	);

	const graphData = useMemo(() => {
		if (quizProgression && quizQuestions) {
			return quizQuestions.map(({ id, name }) => ({
				x: name,
				y: quizProgression[id]?.seen ? (quizProgression[id]?.answered / quizProgression[id]?.seen) * 100 : 0
			}));
		}
		return [];
	}, [quizProgression, quizQuestions]);

	return (
		<Container>
			{isFetchingProgression || isFetchingQuestions ? (
				<WishiSpinner position="center" />
			) : (
				<GraphContainer>
					<VictoryChart
						domain={{
							y: [0, 100],
							x: [0, Math.max(graphData.length, MaxBarsPerPage)]
						}}
						width={1174}
						height={520}
						padding={{
							top: 8,
							right: 0,
							bottom: 150,
							left: 40
						}}
						containerComponent={
							MaxBarsPerPage < graphData.length ? (
								<VictoryZoomContainer
									allowPan
									allowZoom={false}
									zoomDimension="x"
									zoomDomain={{ x: [0, MaxBarsPerPage] }}
								/>
							) : undefined
						}
					>
						<VictoryBar
							style={{ data: { fill: '#EAA760' } }}
							barWidth={13}
							cornerRadius={6.5}
							data={graphData}
						/>
						<VictoryAxis
							dependentAxis
							tickFormat={(t) => `${t}%`}
							tickValues={[20, 40, 60, 80, 100]}
							style={{
								tickLabels: {
									fontSize: 11,
									fontFamily: 'inherit'
								}
							}}
						/>
						<VictoryAxis
							crossAxis
							tickFormat={(t) => truncate(t)}
							tickLabelComponent={<VictoryLabel angle={315} textAnchor="end" dy={0} dx={-4} />}
							style={{
								tickLabels: {
									fontSize: 11,
									fontFamily: 'inherit'
								}
							}}
						/>
					</VictoryChart>
				</GraphContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 520px;
`;

const GraphContainer = styled.div`
	width: 100%;
	height: 100%;
`;
