import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import styled from '@emotion/styled';
import { selectApiToken } from 'services/user';

import {
	Features,
	Footer,
	Header,
	InTheNews,
	Message,
	OurNumbers,
	OurPartners,
	Plans,
	RequestADemoModal,
	Testimonials,
	TopBar
} from './components';
import { TMessageState } from './types';

import './vars.css';
import './reset.css';

const FrontPageContainer = styled.div`
	background-color: var(--fpBackgroundColor);
	font-family: 'ABC Diatype', sans-serif;
	font-weight: 400;
	color: var(--fpTextColor);

	@media (max-width: 1024px) {
		--fpMinSectionSideMargin: 0;
		--fpSectionBorderRadius: 0;
	}
`;

const Content = styled.div`
	max-width: 1440px;
	margin: 0 auto;
`;

export const FrontPage = () => {
	const [messageState, setMessageState] = useState<TMessageState>({
		show: false
	});
	const apiToken = useSelector(selectApiToken);
	const plansSectionRef = useRef<HTMLDivElement>(null);
	const scrollToPlans = () => plansSectionRef.current?.scrollIntoView({ behavior: 'smooth' });

	if (apiToken) {
		return <Navigate replace to="quiz" />;
	}

	return (
		<FrontPageContainer className="frontPage">
			<TopBar scrollToPlans={scrollToPlans} />
			<Message messageState={messageState} setMessageState={setMessageState} />
			<Content>
				<Header />
				<OurPartners />
				<OurNumbers />
				<Testimonials />
				<Features />
				<InTheNews />
				<div ref={plansSectionRef}>
					<Plans />
				</div>
				<Footer scrollToPlans={scrollToPlans} />
			</Content>
			<RequestADemoModal setMessageStateFn={setMessageState} />
		</FrontPageContainer>
	);
};
