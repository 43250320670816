import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { useQuizEditAllowed } from 'routes/quiz/hooks/QuizEditAllowedHook';
import { useVersionEditAllowed } from 'routes/quiz/hooks/VersionsEditAllowedHook';
import { resetQuiz, setSelectedQuiz, useLazyGetQuestionsQuery } from 'services/quiz';
import { useGetPartnerQuery } from 'services/user';
import {
	QuizVersion,
	setSelectedVersionId,
	useArchiveQuizVersionMutation,
	useSetActiveVersionMutation
} from 'services/versions';

import { Pill } from 'components/general/Pill';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { DuplicateVersionModal } from '../../menu/DuplicateVersionModal';

import { VersionActionButton, VersionActionsContainer, VersionBorderColor, VersionContainer } from './VersionsList';

const VersionNameButton = styled(Button)`
	flex-grow: 1;

	font-size: 16px;
	text-align: left;
	text-decoration: none;
	color: #000;

	&:hover {
		color: #000;
	}
`;

const StyledPill = styled(Pill)`
	font-size: 12px;
	background-color: #113f13;
	margin: 8px 12px;
`;

interface VersionRowProps {
	version: QuizVersion;
	closeModal: () => void;
	isActive?: boolean;
	isSelected?: boolean;
}

export const VersionRow = (props: VersionRowProps) => {
	const dispatch = useDispatch();
	const [archiveVersion] = useArchiveQuizVersionMutation();
	const [activateVersion] = useSetActiveVersionMutation();
	const [fetchQuiz] = useLazyGetQuestionsQuery();

	const { data: partner } = useGetPartnerQuery();
	const activeVersionId = partner?.activeVersionId;

	const isVersionEditAllowed = useVersionEditAllowed();
	const isQuizEditAllowed = useQuizEditAllowed();
	const isDeleteAllowed = isVersionEditAllowed && props.version.id !== activeVersionId;

	const [showVersionDuplicationModal, setShowVersionDuplicationModal] = useState(false);

	const selectVersion = (id: number | undefined) => {
		dispatch(setSelectedVersionId(id));
		dispatch(resetQuiz());
		if (id) {
			fetchQuiz({ quizVersion: id })
				.unwrap()
				.then((result) => {
					dispatch(setSelectedQuiz(result));
				});
		}
	};

	const selectVersionClick = (id: number) => {
		props.closeModal();
		selectVersion(id);
	};

	const activateVersionClick = (id: number) => {
		if (props.version.firstQuestionId == null) {
			toast.error('First question needs to be set');
			return;
		}
		activateVersion({ versionId: id })
			.unwrap()
			.then(() => {
				props.closeModal();
				toast.success('Active version set');
				selectVersion(id);
			})
			.catch(() => toast.error('Failed to set active version'));
	};

	const deleteVersionClick = (version: QuizVersion) => {
		window.confirm(
			`You are about to delete version "${version.name}".\nOnce the delete is final you will no longer have access to the version. `
		) &&
			archiveVersion({ versionId: version.id })
				.unwrap()
				.then(() => {
					toast.success('Version archived');
					if (props.isSelected) {
						selectVersion(activeVersionId);
					}
				})
				.catch(() => toast.error('Archiving version failed'));
	};

	return (
		<>
			<VersionContainer style={{ borderColor: props.isSelected ? '#000' : VersionBorderColor }}>
				<VersionNameButton variant="link" onClick={() => selectVersionClick(props.version.id)}>
					{props.version.name}
				</VersionNameButton>
				<VersionActionsContainer>
					{isVersionEditAllowed && (
						<VersionActionButton onClick={() => setShowVersionDuplicationModal(true)}>
							Duplicate
						</VersionActionButton>
					)}
					{props.isActive ? (
						<StyledPill>Active</StyledPill>
					) : (
						isVersionEditAllowed && (
							<VersionActionButton onClick={() => activateVersionClick(props.version.id)}>
								Make Active
							</VersionActionButton>
						)
					)}
					{isQuizEditAllowed && (
						<VersionActionButton
							onClick={() => selectVersionClick(props.version.id)}
							disabled={props.version.isLocked}
						>
							<EditIcon stroke={props.version.isLocked ? '#989898' : '#000'} height={16} width={16} />
						</VersionActionButton>
					)}
					{isDeleteAllowed && (
						<VersionActionButton onClick={() => deleteVersionClick(props.version)}>
							<DeleteIcon height={16} width={16} />
						</VersionActionButton>
					)}
				</VersionActionsContainer>
			</VersionContainer>
			<DuplicateVersionModal
				version={props.version}
				show={showVersionDuplicationModal}
				setShow={setShowVersionDuplicationModal}
			/>
		</>
	);
};
