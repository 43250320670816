import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Role } from 'services/teams';
import { useGetUserQuery } from 'services/user';

import { PageContainer, PageTitleContainer, PageTitleSpan } from 'components/Page';
import PageLayout from 'components/page-layout';

import { TeamManagementTable } from './TeamManagementTable';

export const Settings = () => {
	const navigate = useNavigate();
	const { data: user } = useGetUserQuery();

	if (user?.role !== undefined && user.role !== Role.admin) {
		navigate('/');
	}

	return (
		<>
			<PageLayout>
				<PageContainer>
					<PageTitleContainer>
						<PageTitleSpan>Settings</PageTitleSpan>
					</PageTitleContainer>
					<TeamManagementTable />
				</PageContainer>
			</PageLayout>
		</>
	);
};

export const PageSubtitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 24px;
`;

export const PageSection = styled.div`
	margin-bottom: 40px;
`;
