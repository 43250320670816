import { QuizQuestionCategory } from 'routes/quiz/components/question/categories';

export interface QuizQuestionUi extends QuizQuestion {
	parentsIds?: Set<number>;
	followUpTo?: number;
	familyId?: number;
}

export interface IdToQuestionDict {
	[key: number]: QuizQuestionUi;
}

export enum QuizQuestionType {
	SelectOneRadio = 'SelectOneRadio',
	SelectOneButtons = 'SelectOneButtons',
	SelectOneTags = 'SelectOneTags',
	SelectManyButtons = 'SelectManyButtons',
	SelectManyTags = 'SelectManyTags',
	SelectManyCheckbox = 'SelectManyCheckbox',
	DropDowns = 'DropDowns',
	FreeText = 'FreeText',
	FreeNumber = 'FreeNumber',
	UploadPhoto = 'UploadPhoto',
	PhoneNumber = 'PhoneNumber'
}

export interface AnswerOption {
	key: string;
	text?: string;
	imageUrl?: string;
	leadsTo?: number;
	includeFreeText?: boolean;
	answerGroupKey?: string;
	followUpQuestionId?: number;
}

export interface AnswerGroup {
	key: string;
	description?: string;
}

export type DisplayCondition = 'None' | 'DisplayOnce' | 'DisplayUnanswered' | 'FollowUp';

export const displayConditions: Record<DisplayCondition, string> = {
	None: 'None',
	DisplayOnce: 'Display once',
	DisplayUnanswered: 'Display only if not replied',
	FollowUp: 'Follow up'
};

export interface QuizQuestion {
	id: number;
	name: string | null;
	title: string;
	subtitle?: string;
	imageUrl?: string;
	imageDescription?: string;
	type: QuizQuestionType;
	category: QuizQuestionCategory;
	leadsTo: number | null;
	answers: AnswerOption[] | null;
	answerGroups: AnswerGroup[] | null;
	isSkippable?: boolean;
	includeAdditionalComments: boolean | null;
	maxNextQuestions?: number;
	progressOnClick?: boolean;
	answerKeyToCategoryKeyMapping: Record<string, string> | null;
	textInputPlaceholder?: string;
	condition: DisplayCondition | null;
}

export interface QuestionOptionals {
	name?: string;
	subtitle?: string;
	imageUrl?: string;
	imageDescription?: string;
	answerGroups?: AnswerGroup[];
	answers?: AnswerOption[];
	isSkippable?: boolean;
	includeAdditionalComments?: boolean;
	progressOnClick?: boolean;
	answerKeyToCategoryKeyMapping?: Record<string, string>;
	textInputPlaceholder?: string;
	condition?: DisplayCondition;
}

export interface PartialQuestionInfo extends QuestionOptionals {
	type?: QuizQuestionType;
	category?: QuizQuestionCategory;
	title?: string;
	leadsTo?: number | null;
}

export interface QuestionInfo extends QuestionOptionals {
	type: QuizQuestionType;
	category: QuizQuestionCategory;
	title: string;
	leadsTo: number | null;
}

export interface UpdateQuestionRequestParams {
	quizVersion: number;
	questionId: number;

	question: PartialQuestionInfo;
}

export interface QuizQuestionsQueryParams {
	quizVersion: number;
}

export interface CreateQuestionRequestParams {
	quizVersion: number;
	question: QuestionInfo;
}

export interface DuplicateQuestionRequestParams {
	quizVersion: number;
	questionId: number;
}

export interface DeleteQuestionRequestParams {
	quizVersion: number;
	questionId: number;
}

export const StringToQuizQuestionType: Record<string, QuizQuestionType> = {
	SelectOneRadio: QuizQuestionType.SelectOneRadio,
	SelectOneButtons: QuizQuestionType.SelectOneButtons,
	SelectOneTags: QuizQuestionType.SelectOneTags,
	SelectManyButtons: QuizQuestionType.SelectManyButtons,
	SelectManyTags: QuizQuestionType.SelectManyTags,
	SelectManyCheckbox: QuizQuestionType.SelectManyCheckbox,
	DropDowns: QuizQuestionType.DropDowns,
	FreeText: QuizQuestionType.FreeText,
	FreeNumber: QuizQuestionType.FreeNumber,
	UploadPhoto: QuizQuestionType.UploadPhoto,
	PhoneNumber: QuizQuestionType.PhoneNumber
};

interface QuestionTypeInfo {
	key: QuizQuestionType;
	name: string;
}

export const QuestionTypeDict: Record<QuizQuestionType, QuestionTypeInfo> = {
	[QuizQuestionType.SelectOneRadio]: {
		key: QuizQuestionType.SelectOneRadio,
		name: 'Select one radio'
	},
	[QuizQuestionType.SelectOneButtons]: {
		key: QuizQuestionType.SelectOneButtons,
		name: 'Select one buttons'
	},
	[QuizQuestionType.SelectOneTags]: {
		key: QuizQuestionType.SelectOneTags,
		name: 'Select one tags'
	},
	[QuizQuestionType.SelectManyCheckbox]: {
		key: QuizQuestionType.SelectManyCheckbox,
		name: 'Select many checkbox'
	},
	[QuizQuestionType.SelectManyButtons]: {
		key: QuizQuestionType.SelectManyButtons,
		name: 'Select many buttons'
	},
	[QuizQuestionType.SelectManyTags]: {
		key: QuizQuestionType.SelectManyTags,
		name: 'Select many tags'
	},
	[QuizQuestionType.FreeText]: {
		key: QuizQuestionType.FreeText,
		name: 'Free text'
	},
	[QuizQuestionType.FreeNumber]: {
		key: QuizQuestionType.FreeNumber,
		name: 'Free number'
	},
	[QuizQuestionType.DropDowns]: {
		key: QuizQuestionType.DropDowns,
		name: 'DropDowns'
	},
	[QuizQuestionType.UploadPhoto]: {
		key: QuizQuestionType.UploadPhoto,
		name: 'Upload photo'
	},
	[QuizQuestionType.PhoneNumber]: {
		key: QuizQuestionType.PhoneNumber,
		name: 'Phone number'
	}
};

export const QuestionTypesSupportingImageInAnswer = new Set<QuizQuestionType>([
	QuizQuestionType.SelectManyButtons,
	QuizQuestionType.SelectManyTags,
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneTags
]);

export const QuestionTypesSupportingAnswerDirection = new Set<QuizQuestionType>([
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneRadio,
	QuizQuestionType.SelectOneTags
]);

export const QuestionTypesSupportingAnswerFreeText = new Set<QuizQuestionType>([
	QuizQuestionType.SelectOneRadio,
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneTags
]);

export const QuestionTypesSupportingFollowup = new Set<QuizQuestionType>([
	QuizQuestionType.SelectManyButtons,
	QuizQuestionType.SelectManyCheckbox,
	QuizQuestionType.SelectManyTags
]);
