import { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ErrorColor, ErrorTextColor } from 'constants/colors';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	height: 58px;
	width: 100%;
	color: ${ErrorTextColor};
	background-color: ${ErrorColor};
`;

const AlertCta = styled.a`
	font-weight: 700;
	color: ${ErrorTextColor};
`;

export const PixelAlert: FC = () => {
	return (
		<Container>
			<AlertIcon />
			<div>Important: Implement the conversion pixel to access performance data.</div>
			<AlertCta href="mailto: hello@wishi.me?subject=Pixel implementation">
				For more info, contact support.
			</AlertCta>
		</Container>
	);
};
