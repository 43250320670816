import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';
import { GraphMenuButtonBorderColor, GraphMenuButtonColor, GraphMenuButtonTextColor } from 'constants/colors';

export const GraphMenuButtonBase = styled(Button)`
	pointer-events: auto;
`;

export const GraphMenuButtonStyled = styled(GraphMenuButtonBase)`
	border-radius: 24px;
	padding: 8px 32px;
	font-size: 16px;
	color: ${GraphMenuButtonTextColor};
	border-color: ${GraphMenuButtonBorderColor};
	background-color: ${GraphMenuButtonColor};
	line-height: 1.15em;

	&:hover,
	&:focus {
		border-color: ${GraphMenuButtonBorderColor};
		background-color: ${GraphMenuButtonColor};
		color: ${GraphMenuButtonTextColor};
	}
`;
