import { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import { PageContainer, PageSubtitleSpan, PageTitleContainer, PageTitleSpan } from 'components/Page';
import PageLayout from 'components/page-layout';

import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';

import { useGetLooksQuery } from './redux/looksSlice';
import { Look } from './redux/types';

const getOutfitUrl = (uuid: string) => `${process.env.REACT_APP_B2C_URL}/feed/outfit/${uuid}`;

const newer = 'newer';
const older = 'older';

const sortLooks = (looks: Look[]) => {
	let now = new Date();
	const splitTime = new Date(now.setDate(now.getDate() - 7)).getTime();
	now = new Date();
	const cutTime = new Date(now.setDate(now.getDate() - 30)).getTime();
	const sortedLooks = _.sortBy(looks, ['created'])
		.filter((x) => new Date(x.created).getTime() > cutTime)
		.reverse();
	return _.groupBy(sortedLooks, ({ created }) => (new Date(created).getTime() > splitTime ? newer : older));
};

export const Looks: FC = () => {
	const { data, isFetching } = useGetLooksQuery();

	const sortedLooks = sortLooks(data?.looks ?? []);

	const getLook = (look: Look, index: number) => {
		return (
			<LookContainer key={index}>
				<Link to={getOutfitUrl(look.id)} target="_blank">
					<LookImg src={look.pictureUrl} />
				</Link>
				<Icon onClick={() => saveAs(look.pictureUrl)}></Icon>
				<Download onClick={() => saveAs(look.pictureUrl)}>Download</Download>
			</LookContainer>
		);
	};

	return (
		<PageLayout>
			<PageContainer>
				<PageTitleContainer>
					<PageTitleSpan>Looks created by stylists</PageTitleSpan>
				</PageTitleContainer>
				{isFetching && (
					<SpinnerContainer>
						<Spinner animation="border" />
					</SpinnerContainer>
				)}
				{!isFetching && (
					<>
						<PageSubtitleContainer>
							<PageSubtitleSpan>Last 7 days</PageSubtitleSpan>
						</PageSubtitleContainer>
						<LooksContainer>
							{sortedLooks[newer]?.map((look, index) => getLook(look, index))}
						</LooksContainer>
						<PageSubtitleContainer>
							<PageSubtitleSpan>Last 30 days</PageSubtitleSpan>
						</PageSubtitleContainer>
						<LooksContainer>
							{sortedLooks[older]?.map((look, index) => getLook(look, index))}
						</LooksContainer>
					</>
				)}
			</PageContainer>
		</PageLayout>
	);
};

const SpinnerContainer = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
`;

export const PageSubtitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
`;

export const PageSection = styled.div`
	margin-bottom: 40px;
`;

export const LooksContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
	margin-bottom: 24px;
`;

export const LookContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	width: 300px;
`;

export const LookImg = styled.img`
	width: 90%;
	height: 90%;
	object-fit: contain;
	border: 1px solid ${palette.primaryGray};
`;

export const Download = styled.div`
	cursor: pointer;
	font-weight: 700;
`;

export const Icon = styled(DownloadIcon)`
	cursor: pointer;
`;
