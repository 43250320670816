import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { useCreateNewQuizVersionMutation } from 'services/versions';

import { VersionActionButton, VersionActionsContainer, VersionContainer } from './VersionsList';

export const NewVersionRow = (props: { onSave: () => void; onCancel: () => void }) => {
	const [createNewVersion] = useCreateNewQuizVersionMutation();
	const [newVersionName, setNewVersionName] = useState<string>('');

	const crteateNewVersionClick = () => {
		if (!newVersionName) {
			toast.error('Set version name');
			return;
		}
		createNewVersion({ version: { name: newVersionName } })
			.unwrap()
			.then(() => {
				toast.success('Version created');
				props.onSave();
			})
			.catch(() => {
				toast.error('Version creation failed');
			});
	};

	return (
		<VersionContainer>
			<NewVersionTextField
				id="new-version-name-field"
				value={newVersionName}
				placeholder="Version name"
				onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					setNewVersionName(e.target.value);
				}}
			/>
			<VersionActionsContainer>
				<VersionActionButton onClick={props.onCancel}>Cancel</VersionActionButton>
				<VersionActionButton variant="pill" onClick={() => crteateNewVersionClick()}>
					Save Version
				</VersionActionButton>
			</VersionActionsContainer>
		</VersionContainer>
	);
};

export const NewVersionTextField = styled(Form.Control)`
	font-size: 12px;
	border: unset;
`;
