import { createContext, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const ErrorModalProvider = (props: { children: any }) => {
	const [show, setShow] = useState(false);

	const [errorModalInfo, setErrorModalInfo] = useState<{ title: string; message: string } | undefined>(undefined);

	const handleClose = () => {
		setShow(false);
		setErrorModalInfo(undefined);
	};

	return (
		<ErrorModalContext.Provider
			value={(title: string, message: string) => {
				setErrorModalInfo({ title, message });
				setShow(true);
			}}
		>
			{props.children}
			<Modal show={show && errorModalInfo != null} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{errorModalInfo?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{errorModalInfo?.message}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</ErrorModalContext.Provider>
	);
};

const ErrorModalContext = createContext((title: string, message: string) => {});
export const useErrorModal = () => useContext(ErrorModalContext);
