import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

import { Tooltip } from 'components/general';

import decArrow from '../assets/trendArrowDec.svg';
import incArrow from '../assets/trendArrowInc.svg';
import { TMappedDashboardItem } from '../mappers';

const Container = styled.div`
	padding: var(--metricItemPadding);
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	font: var(--metricTitleFontSize) 'ABCDiatype', sans-serif;
`;

const StyledTooltip = styled(Tooltip)`
	margin-left: 5px;
`;

const ValuesContainer = styled.div`
	display: flex;
	align-items: end;
	margin: var(--metricValueMargin);
	white-space: nowrap;
`;

const Value = styled.div`
	font: var(--metricValueFontSize) 'ABCDiatype', sans-serif;
`;

const SecondaryValue = styled.div`
	font-size: 14px;
	color: #1d6e69;
	margin: var(--metricSecondaryValueMargin);
`;

const Separator = styled.div`
	height: 1px;
	background: ${palette.elementBorderColorLight};
	margin: var(--metricSeparatorMargin);
`;

const Comparison = styled.div`
	display: flex;
	margin: var(--metricComparisonMargin);
`;

const NoComparison = styled.div`
	margin: var(--metricComparisonMargin);
	color: #d0d0d0;
	font-size: 14px;
	line-height: 16px;
`;

const TrendArrow = styled.div`
	width: 10px;
	height: 14px;
	margin-right: 6px;
	display: none;
	flex: none;

	&.inc {
		background: url(${incArrow}) no-repeat;
		display: block;
	}

	&.dec {
		background: url(${decArrow}) no-repeat;
		display: block;
	}
`;

const TrendText = styled.div`
	font-size: 14px;
	line-height: 16px;

	.equal {
		color: ${palette.neutralTrend};
	}
	.inc {
		color: ${palette.positiveTrend};
	}
	.dec {
		color: ${palette.negativeTrend};
	}
`;

export const Metric: FC<TMappedDashboardItem> = ({
	header,
	value,
	secondary,
	comparisonPercent,
	comparison,
	trend,
	trendText,
	tooltipText
}) => {
	return (
		<Container>
			<Title>
				<span>{header}</span>
				{tooltipText && <StyledTooltip text={tooltipText} />}
			</Title>
			<ValuesContainer>
				<Value>{value}</Value>
				{secondary && <SecondaryValue>({secondary})</SecondaryValue>}
			</ValuesContainer>
			<Separator />
			{trend ? (
				<Comparison>
					<TrendArrow className={trend} />
					<TrendText>
						<span className={trend} title={comparison?.toString()}>
							{comparisonPercent?.toFixed(0)}%
						</span>
						&nbsp;
						{trendText}
					</TrendText>
				</Comparison>
			) : (
				<NoComparison>No data to compare</NoComparison>
			)}
		</Container>
	);
};
