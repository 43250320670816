import { useState } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import styled from '@emotion/styled';

import { ReactComponent as ZoomIcon } from '../../../../../assets/icons/zoom.svg';

import { GraphMenuButtonBase } from './GraphMenuButton';

const QuestionsAccordionKey = 'QuestionsAccordionKey';
interface IQuestionsListButton {
	search: string;
	onChange: (search: string) => void;
}
export const QuestionsListButton: React.FC<IQuestionsListButton> = ({ onChange, search }) => {
	const decoratedOnClick = useAccordionButton(QuestionsAccordionKey);
	const [isOpen, setIsOpen] = useState(false);

	const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		onChange(e.currentTarget.value);
		setIsOpen(true);
	};
	return (
		<StyledGraphMenuButton
			className={isOpen ? 'open' : undefined}
			onClick={(event) => {
				setIsOpen(!isOpen);
				decoratedOnClick(event);
			}}
		>
			<StylesZoomIcon />
			<Input placeholder="Search question" value={search} onChange={onInputChange} />
		</StyledGraphMenuButton>
	);
};

const StyledGraphMenuButton = styled(GraphMenuButtonBase)`
	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 12px;

	line-height: 1em;

	background-color: white;
	color: #6a5644;

	font-size: 16px;
	text-align: left;

	border: 1px solid transparent;

	&:hover,
	&:focus {
		background-color: white;
		color: #6a5644;
		border: 1px solid #cdbfb3;
	}

	&.open {
		border-bottom: 1px solid #cdbfb3;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`;

const StylesZoomIcon = styled(ZoomIcon)`
	height: 16px;
	margin-right: 12px;
	margin-bottom: 2px;
`;
const Input = styled.input`
	border: none;
	outline: none;
`;
