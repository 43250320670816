import { combineReducers } from 'redux';
import { rootSplitApi } from 'redux/base-api';
import quizReducer from 'services/quiz/quiz.slice';
import authReducer from 'services/user/auth.slice';
import versionsReducer from 'services/versions/versions.slice';

const appReducer = combineReducers({
	auth: authReducer,
	versions: versionsReducer,
	quiz: quizReducer,
	[rootSplitApi.reducerPath]: rootSplitApi.reducer
});

const rootReducer: typeof appReducer = (state, action) => {
	if (action.type === RESET_STORE) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

const RESET_STORE = '[root] RESET_STORE';

export const resetStore = () => ({
	type: RESET_STORE
});

export default rootReducer;
