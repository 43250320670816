import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import styled from '@emotion/styled';

const Background = styled.div`
	position: fixed;
	z-index: 2;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.8);
`;

const ModalContainer = styled.div``;

type TProps = {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
};

export const Modal: FC<PropsWithChildren<TProps>> = ({ children, show, setShow }) => {
	return show ? (
		<Background onClick={() => setShow(false)}>
			<ModalContainer onClick={(event) => event.stopPropagation()}>{children}</ModalContainer>
		</Background>
	) : (
		<></>
	);
};
