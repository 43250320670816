export const loginParams = {
	message: 'message',
	token: 'token',
	uuid: 'uuid',
	partnerKey: 'partner_key',
	resetPassword: 'resetPassword'
};

export const loginMessages = {
	success: 'success'
};

export const storageParams = {
	accessToken: 'access-token'
};
