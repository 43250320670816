import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';
import { AnswerContainerBorderColor } from 'constants/colors';
import {
	AnswerOption,
	QuestionTypesSupportingAnswerDirection,
	QuestionTypesSupportingAnswerFreeText,
	QuestionTypesSupportingFollowup,
	QuestionTypesSupportingImageInAnswer,
	QuizQuestionType
} from 'services/quiz';

import { QuestionCheckbox } from './form/QuestionCheckbox';
import { TextEdit } from './form/TextEdit';
import { AnswerCategoryKeySelector } from './AnswerCategoryKeySelector';
import { CategoriesDict, QuizQuestionCategory } from './categories';
import { QuestionFollowUpSelector } from './QuestionFollowUpSelector';
import { QuestionImageEdit } from './QuestionImageEdit';
import { QuestionLeadsToSelector } from './QuestionLeadsToSelector';

export type UpdateAnswerNoKeyFunc = (updateFields: Partial<Omit<AnswerOption, 'key'>>) => void;

export const AnswerOptionEdit = (props: {
	index: number;
	answer: AnswerOption;
	updateAnswer: (updateFields: Partial<AnswerOption>) => void;
	removeAnswer: () => void;
	disabled?: boolean;

	questionId?: number;
	questionType: QuizQuestionType | undefined;
	questionCategory: QuizQuestionCategory;
	questionParentsIds?: Set<number>;

	answerKeyToCategoryKeyMapping: Record<string, string>;
	setAnswerKeyToCategoryKeyMapping: (mapping: Record<string, string>) => void;
}) => {
	const answer = props.answer;
	const categoryInfo = CategoriesDict[props.questionCategory];

	return (
		<AnswerContainer key={answer.key}>
			{categoryInfo.optionKeys && (
				<AnswerSectionElementContainer>
					<AnswerCategoryKeySelector
						answerKey={props.answer.key}
						answerCategoryKey={props.answerKeyToCategoryKeyMapping[answer.key]}
						updateAnswerCategoryKey={(newCategoryKey?: string) => {
							const newKeysMapping = { ...props.answerKeyToCategoryKeyMapping };
							if (newCategoryKey == null) {
								delete newKeysMapping[answer.key];
							} else {
								newKeysMapping[answer.key] = newCategoryKey;
							}
							props.setAnswerKeyToCategoryKeyMapping(newKeysMapping);
						}}
						categoryOptions={categoryInfo.optionKeys}
						disabled={props.disabled}
					/>
				</AnswerSectionElementContainer>
			)}
			<AnswerSectionElementContainer>
				<TextEdit
					label={'Answer #' + (props.index + 1)}
					id={`answer-${answer.key}`}
					value={answer.text ?? ''}
					setValue={(value: string) => {
						props.updateAnswer({ text: value });
					}}
					disabled={props.disabled}
				/>
			</AnswerSectionElementContainer>
			{QuestionTypesSupportingImageInAnswer.has(props.questionType!) && (
				<AnswerSectionElementContainer>
					<QuestionImageEdit
						id={`answer-${answer.key}-image`}
						placeholder="Image url"
						imageUrlValue={answer.imageUrl ?? ''}
						setImageUrlValue={(imageUrlValue: string) => {
							props.updateAnswer({ imageUrl: imageUrlValue });
						}}
						label="Answer Image Url"
						disabled={props.disabled}
					/>
				</AnswerSectionElementContainer>
			)}
			{props.questionType && QuestionTypesSupportingAnswerDirection.has(props.questionType) && (
				<AnswerSectionElementContainer>
					<QuestionLeadsToSelector
						id={`answer-${answer.key}-leadsto`}
						questionId={props.questionId}
						questionParentsIds={props.questionParentsIds}
						questionLeadsTo={answer.leadsTo ?? null}
						setQuestionLeadsTo={(answerLeadsTo: number | null) => {
							props.updateAnswer({ leadsTo: answerLeadsTo ?? undefined });
						}}
						disabled={props.disabled}
					/>
				</AnswerSectionElementContainer>
			)}
			{props.questionType && QuestionTypesSupportingFollowup.has(props.questionType) && (
				<AnswerSectionElementContainer>
					<QuestionFollowUpSelector
						id={`answer-${answer.key}-followup`}
						questionId={props.questionId}
						questionParentsIds={props.questionParentsIds}
						questionFollowUp={answer.followUpQuestionId ?? null}
						setQuestionFollowUp={(answerFollowUp: number | null) => {
							props.updateAnswer({ followUpQuestionId: answerFollowUp ?? undefined });
						}}
						disabled={props.disabled}
					/>
				</AnswerSectionElementContainer>
			)}
			{props.questionType && QuestionTypesSupportingAnswerFreeText.has(props.questionType) && (
				<AnswerSectionElementContainer>
					<QuestionCheckbox
						id={`answer-${answer.key}-include-free-text-checkbox`}
						label="Include Free Text"
						value={!!answer.includeFreeText}
						setValue={(value: boolean) => {
							props.updateAnswer({ includeFreeText: value || undefined });
						}}
						disabled={props.disabled}
					/>
				</AnswerSectionElementContainer>
			)}
			<AnswerSectionElementContainer>
				<DeleteButton
					variant="link"
					onClick={() => {
						props.removeAnswer();
						const newCategoryKeyMapping = {
							...props.answerKeyToCategoryKeyMapping
						};
						delete newCategoryKeyMapping[answer.key];
						props.setAnswerKeyToCategoryKeyMapping(newCategoryKeyMapping);
					}}
					disabled={props.disabled}
				>
					Delete
				</DeleteButton>
			</AnswerSectionElementContainer>
		</AnswerContainer>
	);
};

export const AnswerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;

	border: 1px solid ${AnswerContainerBorderColor};
	border-radius: 17px;

	padding: 16px;
`;

export const AnswerSectionElementContainer = styled.div`
	width: 100%;
	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

export const DeleteButton = styled(Button)`
	padding: 0;
	border: 0;
	font-size: 13px;
`;
