import React from 'react';
import styled from '@emotion/styled';
import { QuizQuestionType } from 'services/quiz';

import { AddButton } from 'components/general/AddButton';

import { QuestionCheckbox } from '../form/QuestionCheckbox';

import { DetailsSectionTemplate } from './DetailsSectionTemplate';

interface AnswersSectionTemplateProps {
	title: string;
	titleButtonText: string;
	titleButtonOnClick: () => void;
	elements: React.ReactElement[];

	questionIncludeAdditionalComments: boolean;
	setQuestionIncludeAdditionalComments: (val: boolean) => void;
	disabled?: boolean;
}

export const AnswersSectionTemplate = (props: AnswersSectionTemplateProps) => {
	return (
		<DetailsSectionTemplate
			title={
				<AddAnswerSectionTitleContainer>
					<span>{props.title}</span>
					<AddButton
						text={props.titleButtonText}
						onClick={props.titleButtonOnClick}
						disabled={props.disabled}
					/>
				</AddAnswerSectionTitleContainer>
			}
			elementSections={[
				{ key: 'answer-elements', elements: props.elements },
				{
					key: 'answer-extra-elements',
					elements: [
						<QuestionCheckbox
							key="include-additional-comments"
							id="include-additional-comments"
							label="Include ability to write custom note"
							value={props.questionIncludeAdditionalComments}
							setValue={props.setQuestionIncludeAdditionalComments}
							disabled={props.disabled}
						/>
					]
				}
			]}
			disabled={props.disabled}
		/>
	);
};

export const AddAnswerSectionTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const QuestionTypesSupportingAnswerFreeText = new Set<QuizQuestionType>([QuizQuestionType.SelectOneRadio]);
