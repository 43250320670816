import { rootSplitApi } from 'redux/base-api';

import {
	ArchiveVersionsRequestParams,
	CreateVersionsRequestParams,
	DuplicateVersionRequestParams,
	QuizVersion,
	SetFirstQuestionRequestParams,
	SetFirstQuestionResponse,
	UpdateActiveVersionsRequestParams,
	UpdateActiveVersionsResponse
} from './versions.types';

function providesList<R extends { id: string | number }[], T extends string>(
	resultsWithIds: R | undefined,
	tagType: T
) {
	return resultsWithIds
		? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
		: [{ type: tagType, id: 'LIST' }];
}

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Versions', 'Partner'] });

export const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getQuizVersions: build.query<QuizVersion[], void>({
			query: () => ({
				url: '/quiz/versions'
			}),
			transformResponse: (response: { versions: QuizVersion[] }) => response.versions,
			providesTags: (result) => providesList(result, 'Versions')
		}),
		createNewQuizVersion: build.mutation<QuizVersion, CreateVersionsRequestParams>({
			query: (payload) => ({
				url: '/quiz/version',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: [{ type: 'Versions', id: 'LIST' }],
			transformResponse: (response: { version: QuizVersion }) => response.version
		}),
		duplicateQuizVersion: build.mutation<QuizVersion, DuplicateVersionRequestParams>({
			query: (payload) => ({
				url: '/quiz/version/duplicate',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: [{ type: 'Versions', id: 'LIST' }],
			transformResponse: (response: { version: QuizVersion }) => response.version
		}),
		archiveQuizVersion: build.mutation<QuizVersion, ArchiveVersionsRequestParams>({
			query: (payload) => ({
				url: '/quiz/version/archive',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: (result) => [
				{ type: 'Versions', id: 'LIST' },
				{ type: 'Versions', id: result?.id }
			],
			transformResponse: (response: { version: QuizVersion }) => response.version
		}),
		setFirstQuizQuestion: build.mutation<SetFirstQuestionResponse, SetFirstQuestionRequestParams>({
			query: (payload) => ({
				url: '/quiz/version/firstQuestion',
				method: 'PATCH',
				body: payload
			}),
			invalidatesTags: (result) => [
				{ type: 'Versions', id: 'LIST' },
				{ type: 'Versions', id: result?.versionId }
			]
		}),
		setActiveVersion: build.mutation<UpdateActiveVersionsResponse, UpdateActiveVersionsRequestParams>({
			query: (payload) => ({
				url: '/quiz/partner/updateActiveVersion',
				method: 'PATCH',
				body: payload
			}),
			invalidatesTags: (result) => [
				{ type: 'Versions', id: 'LIST' },
				{ type: 'Versions', id: result?.versionId },
				{ type: 'Partner' }
			]
		})
	})
});

export const {
	useGetQuizVersionsQuery,
	useCreateNewQuizVersionMutation,
	useDuplicateQuizVersionMutation,
	useArchiveQuizVersionMutation,
	useSetFirstQuizQuestionMutation,
	useSetActiveVersionMutation
} = extendedApi;
