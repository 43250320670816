const theme = {
	primaryFont: 'BananaGrotesk, sans-serif',
	secondaryFont: 'TradeGothicNextLTPro, sans-serif',
	mainColor: '#E4002B',
	white: '#FFFFFF'
};

export const config = {
	fonts: [],
	quizStyle: {
		title: {
			font: theme.primaryFont,
			fontSize: '18px',
			fontWeight: 500
		},
		progressBar: {
			backgroundColor: '#EDEDED',
			completedColor: theme.mainColor,
			height: '4px',
			borderRadius: '2px'
		},
		nextButton: {
			backgroundColor: theme.mainColor,
			color: theme.white,
			border: `1px solid ${theme.mainColor}`
		},
		skipButton: {
			backgroundColor: theme.white,
			color: theme.mainColor,
			border: `1px solid ${theme.mainColor}`
		},
		tagButton: {
			borderRadius: '4px',
			border: '1px solid #de5972',
			color: theme.mainColor,
			margin: '6px'
		},
		tagButtonSelected: {
			boxShadow: 'unset',
			backgroundColor: theme.mainColor,
			color: theme.white
		},
		radioButtons: {
			radioLabelFontFamily: theme.primaryFont,
			radioLabelFontSize: '14px',
			radioLabelFontWeight: 'normal',
			radioButtonCircleColor: theme.mainColor,
			radioButtonFillerColor: theme.mainColor
		},
		questionButton: {
			questionButtonFontFamily: theme.primaryFont,
			questionButtonFontSize: '14px',
			questionButtonFontWeight: '500',
			questionButtonSelectedColor: theme.mainColor,
			questionButtonSelectedTextColor: theme.white
		},
		questionCheckbox: {
			questionCheckboxLabelFontFamily: theme.primaryFont,
			questionCheckboxLabelFontSize: '14px',
			questionCheckboxLabelFontWeight: '500'
		}
	},
	quizTexts: {
		skipButton: `Skip`,
		nextButton: `Next`,
		finishQuizButton: `Finish Quiz`
	},
	quizProgressBar: true,
	quizProgressCount: true,
	quizBack: true,
	quizFixedButtons: true
};
