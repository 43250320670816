import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { QuizVersion, useDuplicateQuizVersionMutation } from 'services/versions';

import { TextEdit } from '../question/form/TextEdit';

export const DuplicateVersionModal = (props: {
	version: QuizVersion;
	show: boolean;
	setShow: (show: boolean) => void;
}) => {
	const [duplicate] = useDuplicateQuizVersionMutation();
	const [newVersionName, setNewVersionName] = useState('');

	const version = props.version;
	useEffect(() => {
		setNewVersionName(version.name + ' duplicate');
	}, [version]);

	const handleClose = () => props.setShow(false);

	return (
		<Modal show={props.show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>Duplicate {props.version.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TextEdit
					id="new-version-name"
					label="New version name"
					value={newVersionName}
					setValue={(value: string) => setNewVersionName(value)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button
					variant="primary"
					onClick={() =>
						duplicate({ versionId: version.id, newVersion: { name: newVersionName } })
							.unwrap()
							.then(() => {
								handleClose();
								toast.success('Version duplicated');
							})
							.catch(() => {
								toast.error('Version duplication failed');
							})
					}
				>
					Duplicate
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
