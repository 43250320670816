import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { extendedApi as versionsApi } from './versions.service';

interface VersionsState {
	selectedVersionId: number | undefined;
}

const initialState: VersionsState = {
	selectedVersionId: undefined
};

export const versionsSlice = createSlice({
	name: 'versions',
	initialState,
	reducers: {
		setSelectedVersionId(state, action: PayloadAction<number | undefined>) {
			state.selectedVersionId = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(versionsApi.endpoints.setActiveVersion.matchFulfilled, (state, { payload }) => {
				state.selectedVersionId = payload.versionId;
			})
			.addMatcher(
				isAnyOf(
					versionsApi.endpoints.createNewQuizVersion.matchFulfilled,
					versionsApi.endpoints.duplicateQuizVersion.matchFulfilled
				),
				(state, { payload }) => {
					state.selectedVersionId = payload.id;
				}
			);
	}
});

export const { setSelectedVersionId } = versionsSlice.actions;

export const selectSelectedVersionId = (state: RootState) => state.versions.selectedVersionId;

export default versionsSlice.reducer;
