import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useVersionEditAllowed } from 'routes/quiz/hooks/VersionsEditAllowedHook';
import { selectSelectedQuestionId } from 'services/quiz';
import { useGetQuizVersionsQuery } from 'services/versions';

import { AddButton } from 'components/general/AddButton';

import { VersionsList } from './VersionsList';

export enum VersionViewType {
	Drafts,
	Activated
}

export const QuizVersionsOffcanvas = (props: { show: boolean; handleClose: () => void }) => {
	const selectedVersionId = useSelector(selectSelectedQuestionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const isVersionEditAllowed = useVersionEditAllowed();

	const [showNew, setShowNew] = useState(false);

	return (
		<Offcanvas
			show={props.show}
			onHide={props.handleClose}
			placement="end"
			scroll={true}
			backdrop={true}
			style={{ width: '50%', minWidth: 400, maxWidth: 600, padding: 24 }}
		>
			<OffcanvasHeader closeButton={true} />
			<OffcanvasTitle>Quiz Versions</OffcanvasTitle>
			<OffcanvasBody>
				<Tab.Container
					defaultActiveKey={selectedVersion?.isLocked ? VersionViewType.Activated : VersionViewType.Drafts}
				>
					<TitleContainer>
						<Nav variant="none">
							<Nav.Item>
								<TabButton eventKey={VersionViewType.Drafts}>Drafts</TabButton>
							</Nav.Item>
							<Nav.Item>
								<TabButton eventKey={VersionViewType.Activated}>Activated</TabButton>
							</Nav.Item>
						</Nav>
						{isVersionEditAllowed && (
							<AddButton
								onClick={() => setShowNew(true)}
								borderRadius={30}
								fontSize={16}
								text="New Version"
							/>
						)}
					</TitleContainer>
					<Tab.Content>
						<Tab.Pane eventKey={VersionViewType.Drafts}>
							<VersionsList
								showNew={showNew}
								handleCloseModal={props.handleClose}
								handleCloseNewVersion={() => setShowNew(false)}
								type={VersionViewType.Drafts}
							/>
						</Tab.Pane>
						<Tab.Pane eventKey={VersionViewType.Activated}>
							<VersionsList
								showNew={showNew}
								handleCloseModal={props.handleClose}
								handleCloseNewVersion={() => setShowNew(false)}
								type={VersionViewType.Activated}
							/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

const OffcanvasHeader = styled(Offcanvas.Header)`
	padding: 0 0 24px 0;
`;

const OffcanvasTitle = styled(Offcanvas.Title)`
	font-size: 26px;
	font-weight: 400;

	padding: 16px 4px;
`;

const OffcanvasBody = styled(Offcanvas.Body)`
	padding: 16px 4px;

	display: flex;
	flex-direction: column;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	margin-bottom: 24px;
`;

const TabButton = styled(Nav.Link)`
	margin-right: 8px;
	padding: 8px 28px;
	font-size: 16px;
	color: black;
	border-radius: 24px;
	border: 1px solid black;

	&:hover {
		color: unset;
	}

	&.active {
		color: white;
		background-color: #1f767a;
		border: 1px solid #1f767a;
	}
`;
