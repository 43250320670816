import React from 'react';
import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';
import { CheckboxBorderColor, CheckboxSelectedBackgroundColor } from 'constants/colors';

import { FormGroup } from './FormGroup';

export const QuestionCheckbox = (props: {
	id: string;
	label: string;
	value: boolean;
	setValue: (value: boolean) => void;
	disabled?: boolean;
}) => (
	<FormGroup controlId={props.id}>
		<FormCheck
			type={'checkbox'}
			label={props.label}
			checked={props.value}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				props.setValue(!props.value);
			}}
			disabled={props.disabled}
		/>
	</FormGroup>
);

const CheckSize = 25;

const FormCheck = styled(Form.Check)`
	font-size: 13px;

	padding-left: calc(${CheckSize}px + 0.5em);
	.form-check-input {
		height: ${CheckSize}px;
		width: ${CheckSize}px;

		border-radius: 7px;
		border-color: ${CheckboxBorderColor};

		margin: 0 0 0 calc(-${CheckSize}px - 0.5em);

		&:checked[type='checkbox'] {
			border-color: ${CheckboxSelectedBackgroundColor};
			background-color: ${CheckboxSelectedBackgroundColor};
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M0.5625 8.4375L6.46875 14.625L17.4375 3.375' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
		}
	}

	.form-check-label {
		line-height: 1.2em;
		margin-top: calc((${CheckSize}px - 1em) / 2);
	}
`;
