import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { selectQuestions } from 'services/quiz';

import { QuestionsListButton } from './QuestionsListButton';
import { QuestionsListRow } from './QuestionsListRow';

const QuestionsAccordionKey = 'QuestionsAccordionKey';

export const QuestionsList = () => {
	const [search, setSearch] = useState('');
	const { questionIds, idToQuestion } = useSelector(selectQuestions);

	if (!idToQuestion) {
		return null;
	}
	const filterQuestions = (id: number) =>
		idToQuestion[id].name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
		idToQuestion[id].title?.toLocaleLowerCase().includes(search.toLocaleLowerCase());

	return (
		<AccordionWrapper>
			<StyledAccordion>
				<QuestionsListButton search={search} onChange={setSearch} />
				<StyledAccordionCollapse eventKey={QuestionsAccordionKey}>
					<ListGroup>
						{questionIds?.filter(filterQuestions).map((questionId) => (
							<ListGroup.Item
								key={questionId}
								style={{
									padding: 0,
									borderRadius: 0
								}}
							>
								<QuestionsListRow
									questionId={questionId}
									title={idToQuestion[questionId].name ?? idToQuestion[questionId].title}
								/>
							</ListGroup.Item>
						))}
					</ListGroup>
				</StyledAccordionCollapse>
			</StyledAccordion>
		</AccordionWrapper>
	);
};

const AccordionWrapper = styled.div`
	height: 100%;
	overflow: hidden;

	padding-bottom: 32px;

	display: flex;
	flex-direction: column;
`;

const StyledAccordion = styled(Accordion)`
	width: 256px;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	border: 1px solid #cdbfb3;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px 2px 2px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
`;

const StyledAccordionCollapse = styled(Accordion.Collapse)`
	overflow: auto;
	background-color: white;

	min-height: 196px;

	width: 100%;
	padding-right: 0px;
	pointer-events: auto;
`;
