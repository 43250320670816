import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';
import { Role } from 'services/teams';

import { MemberOffCanvasVariant } from './MemberOffcanvas';
import { WishiRadio } from './WishiRadio';

const RoleOptions = (variant: MemberOffCanvasVariant) => [
	{
		id: Role.admin,
		label: {
			title: (variant === MemberOffCanvasVariant.Invite ? 'Invite an' : 'Set as') + ' Admin',
			description: 'Admins will have full access including activating versions'
		}
	},
	{
		id: Role.editor,
		label: {
			title: (variant === MemberOffCanvasVariant.Invite ? 'Invite' : 'Set') + ' with Editor access',
			description: 'Editor will be able to edit the quiz but not activate'
		}
	},
	{
		id: Role.viewer,
		label: {
			title: (variant === MemberOffCanvasVariant.Invite ? 'Invite' : 'Set') + ' with View-Only access',
			description: 'Allow view-only access to everything'
		}
	}
];

export const RolesRadio = (props: {
	selectedId: string | undefined;
	setSelectedId: (id: string) => void;
	variant: MemberOffCanvasVariant;
}) => {
	return (
		<Form>
			{RoleOptions(props.variant).map((option) => {
				const isSelected = option.id === props.selectedId;
				return (
					<RadioContainer
						key={option.id}
						style={{
							boxShadow: isSelected ? '0 0 0 2px #0F3411' : '0 0 0 1px #cfccca, 0 0 0 1px transparent'
						}}
					>
						<WishiRadio
							groupId="roles-selection-radio"
							id={option.id}
							isSelected={isSelected}
							setSelected={() => props.setSelectedId(option.id)}
							label={<LabelElement {...option.label} />}
						/>
					</RadioContainer>
				);
			})}
		</Form>
	);
};

const LabelElement = (props: { title: string; description: string }) => {
	return (
		<LabelContainer>
			<LabelTitle>{props.title}</LabelTitle>
			<LabelDescription>{props.description}</LabelDescription>
		</LabelContainer>
	);
};

const LabelContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const LabelTitle = styled.span`
	font-size: 16px;
	font-weight: 500;
`;
const LabelDescription = styled.span`
	font-size: 12px;
	font-weight: 400;
`;

const RadioContainer = styled.div`
	border-radius: 17px;
	margin-bottom: 24px;
`;
