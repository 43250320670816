import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { selectIsNewQuestion, selectSelectedQuestionId } from 'services/quiz';

import PageLayout from 'components/page-layout';

import QuestionsGraph from './components/graph/QuestionsGraph';
import QuestionDetails from './components/question/QuestionDetails';

export const Quiz: FC = () => {
	const selectedQuestionId = useSelector(selectSelectedQuestionId);
	const isNewQuestion = useSelector(selectIsNewQuestion);
	const displayquestion = selectedQuestionId || isNewQuestion;

	return (
		<PageLayout>
			<QuizContainer>
				<QuestionsGraphContainer style={{ flex: displayquestion ? 0 : 1 }}>
					<QuestionsGraph />
				</QuestionsGraphContainer>

				{displayquestion && (
					<QuestionDetailsContainer>
						<QuestionDetails />
					</QuestionDetailsContainer>
				)}
			</QuizContainer>
		</PageLayout>
	);
};

const QuizContainer = styled.div`
	display: flex;
	flex-direction: row;

	width: 100%;
	height: 100%;
`;

const QuestionsGraphContainer = styled.div`
	overflow: auto;

	position: relative;
`;

const QuestionDetailsContainer = styled.div`
	overflow: auto;
`;
