import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { QuestionPreviewBackgroundColor, QuestionPreviewBorderColor } from 'constants/colors';
import { selectSelectedQuestion } from 'services/quiz';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { SdkWrapper } from '../preview/SdkWrapper';

import { QuestionDetailsForm } from './QuestionDetailsForm';
import { useQuestionDetails } from './QuestionDetailsHook';

const DummyQuestionId = 1234567890;

const QuestionDetails = () => {
	const question = useSelector(selectSelectedQuestion);

	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const questionDetails = useQuestionDetails(question);

	if (!selectedVersion) {
		return <>Select version</>;
	}

	return (
		<Container>
			<FormContainer>
				<QuestionDetailsForm questionDetails={questionDetails} />
			</FormContainer>
			<PreviewContainer>
				<QuestionPreviewContainer>
					<PreviewTitleSpan>Client Experience</PreviewTitleSpan>
					<QuestionContainer>
						<SdkWrapper
							isSingleQuestion={true}
							firstQuestionId={DummyQuestionId}
							idToQuestion={{
								[DummyQuestionId]: {
									...questionDetails.question,
									id: DummyQuestionId
								}
							}}
						/>
					</QuestionContainer>
				</QuestionPreviewContainer>
			</PreviewContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

const FormContainer = styled.div`
	flex: 1 1;

	overflow: auto;

	height: 100%;
`;

const PreviewContainer = styled.div`
	overflow: auto;
`;

const QuestionPreviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	background-color: ${QuestionPreviewBackgroundColor};

	padding: 24px;
`;

const PreviewTitleSpan = styled.span`
	font-size: 26px;
	padding: 16px 16px 40px 16px;
`;

const QuestionBorderWidth = 1;
const QuestionContainer = styled.div`
	width: ${426 + QuestionBorderWidth * 2}px;

	height: ${765 + QuestionBorderWidth * 2}px;

	border: 1px solid ${QuestionPreviewBorderColor};
	border-radius: 10px;
	overflow: hidden;
`;

export default QuestionDetails;
