import { FC } from 'react';
import styled from '@emotion/styled';
import { getShortComparePeriod, getShortDatePeriod, TComparePeriod, TDatePeriod } from 'utils';

import { palette } from '../../constants/styles';
import { Select } from '../general';

import { dateCompares, datePeriods } from './optionsMap';

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	min-width: 0;
`;

const Container = styled(FlexContainer)`
	font-size: 16px;
`;

const PeriodContainer = styled(FlexContainer)`
	border: 1px solid ${palette.elementBorderColor};
	background: ${palette.elementBackground};
	border-radius: 4px;
	box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1);
`;

const CompareToContainer = styled(PeriodContainer)``;

const SelectedDates = styled.div`
	padding: 10px;
	border-left: 1px solid ${palette.elementBorderColorLight};
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Label = styled.div`
	margin: 0 20px;
`;

type TProps = {
	period?: TDatePeriod;
	compareTo?: TComparePeriod;
	onChange: (range: TDatePeriod, compareTo?: TComparePeriod) => void;
	hasCompare?: boolean;
};

export const ComparisonDatePicker: FC<TProps> = ({
	period = TDatePeriod.last4Weeks,
	compareTo = TComparePeriod.none,
	onChange,
	hasCompare = true
}) => {
	return (
		<Container>
			<PeriodContainer>
				<Select
					value={period}
					options={datePeriods}
					onChange={(selectedValue) => onChange(selectedValue, compareTo)}
				/>
				<SelectedDates>{period && getShortDatePeriod(period)}</SelectedDates>
			</PeriodContainer>
			{hasCompare && period !== TDatePeriod.allTime && (
				<>
					<Label>compare to</Label>
					<CompareToContainer>
						<Select
							value={compareTo}
							options={dateCompares}
							onChange={(selectedValue) => onChange(period, selectedValue)}
						/>
						{compareTo !== TComparePeriod.none && (
							<SelectedDates>
								{compareTo && period && getShortComparePeriod(period, compareTo)}
							</SelectedDates>
						)}
					</CompareToContainer>
				</>
			)}
		</Container>
	);
};
