import React from 'react';
import styled from '@emotion/styled';

const OurNumbersContainer = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--fpAccentColor);
	margin: 56px var(--fpMinSectionSideMargin) 0;
	color: #ffffff;
	padding: 54px 56px 53px 56px;
	border-radius: var(--fpSectionBorderRadius);

	@media (max-width: 1024px) {
		margin-top: 0;
	}
`;

const SectionHeader = styled.h3`
	font-size: 32px;
	font-weight: 400;
	line-height: 38px;
	text-align: center;
	max-width: 926px;
	margin: 0;
`;

const Separator = styled.div`
	height: 1px;
	background: #ffffff;
	margin: 48px 0 54px 0;
	width: 100%;
`;

const NumbersGrid = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		margin-top: -50px;
	}
`;

const GridCell = styled.div`
	width: 33.3%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 1024px) {
		margin-top: 50px;
	}
`;

const GridCellIcon = styled.div<{ image: 'aov' | 'upt' | 'engagement' }>`
	width: 50px;
	height: 52px;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(/images/frontPage/${(p) => p.image}_icon.png);
`;
const GridCellTitle = styled.div`
	font-size: 22px;
	line-height: 34px;
	margin-top: 17px;
	white-space: nowrap;
`;
const GridCellNumber = styled.div`
	font-size: 110px;
	line-height: 115px;
	margin-top: 7px;
`;

export const OurNumbers = () => {
	return (
		<OurNumbersContainer>
			<SectionHeader>
				Tech-enabled experts create the most personalized recommendations for our partners with record breaking
				results.
			</SectionHeader>
			<Separator />
			<NumbersGrid>
				<GridCell>
					<GridCellIcon image="aov" />
					<GridCellTitle>Increase in AOV</GridCellTitle>
					<GridCellNumber>141%</GridCellNumber>
				</GridCell>
				<GridCell>
					<GridCellIcon image="upt" />
					<GridCellTitle>Increase in UPT</GridCellTitle>
					<GridCellNumber>233%</GridCellNumber>
				</GridCell>
				<GridCell>
					<GridCellIcon image="engagement" />
					<GridCellTitle>Increased Engagement</GridCellTitle>
					<GridCellNumber>151%</GridCellNumber>
				</GridCell>
			</NumbersGrid>
		</OurNumbersContainer>
	);
};
