import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { ReactComponent as Followup } from 'assets/icons/cond-followup.svg';
import { ReactComponent as Once } from 'assets/icons/cond-once.svg';
import { ReactComponent as Unless } from 'assets/icons/cond-unless-replied.svg';
import { NeutralColor, QuestionNodeRegularColor, QuestionNodeRequiredColor } from 'constants/colors';
import { Node, NodeProps } from 'reaflow';
import { DisplayCondition, selectQuestion } from 'services/quiz';
import { hex2rgba } from 'services/quiz/quiz.utils';

import { CustomNodeData, firstQuestionNodeId, lastQuestionNodeId } from './QuestionsGraph';

export const QuestionsGraphNode = (props: { nodeProps: NodeProps<CustomNodeData> }) => {
	const dispatch = useDispatch();
	const selectQuestionCallback = React.useCallback(
		(questionId: number) => dispatch(selectQuestion(questionId)),
		[dispatch]
	);

	if (props.nodeProps.id === firstQuestionNodeId || props.nodeProps.id === lastQuestionNodeId) {
		return (
			<GraphNode {...props}>
				{(event) => (
					<foreignObject height={event.height} width={event.width} x={0} y={0} pointerEvents="none">
						<QuestionNodeContainerWrapper>
							<FirstQuestionNodeContainer>
								<StartNodeTitleSpan>
									{props.nodeProps.id === firstQuestionNodeId
										? 'Client begins session'
										: 'Session ends'}
								</StartNodeTitleSpan>
							</FirstQuestionNodeContainer>
						</QuestionNodeContainerWrapper>
					</foreignObject>
				)}
			</GraphNode>
		);
	}

	return (
		<GraphNode
			{...props}
			onClick={() => {
				if (props.nodeProps.properties.data?.questionId != null) {
					selectQuestionCallback(props.nodeProps.properties.data?.questionId);
				}
			}}
		>
			{(event) => {
				const isConditional = event.node.data?.isConditional;
				const getConditionIcon = (type: DisplayCondition) => {
					switch (type) {
						case 'DisplayOnce':
							return <Once />;
						case 'DisplayUnanswered':
							return <Unless />;
						case 'FollowUp':
							return <Followup />;
						default:
							return null;
					}
				};

				return (
					<foreignObject height={event.height} width={event.width} x={0} y={0} pointerEvents="none">
						<QuestionNodeContainerWrapper>
							<QuestionNodeContainer $conditional={isConditional} $color={event.node.data.color}>
								<QuestionTitleSpan>{event.node.data.text}</QuestionTitleSpan>
								{isConditional && (
									<QuestionConditionsContainer>
										<IconContainer>
											{getConditionIcon(event.node.data.condition.type)}
										</IconContainer>
										<ConditionLabel $color={hex2rgba(event.node.data.color, 0.2)}>
											<div>{event.node.data.condition.label}</div>
										</ConditionLabel>
									</QuestionConditionsContainer>
								)}
								<QuestionNodeDetailsContainer>
									<QuestionDetailsSpan>
										{event.node.data.maxRemainingQuestions != null &&
											event.node.data.maxRemainingQuestions + ' left'}
									</QuestionDetailsSpan>
									<QuestionDetailsSpan $isWarn={!event.node.data.isSkippable}>
										{event.node.data.isSkippable ? 'Optional' : 'Required'}
									</QuestionDetailsSpan>
								</QuestionNodeDetailsContainer>
							</QuestionNodeContainer>
						</QuestionNodeContainerWrapper>
					</foreignObject>
				);
			}}
		</GraphNode>
	);
};

const GraphNode = styled(Node)`
	fill: transparent;
	stroke: none;
`;

const QuestionTitleSpan = styled.div`
	font-size: 14px;
	max-height: 100%;
	width: 100%;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const StartNodeTitleSpan = styled.div`
	font-size: 14px;
	max-height: 100%;
	width: 100%;
	text-align: center;
	color: #fff;
	line-height: normal;
`;

const QuestionDetailsSpan = styled.div<{ $isWarn?: boolean }>`
	font-size: 10px;
	line-height: 1.4em;
	color: ${(props) => (props.$isWarn ? QuestionNodeRequiredColor : QuestionNodeRegularColor)};
`;

const BoxShadowHorizontal = 0;
const BoxShadowVertical = 4;
const BoxShadowBlurRadius = 4;
const BoxShadow = `${BoxShadowHorizontal}px ${BoxShadowVertical}px ${BoxShadowBlurRadius}px rgba(0, 0, 0, 0.1)`;

export const BoxShadowMargins = BoxShadowBlurRadius + Math.max(BoxShadowVertical, BoxShadowHorizontal);

const QuestionNodeContainerWrapper = styled.div`
	height: 100%;
	width: 100%;
	padding: ${BoxShadowMargins}px;
`;

const QuestionNodeContainer = styled.div<{ $conditional?: boolean; $color?: string }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 20px;
	border-radius: 17px;
	box-shadow: ${BoxShadow};
	background-color: #fff;
	border-style: ${(props) => (props.$conditional ? 'dashed' : props.$color === NeutralColor ? 'none' : 'solid')};
	border-color: ${(props) => props.$color ?? NeutralColor};
	border-width: 1px;
`;

const FirstQuestionNodeContainer = styled(QuestionNodeContainer)`
	padding: 8px;
	background-color: #3870a3;
	border-style: none;
`;

const QuestionConditionsContainer = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
	width: 100%;
	margin-top: 5px;
`;

const QuestionNodeDetailsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 5px;
`;

const IconContainer = styled.div`
	min-width: 20px;
	height: 20px;

	svg {
		width: 100%;
		height: 100%;
	}
`;

const ConditionLabel = styled.div<{ $color?: string }>`
	border-radius: 2px;
	background: ${(props) => props.$color ?? NeutralColor};
	padding: 4px 6px;

	div {
		font-size: 12px;
		max-height: 38px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
`;
