import { FC } from 'react';
import styled from '@emotion/styled';

import firstItemBackground from '../assets/arrowLikeFirstItemMask.svg';
import itemBackground from '../assets/arrowLikeItemMask.svg';
import { TMappedDashboardItem } from '../mappers';

import { Metric } from './Metric';

const Container = styled.div`
	--metricComparisonMargin: 0 -12px;
	margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 16px;
`;

const Items = styled.div`
	display: flex;
	gap: 5px;
`;

const Item = styled.div<{ $clickable?: boolean }>`
	width: 304px;
	height: 204px;
	background: url(${itemBackground}) no-repeat;
	cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
	&:first-of-type {
		width: 306px;
		background: url(${firstItemBackground}) no-repeat;
	}
`;

type TProps = {
	title: string;
	items: TMappedDashboardItem[];
};

export const ArrowLikeSection: FC<TProps> = ({ title, items }) => {
	return (
		<Container>
			<SectionTitle>{title}</SectionTitle>
			<Items>
				{items.map((item, index) => (
					<Item $clickable={!!item.onClick} key={index} onClick={item.onClick}>
						<Metric {...item} />
					</Item>
				))}
			</Items>
		</Container>
	);
};
