import { TComparePeriod, TDatePeriod } from 'utils';

export const datePeriods = [
	{ value: TDatePeriod.today, label: 'Today' },
	{ value: TDatePeriod.last7Days, label: 'Last 7 days' },
	{ value: TDatePeriod.last4Weeks, label: 'Last 4 weeks' },
	{ value: TDatePeriod.last3Months, label: 'Last 3 months' },
	{ value: TDatePeriod.last6Months, label: 'Last 6 months' },
	{ value: TDatePeriod.last12Months, label: 'Last 12 months' },
	{ value: TDatePeriod.currentMonth, label: 'Current month' },
	{ value: TDatePeriod.currentQuarter, label: 'Current quarter' },
	{ value: TDatePeriod.currentYear, label: 'Current year' },
	{ value: TDatePeriod.allTime, label: 'All time' }
];
export const dateCompares = [
	{ value: TComparePeriod.previousPeriod, label: 'Previous period' },
	{ value: TComparePeriod.previousMonth, label: 'Previous month' },
	{ value: TComparePeriod.previousQuarter, label: 'Previous quarter' },
	{ value: TComparePeriod.previousYear, label: 'Previous year' },
	{ value: TComparePeriod.none, label: 'No comparison' }
];
