import { rootSplitApi } from 'redux/base-api';

import { getFormattedPeriods } from '../../utils';

import { TDashboardQueryParams, TDashboardQueryRequestParams, TDashboardResponse } from './insights.types';

// This function is needed for supporting the legacy data structure
const normalizeDashboardData = (rawData: any): TDashboardResponse => {
	if (rawData.quizProgressStats) {
		const { active, completed, shoppers, started } = rawData.quizProgressStats;
		return {
			...rawData,
			quizProgressStarted: started,
			quizProgressCompleted: completed,
			quizProgressActive: active,
			quizProgressShoppers: shoppers
		};
	}

	return rawData;
};

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Insights'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getDashboard: build.query<TDashboardResponse, TDashboardQueryParams>({
			query: ({ datePeriod, comparePeriod }) => {
				const { periodFrom, periodTo, compareFrom, compareTo } = getFormattedPeriods(datePeriod, comparePeriod);
				const params: TDashboardQueryRequestParams = {
					date: periodFrom,
					to: periodTo
				};

				if (comparePeriod) {
					params.comparisonFrom = compareFrom;
					params.comparisonTo = compareTo;
				}

				return {
					url: '/partnersUser/dashboard',
					method: 'GET',
					params
				};
			},
			transformResponse: (response: TDashboardResponse) => normalizeDashboardData(response),
			providesTags: () => [{ type: 'Insights', id: 'Dashboard' }]
		})
	}),
	overrideExisting: false
});

export const { useGetDashboardQuery } = extendedApi;
