import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { selectQuestion } from 'services/quiz';

import { SingleQuestionActions } from '../../question/SingleQuestionActions';

export const QuestionsListRow = (props: { questionId: number; title: string }) => {
	const dispatch = useDispatch();

	return (
		<Dropdown as={ButtonGroup} style={{ width: '100%' }}>
			<QuestionButton
				style={{ flex: '1 1', padding: '8px 16px' }}
				onClick={() => dispatch(selectQuestion(props.questionId))}
				variant="plain"
			>
				{props.title}
			</QuestionButton>
			<SingleQuestionActions questionId={props.questionId} />
		</Dropdown>
	);
};

const QuestionButton = styled(Button)`
	background-color: #fff;
	border: 0;
	border-radius: 0;

	text-align: left;

	color: #6a5644;
	font-size: 11px;

	&:hover {
		background-color: #eee;
		border: 0;
	}
`;
