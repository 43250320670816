import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { selectApiToken } from 'services/user';

export const ProtectedRoute: React.FC = () => {
	const location = useLocation();

	const apiToken = useSelector(selectApiToken);

	if (!apiToken) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Outlet />;
};
