import { CSSProperties, MutableRefObject } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styled from '@emotion/styled';
import { CanvasRef } from 'reaflow';

import { ReactComponent as ZoomMinus } from '../../../../../assets/icons/zoom_minus.svg';
import { ReactComponent as ZoomPlus } from '../../../../../assets/icons/zoom_plus.svg';

import { GraphMenuButtonStyled } from './GraphMenuButton';

const IconSize = 22;

export const QuestionsGraphZoomMenu = (props: {
	zoom: number;
	canvasRef: MutableRefObject<CanvasRef | null>;
	style?: CSSProperties;
}) => {
	return (
		<GraphMenuButtonGroup style={props.style}>
			<ZoomGraphMenuButtonStyles
				onClick={() => {
					if (props.canvasRef.current?.zoomOut) {
						props.canvasRef.current.zoomOut();
					}
				}}
			>
				<ZoomMinus height={IconSize} width={IconSize} />
			</ZoomGraphMenuButtonStyles>
			<ZoomGraphMenuButtonStyles
				onClick={() => {
					if (props.canvasRef.current?.fitCanvas) {
						props.canvasRef.current.fitCanvas();
					}
				}}
			>
				Fit to
				<br />
				screen
			</ZoomGraphMenuButtonStyles>
			<ZoomGraphMenuButtonStyles
				onClick={() => {
					if (props.canvasRef.current?.zoomIn) {
						props.canvasRef.current.zoomIn();
					}
				}}
			>
				<ZoomPlus height={IconSize} width={IconSize} />
			</ZoomGraphMenuButtonStyles>
		</GraphMenuButtonGroup>
	);
};

const GraphMenuButtonGroup = styled(ButtonGroup)``;

const ZoomGraphMenuButtonStyles = styled(GraphMenuButtonStyled)`
	font-size: 13px;
	padding: 8px 14px;
`;
