// const Inactive = '#E9E1D5';
const Alert = '#98191d';
const ModuleBg = '#FFF';
const ScreenBg = '#F4F2EF';
const Stroke = '#E5DDD7';
// const ConnectorLines = '#BBA889';
const ButtonOutlines = '#3D3D3D';
const Text = '#121212';
const Success = '#1F767A';

const InputBorderColor = '#CDBFB3';

export const ButtonBorderColor = '#3D3D3D';
export const ButtonHoverBackgroundColor = '#000';
export const ButtonHoverTextColor = '#FFF';
export const ButtonSelectedColor = '#424242';
export const ButtonInactiveColor = '#E9E1D5';

export const PrimaryButtonBackgroundColor = '#222222';
export const PrimaryButtonTextColor = '#FFFFFF';

export const SecondaryButtonBackgroundColor = 'transparent';
export const SecondaryButtonTextColor = '#222222';

export const TopBarBackground = ModuleBg;
export const TopBarBorder = Stroke;

export const SuccessColor = Success;
export const ErrorColor = Alert;
export const ErrorTextColor = ModuleBg;

export const GraphBackgroundColor = ScreenBg;

export const GraphMenuButtonColor = GraphBackgroundColor;
export const GraphMenuButtonHoverColor = GraphBackgroundColor;
export const GraphMenuButtonTextColor = Text;
export const GraphMenuButtonBorderColor = ButtonOutlines;

export const QuestionDetailsBackgroundColor = ModuleBg;

export const TextFieldBackgroundColor = ScreenBg;

export const AnswerContainerBorderColor = Stroke;

export const CheckboxBorderColor = InputBorderColor;
export const CheckboxSelectedBackgroundColor = PrimaryButtonBackgroundColor;

export const QuestionPreviewBackgroundColor = ScreenBg;
export const QuestionPreviewBorderColor = Stroke;

export const QuestionFormSectionBorder = Stroke;

export const PageBackgroundColor = ScreenBg;

export const TableBorderColor = Stroke;
export const TableBackgroundColor = ModuleBg;

export const QuestionNodeRequiredColor = '#A95028';
export const QuestionNodeRegularColor = '#747474';

export const NeutralColor = '#bba889';
export const FamilyColors = ['#027f4c', '#f79029', '#3870a3', '#ffca04', '#ff6870', '#A51890'];
