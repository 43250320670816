import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { selectPartnerKey } from 'services/user';

import { RootState } from './store';

const baseUrl = process.env.REACT_APP_API_GW_URL;

const rawBaseQuery = fetchBaseQuery({
	baseUrl,
	timeout: 20 * 1000,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth?.apiToken;

		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	}
});
export const partnerKeyPlaceholder = 'partner-key-placeholder';

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	const urlEnd = typeof args === 'string' ? args : args.url;
	if (urlEnd.includes(partnerKeyPlaceholder)) {
		const partnerKey = selectPartnerKey(api.getState() as RootState);
		if (!partnerKey) {
			return {
				error: {
					status: 400,
					statusText: 'Bad Request',
					data: 'No partner key'
				}
			};
		}
		const adjustedUrl = urlEnd.replace(partnerKeyPlaceholder, partnerKey);
		const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
		return rawBaseQuery(adjustedArgs, api, extraOptions);
	}
	return rawBaseQuery(args, api, extraOptions);
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootSplitApi = createApi({
	baseQuery: dynamicBaseQuery,
	endpoints: () => ({})
});
