import { Dropdown } from './form/Dropdown';
import { CategoryOptionInfo } from './categories';

export const AnswerCategoryKeySelector = (props: {
	answerKey: string;
	answerCategoryKey?: string;
	updateAnswerCategoryKey: (newKey?: string) => void;
	categoryOptions: CategoryOptionInfo[];
	disabled?: boolean;
}) => {
	return (
		<Dropdown
			id={`answer-${props.answerKey}-category-key`}
			label="Category key"
			selectedValue={props.answerCategoryKey}
			setValue={(value: string | undefined) => props.updateAnswerCategoryKey(value)}
			options={props.categoryOptions.map((option) => ({
				value: option.key,
				name: option.name
			}))}
			disabled={props.disabled}
			undefinedOptionText="Select one"
		/>
	);
};
