import { QuestionCheckbox } from '../form/QuestionCheckbox';
import { QuestionConditionsSelector } from '../QuestionConditionSelector';
import { QuestionDetails } from '../QuestionDetailsHook';
import { QuestionLeadsToSelector } from '../QuestionLeadsToSelector';

import { DetailsSectionTemplate } from './DetailsSectionTemplate';

export const LogicSection = (props: {
	questionDetails: QuestionDetails;
	questionId?: number;
	questionParentsIds?: Set<number>;
	disabled?: boolean;
}) => {
	return (
		<DetailsSectionTemplate
			title="Logic"
			elementSections={[
				{
					key: 'logic-section-selectors',
					elements: [
						<QuestionLeadsToSelector
							key="question-leadsTo"
							id="question-leadsTo"
							questionId={props.questionId}
							questionParentsIds={props.questionParentsIds}
							questionLeadsTo={props.questionDetails.question.leadsTo}
							setQuestionLeadsTo={props.questionDetails.questionSetters.setQuestionLeadsTo}
							disabled={props.disabled}
						/>,
						<QuestionConditionsSelector
							key="question-condition"
							id="question-condition"
							questionId={props.questionId}
							questionCondition={props.questionDetails.question.condition}
							setQuestionCondition={props.questionDetails.questionSetters.setQuestionCondition}
							disabled={props.disabled}
						/>
					]
				},
				{
					key: 'logic-section-checkboxes',
					elements: [
						<QuestionCheckbox
							key="is-skippable"
							id="is-skippable"
							label="This question is optional, and can be skipped"
							value={props.questionDetails.question.isSkippable}
							setValue={props.questionDetails.questionSetters.setQuestionIsSkippable}
							disabled={props.disabled}
						/>
					]
				}
			]}
		/>
	);
};
