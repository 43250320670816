import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { TextEdit } from 'routes/quiz/components/question/form/TextEdit';
import {
	Role,
	StringToRole,
	TeamMember,
	useEditTeamMemberRoleMutation,
	useInviteTeamMemberMutation
} from 'services/teams';

import { ActionButton } from 'components/general/ActionButton';

import { RolesRadio } from './RolesRadio';

export enum MemberOffCanvasVariant {
	Invite,
	Edit
}

const DefaultRole = Role.viewer;

export const MemberOffCanvas = (props: {
	close: () => void;
	// hide when mode is undefined
	mode?:
		| { variant: MemberOffCanvasVariant.Edit; member: TeamMember }
		| { variant: MemberOffCanvasVariant.Invite; member: undefined };
}) => {
	const [inviteTeamMember] = useInviteTeamMemberMutation();
	const [editTeamMemberRole] = useEditTeamMemberRoleMutation();

	const [newMemberEmail, setNewMemberEmail] = useState('');
	const [selectedRole, setSelectedRole] = useState<Role>(DefaultRole);

	const member = props.mode?.member;
	useEffect(() => {
		if (member) {
			setSelectedRole(member.role);
		} else {
			setSelectedRole(DefaultRole);
		}
	}, [member]);

	const title =
		props.mode?.variant === MemberOffCanvasVariant.Edit
			? `Edit ${props.mode.member.firstName + ' ' + props.mode.member.lastName} permissions`
			: 'Invite a Team Member';

	const onActionClick = () => {
		if (!selectedRole) {
			toast.error('Please select role');
			return;
		}

		if (props.mode?.variant === MemberOffCanvasVariant.Invite) {
			inviteFn();
		}

		if (props.mode?.variant === MemberOffCanvasVariant.Edit) {
			editFn(props.mode.member.uuid);
		}
	};

	const inviteFn = () => {
		if (!newMemberEmail) {
			toast.error('Please set inviting email');
			return;
		}

		inviteTeamMember({
			emailAddress: newMemberEmail,
			role: selectedRole
		})
			.unwrap()
			.then(() => {
				setNewMemberEmail('');
				setSelectedRole(DefaultRole);
				props.close();
			})
			.catch(() => toast.error('Failed to set email'));
	};

	const editFn = (userId: string) => {
		editTeamMemberRole({
			userId,
			role: selectedRole
		})
			.unwrap()
			.then(() => {
				setSelectedRole(DefaultRole);
				props.close();
			})
			.catch(() => toast.error('Failed to set new role'));
	};

	return (
		<Offcanvas
			show={props.mode != null}
			onHide={props.close}
			placement="end"
			scroll={true}
			backdrop={true}
			style={{ width: '50%', minWidth: 400, maxWidth: 600, padding: 24 }}
		>
			<OffcanvasHeader closeButton={true} />
			<Offcanvas.Body>
				<Section>
					<OffcanvasTitle>{title}</OffcanvasTitle>
					{props.mode?.variant === MemberOffCanvasVariant.Invite && (
						<TextEdit
							id="add-user-email-edit"
							label="Enter team member email addresses"
							value={newMemberEmail}
							setValue={setNewMemberEmail}
							autoFocus={true}
						/>
					)}
				</Section>
				<Section>
					<OffcanvasTitle>Team Member permissions</OffcanvasTitle>
					<RolesRadio
						variant={props.mode?.variant ?? MemberOffCanvasVariant.Invite}
						selectedId={selectedRole}
						setSelectedId={(role: string) => setSelectedRole(StringToRole[role])}
					/>
				</Section>
				<ActionsSection>
					<ActionButton variant="link" onClick={props.close}>
						Cancel
					</ActionButton>
					<ActionButton
						variant="primary"
						onClick={() => onActionClick()}
						disabled={
							(props.mode?.variant === MemberOffCanvasVariant.Invite && !newMemberEmail) ||
							(props.mode?.variant === MemberOffCanvasVariant.Edit &&
								selectedRole === props.mode.member.role)
						}
					>
						{props.mode?.variant === MemberOffCanvasVariant.Invite ? 'Send Invite' : 'Set permission'}
					</ActionButton>
				</ActionsSection>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

const Section = styled.div`
	margin-bottom: 40px;
`;

const OffcanvasHeader = styled(Offcanvas.Header)`
	padding: 28px 8px 32px 8px;
`;

const OffcanvasTitle = styled(Offcanvas.Title)`
	font-size: 26px;
	margin-bottom: 20px;
`;

const ActionsSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
