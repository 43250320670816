import React from 'react';
import styled from '@emotion/styled';

const OurPartnersContainer = styled.div`
	display: flex;
	margin: 0 var(--fpMinSectionSideMargin);
	align-items: center;
	margin-top: 70px;

	@media (max-width: 1024px) {
		flex-direction: column;
		background: white;
		margin-top: 40px;
		padding: 20px 0 40px 0;
	}
`;

const Label = styled.div`
	font-size: 22px;
	line-height: 43px;
	padding-right: 50px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	margin-right: 31px;
	white-space: nowrap;

	@media (max-width: 1024px) {
		font-size: 18px;
		line-height: 28px;
		border-right: none;
		margin: 0 auto;
		padding: 0;
	}
`;

const Logos = styled.ul`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	list-style: none;
	padding: 0;

	li {
		img {
		}
	}

	@media (max-width: 1024px) {
		flex-wrap: wrap;
		justify-content: space-evenly;
		transform: scale(70%);
		width: 140%;

		li {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 19px 10px 0 10px;
		}
	}
`;

type TLogo = {
	src: string;
	alt: string;
	width: number;
};

const partnerLogos: TLogo[] = [
	{
		src: '/images/frontPage/saks_logo.png',
		alt: 'Saks',
		width: 56
	},
	{
		src: '/images/frontPage/farfetch_logo.png',
		alt: 'Farfetch',
		width: 155
	},
	{
		src: '/images/frontPage/shopbop_logo.png',
		alt: 'Shopbop',
		width: 136
	},
	{
		src: '/images/frontPage/nap_logo.png',
		alt: 'Net-A-Porter',
		width: 267
	},
	{
		src: '/images/frontPage/luisaviaroma_logo.png',
		alt: 'Luisaviaroma',
		width: 187
	}
];

export const OurPartners = () => {
	return (
		<OurPartnersContainer>
			<Label>Our Partners:</Label>
			<Logos>
				{partnerLogos.map((logo) => (
					<li key={logo.alt}>
						<img {...logo} alt={logo.alt} />
					</li>
				))}
			</Logos>
		</OurPartnersContainer>
	);
};
