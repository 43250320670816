import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ReactComponent as BackArrow } from 'assets/icons/BackArrow.svg';

import { PageContainer, PageTitleContainer, PageTitleSpan } from 'components/Page';

import { ShoppersTable } from './ShoppersTable';

export const Shoppers = () => {
	const navigate = useNavigate();

	return (
		<PageContainer>
			<TopBarContainer>
				<BackButtonStyled variant="plain" onClick={() => navigate(-1)}>
					<BackArrow width={12} height={12} style={{ marginRight: 8 }} />
					<span style={{ marginTop: 3 }}>Back</span>
				</BackButtonStyled>
			</TopBarContainer>
			<PageTitleContainer>
				<PageTitleSpan>Shoppers & Sales</PageTitleSpan>
			</PageTitleContainer>
			<ShoppersTable />
		</PageContainer>
	);
};

export const PageSubtitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 24px;
`;

export const PageSection = styled.div`
	margin-bottom: 40px;
`;

const TopBarContainer = styled.div`
	justify-content: flex-start;
`;

const BackButtonStyled = styled(Button)`
	font-size: 13px;

	padding: 8px 4px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	border: 0;
`;
