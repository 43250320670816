import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Global } from '@emotion/react';
import globalCss from 'constants/global-styles';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import store from 'redux/store';
import { AppRoutes } from 'routes';
import { useGetUserQuery } from 'services/user';

import { ErrorModalProvider } from 'components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
	const ldClient = useLDClient();
	const { data: user } = useGetUserQuery();

	useEffect(() => {
		if (ldClient && user) {
			ldClient.identify(
				{
					key: user.id,
					name: user.name
				},
				undefined
			);
		}
	}, [ldClient, user]);

	return (
		<>
			<ErrorModalProvider>
				<BrowserRouter>
					<Global styles={globalCss} />
					<AppRoutes />
				</BrowserRouter>
			</ErrorModalProvider>
			<ToastContainer position="top-center" autoClose={2000} />
		</>
	);
};

export const AppWithLDProvider = withLDProvider({
	clientSideID: process.env.REACT_APP_LAUNCHDARKLEY_SDK_KEY ?? '',
	user: {
		key: '00000000-0000-0000-a'
	}
})(() => (
	<Provider store={store}>
		<App />
	</Provider>
));
