import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useGetPartnerQuery } from 'services/user';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { TooltipBubble } from 'components/general';
import { Pill } from 'components/general/Pill';

const Container = styled.div`
	display: flex;
	align-items: center;
	pointer-events: auto;
	margin-bottom: 18px;
`;

const VersionName = styled.div`
	font-size: 32px;
	margin-right: 12px;
`;

const StyledPill = styled(Pill)`
	position: relative;
	background-color: ${(props) => props.color};

	&:hover {
		.tooltip {
			visibility: visible;
			opacity: 1;
			transition: opacity 0.3s;
		}
	}
`;

type TPillData = {
	text: string;
	color: string;
	tooltipText: string;
};

export const QuizDetails: FC = () => {
	const { data: partner } = useGetPartnerQuery();
	const activeVersionId = partner?.activeVersionId;
	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});
	const pillData = useMemo<TPillData | undefined>(() => {
		if (selectedVersion?.id === activeVersionId) {
			return {
				text: 'Active',
				color: '#BBA889',
				tooltipText:
					'This version is “Active” and can’t be edited. Add a new version or duplicate an existing version if you want to make changes'
			};
		} else if (selectedVersion?.isLocked !== true) {
			return {
				text: 'Editable version',
				color: '#58B25B',
				tooltipText: 'This version isn’t “Active” and therefore you can edit it'
			};
		}
	}, [selectedVersion, activeVersionId]);

	return (
		<Container>
			<VersionName>{selectedVersion?.name}</VersionName>
			{pillData && (
				<StyledPill color={pillData.color}>
					{pillData.text}
					<TooltipBubble className="tooltip">{pillData.tooltipText}</TooltipBubble>
				</StyledPill>
			)}
		</Container>
	);
};
