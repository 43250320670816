import { rootSplitApi } from 'redux/base-api';

import {
	CreateQuestionRequestParams,
	DeleteQuestionRequestParams,
	DuplicateQuestionRequestParams,
	QuizQuestion,
	QuizQuestionsQueryParams,
	UpdateQuestionRequestParams
} from './quiz.types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Quiz'] });

export const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getQuestions: build.query<QuizQuestion[], QuizQuestionsQueryParams>({
			query: ({ quizVersion }) => ({
				url: `/quiz`,
				method: 'GET',
				params: { quizVersion }
			}),
			transformResponse: (response: { questions: QuizQuestion[] }) => response.questions,
			providesTags: () => ['Quiz']
		}),
		updateQuestion: build.mutation<QuizQuestion, UpdateQuestionRequestParams>({
			query: (payload) => ({
				url: '/quiz/question',
				method: 'PATCH',
				body: payload
			}),
			transformResponse: (response: { question: QuizQuestion }) => response.question,
			invalidatesTags: (response) => ['Quiz']
		}),
		createQuestion: build.mutation<QuizQuestion, CreateQuestionRequestParams>({
			query: (payload) => ({
				url: '/quiz/question',
				method: 'POST',
				body: payload
			}),
			transformResponse: (response: { question: QuizQuestion }) => response.question,
			invalidatesTags: ['Quiz']
		}),
		duplicateQuestion: build.mutation<QuizQuestion, DuplicateQuestionRequestParams>({
			query: (payload) => ({
				url: '/quiz/duplicateQuestion',
				method: 'POST',
				body: payload
			}),
			transformResponse: (response: { question: QuizQuestion }) => response.question,
			invalidatesTags: ['Quiz']
		}),
		deleteQuestion: build.mutation<void, DeleteQuestionRequestParams>({
			query: ({ quizVersion, questionId }) => ({
				url: `/quiz/${quizVersion}/question/${questionId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Quiz']
		})
	})
});

export const {
	useGetQuestionsQuery,
	useLazyGetQuestionsQuery,
	useUpdateQuestionMutation,
	useCreateQuestionMutation,
	useDuplicateQuestionMutation,
	useDeleteQuestionMutation
} = extendedApi;
