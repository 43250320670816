import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';

import { TSetMessageStateFn } from '../types';

const ModalContainer = styled.div<{ show: boolean }>`
	position: fixed;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(29, 29, 29, 0.3);
	z-index: 2;
	display: ${(p) => (p.show ? 'flex' : 'none')};
`;

const Modal = styled.div`
	background-color: #ffffff;
	border: 1px solid #e5ddd7;
	padding: 35px 26px 48px 26px;
	border-radius: 18px;
	font-size: 16px;
`;

const ModalHeader = styled.div`
	font-size: 32px;
	line-height: 42px;
	letter-spacing: -0.005em;
	padding-bottom: 18px;
	border-bottom: 1px solid #cfccca;
`;

const InputContainer = styled.div`
	margin-top: 23px;

	label {
		display: block;
		line-height: 22px;
	}

	input,
	textarea {
		border: 1px solid var(--fpStrokeColor);
		background: #f3f0ed;
		border-radius: 10px;
		margin-top: 8px;
		padding: 10px 15px 9px 15px;
		width: 360px;
		line-height: 22px;

		&::placeholder {
			color: #bba889;
		}
	}
`;

const FormFooter = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
`;

const CancelButton = styled.button`
	background: transparent;
	border: 0 none;
	text-decoration: underline;
`;

const SubmitButton = styled.button`
	background: var(--fpAccentColor);
	color: #ffffff;
	line-height: 25px;
	padding: 14px 65px;
	border-radius: 100px;
	border: 0 none;
`;

export const RequestADemoModal: React.VFC<{
	setMessageStateFn: TSetMessageStateFn;
}> = ({ setMessageStateFn }) => {
	const overlayRef = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const sendDemoRequest: React.FormEventHandler = (event) => {
		event.preventDefault();
		axios
			.put(
				`${process.env.REACT_APP_API_GW_URL}/proxy/styleRequest/businessesApplicationForm`,
				Object.fromEntries(new FormData(event.target as HTMLFormElement)),
				{
					validateStatus: () => true
				}
			)
			.then((response) => {
				if (response.status === 200) {
					setMessageStateFn({
						text: 'You successfully submitted your request!',
						type: 'success',
						show: true
					});
				} else {
					setMessageStateFn({
						text: 'Something went wrong! Please try again',
						type: 'fail',
						show: true
					});
				}

				hideModal();
			});
	};

	const hideModal = () => {
		navigate('/', { replace: true });
	};

	const onClickOutside = (event: React.MouseEvent) => {
		if (event.target === overlayRef.current) {
			hideModal();
		}
	};

	return (
		<ModalContainer show={location.hash === '#contact'} ref={overlayRef} onClick={onClickOutside}>
			<Modal>
				<ModalHeader>Let's talk</ModalHeader>
				<form name="requestForm" onSubmit={sendDemoRequest}>
					<InputContainer>
						<label htmlFor="name">Full name*</label>
						<input type="text" name="name" placeholder="Enter Text" required />
					</InputContainer>
					<InputContainer>
						<label htmlFor="email">Email*</label>
						<input type="email" name="email" placeholder="Enter Text" required />
					</InputContainer>
					<InputContainer>
						<label htmlFor="phone_number">Phone number*</label>
						<input
							type="text"
							name="phone_number"
							placeholder="Enter Text"
							required
							pattern="[0-9+][0-9\-\s()]{3,16}[0-9]"
						/>
					</InputContainer>
					<InputContainer>
						<label htmlFor="company">Company name*</label>
						<input type="text" name="company" placeholder="Enter Text" required />
					</InputContainer>
					<InputContainer>
						<label htmlFor="message">Message</label>
						<textarea name="message" placeholder="Enter Text" rows={4} />
					</InputContainer>
					<FormFooter>
						<CancelButton type="button" onClick={hideModal}>
							Cancel
						</CancelButton>
						<SubmitButton>Submit</SubmitButton>
					</FormFooter>
				</form>
			</Modal>
		</ModalContainer>
	);
};
