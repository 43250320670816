import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { RootState } from 'redux/store';

import { storageParams } from 'components/login/consts';

import { extendedApi } from './user.service';

type WishiJwtPayload = JwtPayload & {
	partnerKey: string;
};

interface AuthState {
	apiToken: string | null;
	partnerKey: string | null;
}

const extractPartnerKey = (token: string | null): string | null => {
	if (!token) return null;
	const decoded = jwtDecode<WishiJwtPayload>(token);
	return decoded?.partnerKey;
};

const initialState: AuthState = {
	apiToken: localStorage.getItem(storageParams.accessToken),
	partnerKey: extractPartnerKey(localStorage.getItem(storageParams.accessToken))
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signOut(state) {
			state.apiToken = null;
			state.partnerKey = null;
		},
		setToken(state, action: PayloadAction<string>) {
			state.apiToken = action.payload;
			state.partnerKey = extractPartnerKey(action.payload);
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(extendedApi.endpoints.login.matchFulfilled, (state, { payload }) => {
			state.apiToken = payload.accessToken;
			state.partnerKey = extractPartnerKey(payload.accessToken);
		});
	}
});

export const selectApiToken = (state: RootState) => state.auth.apiToken;
export const selectPartnerKey = (state: RootState) => state.auth.partnerKey;

export const { signOut, setToken } = authSlice.actions;
export default authSlice.reducer;
