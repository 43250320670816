import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { ReactComponent as BackArrow } from 'assets/icons/BackArrow.svg';
import { QuestionDetailsBackgroundColor } from 'constants/colors';
import { useCloseQuestionDetails } from 'routes/quiz/hooks/CloseQuestionDetailsHook';
import { useQuizEditAllowed } from 'routes/quiz/hooks/QuizEditAllowedHook';
import { QuizQuestionType, selectSelectedQuestion } from 'services/quiz';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { QuestionCheckbox } from './form/QuestionCheckbox';
import { TextEdit } from './form/TextEdit';
import { AnswersSection } from './sections/AnswersSection';
import { LogicSection } from './sections/LogicSection';
import { MainDetailsSection } from './sections/MainDetailsSection';
import { QuestionActions } from './sections/QuestionActions';
import { QuestionDetails } from './QuestionDetailsHook';
import { QuestionImageEdit } from './QuestionImageEdit';
import { SingleQuestionActions } from './SingleQuestionActions';

export const QuestionDetailsForm = (props: { questionDetails: QuestionDetails }) => {
	const closeQuestionDetails = useCloseQuestionDetails();

	const question = useSelector(selectSelectedQuestion);
	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const quizEditAllowed = useQuizEditAllowed();

	const disabled = selectedVersion?.isLocked || !quizEditAllowed;

	if (!selectedVersion) {
		return <>Select version</>;
	}

	return (
		<Container>
			<FormContainer>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<TopBarContainer>
						<BackButtonStyled variant="plain" onClick={closeQuestionDetails}>
							<BackArrow width={12} height={12} style={{ marginRight: 8 }} />
							<span style={{ marginTop: 3 }}>Back</span>
						</BackButtonStyled>
					</TopBarContainer>
					<TopBarContainerEnd>
						{question && (
							<BackButtonStyled variant="plain">
								<span style={{ marginTop: 3 }}>Actions</span>
								<Dropdown as={ButtonGroup}>
									<SingleQuestionActions questionId={question?.id} />
								</Dropdown>
							</BackButtonStyled>
						)}
					</TopBarContainerEnd>
				</div>
				<MainDetailsSection questionDetails={props.questionDetails} disabled={disabled} />
				<AnswersSection
					questionId={question?.id}
					questionParentsIds={question?.parentsIds}
					questionType={props.questionDetails.question.type}
					questionCategory={props.questionDetails.question.category}
					answerOptions={props.questionDetails.question.answers}
					setAnswerOptions={props.questionDetails.questionSetters.setAnswerOptions}
					answerGroups={props.questionDetails.question.answerGroups}
					setAnswerGroups={props.questionDetails.questionSetters.setAnswerGroups}
					answerKeyToCategoryKeyMapping={props.questionDetails.question.answerKeyToCategoryKeyMapping}
					setAnswerKeyToCategoryKeyMapping={
						props.questionDetails.questionSetters.setAnswerKeyToCategoryKeyMapping
					}
					disabled={disabled}
					questionIncludeAdditionalComments={props.questionDetails.question.includeAdditionalComments}
					setQuestionIncludeAdditionalComments={
						props.questionDetails.questionSetters.setQuestionIncludeAdditionalComments
					}
				/>
				<LogicSection
					questionDetails={props.questionDetails}
					questionId={question?.id}
					questionParentsIds={question?.parentsIds}
					disabled={disabled}
				/>

				<ItemContainer>
					<QuestionImageEdit
						id="question-image-url"
						label="Question Image Url"
						imageUrlValue={props.questionDetails.question.imageUrl}
						setImageUrlValue={props.questionDetails.questionSetters.setQuestionImageUrl}
						disabled={disabled}
					/>
				</ItemContainer>
				{props.questionDetails.question.imageUrl && (
					<ItemContainer>
						<TextEdit
							id="question-image-description"
							label="Image Description"
							value={props.questionDetails.question.imageDescription}
							setValue={props.questionDetails.questionSetters.setQuestionImageDescription}
							disabled={disabled}
						/>
					</ItemContainer>
				)}
				{props.questionDetails.question.type === QuizQuestionType.SelectOneButtons && (
					<ItemContainer>
						<QuestionCheckbox
							id="progress-on-selection"
							label="Progress on selection"
							value={props.questionDetails.question.progressOnClick}
							setValue={props.questionDetails.questionSetters.setProgressOnClick}
							disabled={disabled}
						/>
					</ItemContainer>
				)}
			</FormContainer>
			<QuestionActions questionId={question?.id} questionDetails={props.questionDetails} disabled={disabled} />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;

	height: 100%;

	justify-content: space-between;

	background-color: ${QuestionDetailsBackgroundColor};
`;

const FormContainer = styled.div`
	flex-grow: 1;

	overflow: auto;

	padding: 16px 48px 40px;
`;

const ItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-bottom: 16px;

	width: 100%;
`;

// display: flex;
// flex-direction: row;
const TopBarContainer = styled.div`
	justify-content: flex-start;
`;
const TopBarContainerEnd = styled.div`
	justify-content: flex-end;
`;

const BackButtonStyled = styled(Button)`
	font-size: 13px;

	padding: 8px 4px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	border: 0;
`;
