export enum Role {
	admin = 'admin',
	editor = 'editor',
	viewer = 'viewer'
}
export const RoleToName: Record<Role, string> = {
	[Role.admin]: 'Admin',
	[Role.editor]: 'Editor',
	[Role.viewer]: 'Viewer'
};
export const StringToRole: Record<string, Role> = {
	[Role.admin]: Role.admin,
	[Role.editor]: Role.editor,
	[Role.viewer]: Role.viewer
};

export interface TeamMember {
	emailAddress: string;
	firstName: string;
	lastLogin?: Date;
	lastName: string;
	role: Role;
	uuid: string;
}

export interface InviteTeamMemberRequestParams {
	emailAddress: string;
	role: Role;
}

export interface EditTeamMemberRequestParams {
	userId: string;
	role: Role;
}
