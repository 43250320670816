import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';

import { Tooltip } from 'components/general';

const Container = styled(Form.Label)`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 6px;
	font-size: 13px;
`;

const Label = styled.div`
	margin-left: 2px;
`;

const StyledTooltip = styled(Tooltip)`
	margin-left: 5px;
`;

type TProps = {
	text: string;
	tooltip?: string;
};

export const FormLabel: FC<TProps> = ({ text, tooltip }) => {
	return (
		<Container>
			<Label>{text}</Label>
			{tooltip && <StyledTooltip text={tooltip} />}
		</Container>
	);
};
