import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	AnswerGroup,
	AnswerOption,
	DisplayCondition,
	QuizQuestionType,
	QuizQuestionUi,
	useCreateQuestionMutation,
	useUpdateQuestionMutation
} from 'services/quiz';
import { selectSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { useErrorModal } from 'components/ErrorModalProvider';

import { CategoriesDict, QuizQuestionCategory } from './categories';

const QuestionDefaults = {
	type: QuizQuestionType.SelectOneRadio,
	category: QuizQuestionCategory.custom,
	leadsTo: null,
	title: '',
	name: '',
	subtitle: '',
	imageUrl: '',
	imageDescription: '',
	isSkippable: false,
	includeAdditionalComments: false,
	answerOptions: [],
	answerGroups: [],
	progressOnClick: false,
	answerKeyToCategoryMapping: {},
	textInputPlaceholder: '',
	condition: 'None' as DisplayCondition
};

export interface QuizQuestionWithDefaults {
	id?: number;
	name: string;
	title: string;
	subtitle: string;
	imageUrl: string;
	imageDescription: string;
	type: QuizQuestionType;
	category: QuizQuestionCategory;
	leadsTo: number | null;
	answers: AnswerOption[];
	answerGroups: AnswerGroup[];
	isSkippable: boolean;
	includeAdditionalComments: boolean;
	maxNextQuestions: number;
	progressOnClick: boolean;
	answerKeyToCategoryKeyMapping: Record<string, string>;
	textInputPlaceholder: string;
	condition: DisplayCondition;
}

export interface QuestionDetails {
	question: QuizQuestionWithDefaults;
	questionSetters: {
		setQuestionType: (type: QuizQuestionType) => void;
		setQuestionCategory: (category: QuizQuestionCategory) => void;
		setQuestionLeadsTo: (leadsTo: number | null) => void;
		setQuestionTitleHandler: (title: string) => void;
		setQuestionName: (name: string) => void;
		setQuestionSubtitle: (subtitle: string) => void;
		setQuestionImageUrl: (imageUrl: string) => void;
		setQuestionImageDescription: (imageDescription: string) => void;
		setQuestionIsSkippable: (isSkippable: boolean) => void;
		setQuestionIncludeAdditionalComments: (additionalComments: boolean) => void;
		setAnswerOptions: (answerOptions: AnswerOption[]) => void;
		setAnswerGroups: (answerGroups: AnswerGroup[]) => void;
		setProgressOnClick: (progressOnClick: boolean) => void;
		setAnswerKeyToCategoryKeyMapping: (answerKeyToCategoryKeyMapping: Record<string, string>) => void;
		setQuestionTextInputPlaceholder: (textInputPlaceholder: string) => void;
		setQuestionCondition: (condition: DisplayCondition) => void;
	};

	saveQuestion: () => void;
}

export const useQuestionDetails = (question: QuizQuestionUi | undefined): QuestionDetails => {
	const errorModal = useErrorModal();
	const [updateQuestion] = useUpdateQuestionMutation();
	const [createQuestion] = useCreateQuestionMutation();
	const selectedVersionId = useSelector(selectSelectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const [questionType, setQuestionType] = useState<QuizQuestionType>(QuestionDefaults.type);
	const [questionCategory, setQuestionCategory] = useState<QuizQuestionCategory>(QuestionDefaults.category);
	const [questionLeadsTo, setQuestionLeadsTo] = useState<number | null>(QuestionDefaults.leadsTo);
	const [questionTitle, setQuestionTitle] = useState<string>(QuestionDefaults.title);
	const [questionName, setQuestionName] = useState<string>(QuestionDefaults.name);
	const [questionSubtitle, setQuestionSubtitle] = useState<string>(QuestionDefaults.subtitle);
	const [questionImageUrl, setQuestionImageUrl] = useState<string>(QuestionDefaults.imageUrl);
	const [questionImageDescription, setQuestionImageDescription] = useState<string>(QuestionDefaults.imageDescription);
	const [questionIsSkippable, setQuestionIsSkippable] = useState<boolean>(QuestionDefaults.isSkippable);
	const [questionIncludeAdditionalComments, setQuestionIncludeAdditionalComments] = useState<boolean>(
		QuestionDefaults.includeAdditionalComments
	);
	const [answerOptions, setAnswerOptions] = useState<AnswerOption[]>(QuestionDefaults.answerOptions);
	const [answerGroups, setAnswerGroups] = useState<AnswerGroup[]>(QuestionDefaults.answerGroups);
	const [progressOnClick, setProgressOnClick] = useState<boolean>(QuestionDefaults.progressOnClick);
	const [answerKeyToCategoryKeyMapping, setAnswerKeyToCategoryKeyMapping] = useState<Record<string, string>>(
		QuestionDefaults.answerKeyToCategoryMapping
	);
	const [questionTextInputPlaceholder, setQuestionTextInputPlaceholder] = useState<string>(
		QuestionDefaults.textInputPlaceholder
	);
	const [questionCondition, setQuestionCondition] = useState<DisplayCondition>(QuestionDefaults.condition);

	const setQuestionTitleHandler = (newTitle: string) => {
		if (questionTitle === questionName) {
			setQuestionName(newTitle);
		}
		setQuestionTitle(newTitle);
	};

	useEffect(() => {
		setQuestionType(question?.type ?? QuestionDefaults.type);
		setQuestionCategory(question?.category ?? QuestionDefaults.category);
		setQuestionLeadsTo(question?.leadsTo ?? QuestionDefaults.leadsTo);
		setQuestionName(question?.name ?? question?.title ?? QuestionDefaults.name);
		setQuestionTitle(question?.title ?? QuestionDefaults.title);
		setQuestionSubtitle(question?.subtitle ?? QuestionDefaults.subtitle);
		setQuestionImageUrl(question?.imageUrl ?? QuestionDefaults.imageUrl);
		setQuestionImageDescription(question?.imageDescription ?? QuestionDefaults.imageDescription);
		setQuestionIsSkippable(question?.isSkippable ?? QuestionDefaults.isSkippable);
		setQuestionIncludeAdditionalComments(
			question?.includeAdditionalComments ?? QuestionDefaults.includeAdditionalComments
		);
		setAnswerOptions(question?.answers ?? QuestionDefaults.answerOptions);
		setAnswerGroups(question?.answerGroups ?? QuestionDefaults.answerGroups);
		setProgressOnClick(question?.progressOnClick ?? QuestionDefaults.progressOnClick);
		setAnswerKeyToCategoryKeyMapping(
			question?.answerKeyToCategoryKeyMapping ?? QuestionDefaults.answerKeyToCategoryMapping
		);
		setQuestionTextInputPlaceholder(question?.textInputPlaceholder ?? QuestionDefaults.textInputPlaceholder);
		setQuestionCondition(question?.condition ?? QuestionDefaults.condition);
	}, [question]);

	const validateQuestionDetails = () => {
		let res: string[] = [];

		res = res.concat(validateQuestionFields());
		res = res.concat(validateQuestionCategory());

		return res.length === 0 ? null : res;
	};

	const validateQuestionFields = () => {
		const res: string[] = [];
		if (questionTitle === '') {
			res.push("Title can't be an empty string");
		}

		return res;
	};

	const validateQuestionCategory = () => {
		if (questionCategory != null) {
			const categoryInfo = CategoriesDict[questionCategory];

			if (categoryInfo.subCategories != null) {
				return ['Please select sub-category.'];
			}

			if (categoryInfo.optionKeys != null) {
				if (Object.keys(answerKeyToCategoryKeyMapping).length !== answerOptions.length) {
					return ['Missing category keys in some answer options.'];
				}

				const optionKeysSet = new Set(categoryInfo.optionKeys.map((option) => option.key));

				for (let answer of answerOptions) {
					const answerCategoryKey = answerKeyToCategoryKeyMapping[answer.key];

					if (answerCategoryKey == null) {
						return [`Missing category key on ${answer.text}`];
					}

					if (!optionKeysSet.has(answerCategoryKey)) {
						return [`Illegal category key on ${answer.text}`];
					}
				}
			}
		}
		return [];
	};

	const saveQuestion = () => {
		const errorMessages: string[] | null = validateQuestionDetails();
		if (errorMessages != null) {
			errorModal('Validation error', errorMessages.join('\n'));
			return;
		}

		if (selectedVersion == null) {
			// Shouldn't really happen
			errorModal('Error saving question', 'Version not selected');
			return;
		}

		if (question?.id != null) {
			updateQuestion({
				quizVersion: selectedVersion.id,
				questionId: question.id,
				question: {
					type: questionType !== question.type ? questionType : undefined,
					category: questionCategory !== question.category ? questionCategory : undefined,
					leadsTo: questionLeadsTo !== question.leadsTo ? questionLeadsTo : undefined,
					name: questionName !== question.name ? questionName : undefined,
					title: questionTitle !== question.title ? questionTitle : undefined,
					subtitle: questionSubtitle !== question.subtitle ? questionSubtitle : undefined,
					imageUrl: questionImageUrl !== question.imageUrl ? questionImageUrl : undefined,
					imageDescription:
						questionImageDescription !== question.imageDescription ? questionImageDescription : undefined,
					answerGroups: answerGroups !== question.answerGroups ? answerGroups : undefined,
					answers: answerOptions !== question.answers ? answerOptions : undefined,
					isSkippable: questionIsSkippable !== question.isSkippable ? questionIsSkippable : undefined,
					includeAdditionalComments:
						questionIncludeAdditionalComments !== question.includeAdditionalComments
							? questionIncludeAdditionalComments
							: undefined,
					progressOnClick: progressOnClick !== question.progressOnClick ? progressOnClick : undefined,
					answerKeyToCategoryKeyMapping:
						answerKeyToCategoryKeyMapping !== question.answerKeyToCategoryKeyMapping
							? answerKeyToCategoryKeyMapping
							: undefined,
					textInputPlaceholder:
						questionTextInputPlaceholder !== question.textInputPlaceholder
							? questionTextInputPlaceholder
							: undefined,
					condition: questionCondition !== question.condition ? questionCondition : undefined
				}
			})
				.unwrap()
				.then(() => toast.success('Question updated'))
				.catch(() => toast.error('Question update failed'));
		} else {
			createQuestion({
				quizVersion: selectedVersion.id,
				question: {
					type: questionType,
					category: questionCategory,
					title: questionTitle,
					leadsTo: questionLeadsTo,
					name: questionName !== QuestionDefaults.name ? questionName : undefined,
					subtitle: questionSubtitle !== QuestionDefaults.subtitle ? questionSubtitle : undefined,
					imageUrl: questionImageUrl !== QuestionDefaults.imageUrl ? questionImageUrl : undefined,
					imageDescription:
						questionImageDescription !== QuestionDefaults.imageDescription
							? questionImageDescription
							: undefined,
					answerGroups: answerGroups !== QuestionDefaults.answerGroups ? answerGroups : undefined,
					answers: answerOptions !== QuestionDefaults.answerOptions ? answerOptions : undefined,
					isSkippable: questionIsSkippable !== QuestionDefaults.isSkippable ? questionIsSkippable : undefined,
					includeAdditionalComments:
						questionIncludeAdditionalComments !== QuestionDefaults.includeAdditionalComments
							? questionIncludeAdditionalComments
							: undefined,
					progressOnClick: progressOnClick !== QuestionDefaults.progressOnClick ? progressOnClick : undefined,
					answerKeyToCategoryKeyMapping:
						answerKeyToCategoryKeyMapping !== QuestionDefaults.answerKeyToCategoryMapping
							? answerKeyToCategoryKeyMapping
							: undefined,
					textInputPlaceholder:
						questionTextInputPlaceholder !== QuestionDefaults.textInputPlaceholder
							? questionTextInputPlaceholder
							: undefined,
					condition: questionCondition !== QuestionDefaults.condition ? questionCondition : undefined
				}
			})
				.unwrap()
				.then(() => toast.success('Question created'))
				.catch(() => toast.error('Question creation failed'));
		}
	};

	return {
		question: {
			id: question?.id,
			type: questionType,
			category: questionCategory,
			leadsTo: questionLeadsTo,
			title: questionTitle,
			name: questionName,
			subtitle: questionSubtitle,
			imageUrl: questionImageUrl,
			imageDescription: questionImageDescription,
			isSkippable: questionIsSkippable,
			includeAdditionalComments: questionIncludeAdditionalComments,
			answers: answerOptions,
			answerGroups,
			progressOnClick,
			answerKeyToCategoryKeyMapping,
			textInputPlaceholder: questionTextInputPlaceholder,
			maxNextQuestions: question?.maxNextQuestions ?? 0,
			condition: questionCondition
		},
		questionSetters: {
			setQuestionType,
			setQuestionCategory,
			setQuestionLeadsTo,
			setQuestionTitleHandler,
			setQuestionName,
			setQuestionSubtitle,
			setQuestionImageUrl,
			setQuestionImageDescription,
			setQuestionIsSkippable,
			setQuestionIncludeAdditionalComments,
			setAnswerOptions,
			setAnswerGroups,
			setProgressOnClick,
			setQuestionTextInputPlaceholder,
			setAnswerKeyToCategoryKeyMapping,
			setQuestionCondition: setQuestionCondition
		},

		saveQuestion
	};
};
