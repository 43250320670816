import { partnerKeyPlaceholder, rootSplitApi } from 'redux/base-api';

import { FetchLooksResponse } from './types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Looks'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getLooks: build.query<FetchLooksResponse, void>({
			query: () => ({
				url: `/partner/${partnerKeyPlaceholder}/looks?type=all`
			}),
			providesTags: () => ['Looks']
		})
	})
});

export const { useGetLooksQuery } = extendedApi;
