import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLogout } from 'hooks';
import { useGetUserQuery } from 'services/user';

const UserPanel = () => {
	const logout = useLogout();

	const { data: user } = useGetUserQuery();
	const userName = user?.name;

	return (
		<Dropdown as={ButtonGroup} style={{ margin: '0 8px' }}>
			<Dropdown.Toggle variant="plain" id="version-menu">
				{userName}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default UserPanel;
