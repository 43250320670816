import { partnerKeyPlaceholder, rootSplitApi } from 'redux/base-api';
import { Role } from 'services/teams';

import { ForgotPwdPayload, LoginPayload, LoginResponse, Partner, User } from './user.types';

interface OrigUser {
	uuid: string;
	role: Role;
	name: string;
}

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['User', 'Partner', 'Token'] });

export const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<LoginResponse, LoginPayload>({
			query: (payload) => ({
				url: '/partnersUser/auth',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: ['Token']
		}),
		forgotPassword: build.mutation<string, ForgotPwdPayload>({
			query: (payload) => ({
				url: '/partnersUser/forgotPassword',
				method: 'PUT',
				body: payload
			})
		}),
		getUser: build.query<User, void>({
			query: () => ({
				url: '/partnersUser/user',
				method: 'GET'
			}),
			transformResponse: (response: OrigUser) => {
				return { ...response, id: response.uuid };
			},
			providesTags: ['User']
		}),
		getPartner: build.query<Partner, void>({
			query: () => ({
				url: `/partner/${partnerKeyPlaceholder}`,
				method: 'GET'
			}),
			transformResponse: (response: { partner: Partner }) => response.partner,
			providesTags: ['Partner']
		})
	})
});

export const { useGetUserQuery, useGetPartnerQuery, useLoginMutation, useForgotPasswordMutation } = extendedApi;
