import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RootState } from 'redux/store';
import { useGetPartnerQuery } from 'services/user';
import { QuizVersion, useGetQuizVersionsQuery } from 'services/versions';

import { NewVersionRow } from './NewVersionRow';
import { VersionViewType } from './QuizVersionsOffcanvas';
import { VersionRow } from './VersionRow';

export const VersionsList = (props: {
	showNew: boolean;
	handleCloseModal: () => void;
	handleCloseNewVersion: () => void;
	type: VersionViewType;
}) => {
	const { data: partner } = useGetPartnerQuery();
	const { data: versions } = useGetQuizVersionsQuery();
	const activeVersionId = partner?.activeVersionId;
	const selectedVersionId = useSelector((state: RootState) => state.versions.selectedVersionId);

	const versionViewType = props.type;

	const versionsList = useMemo(() => {
		// Arrange versions list to always prioritize selected first and active second.
		const sortFn = (a: QuizVersion, b: QuizVersion) => {
			const aa = a.id === selectedVersionId ? -2 : a.id === activeVersionId ? -1 : a.id;
			const bb = b.id === selectedVersionId ? -2 : b.id === activeVersionId ? -1 : b.id;
			return aa - bb;
		};
		return versions?.filter((v) => shouldShowVersion(versionViewType, v)).sort(sortFn);
	}, [versions, selectedVersionId, activeVersionId, versionViewType]);

	return (
		<StyledListGroup variant="flush">
			{props.showNew && (
				<VersionListItem key="new-version-row">
					<NewVersionRow
						onCancel={props.handleCloseNewVersion}
						onSave={() => {
							props.handleCloseModal();
							props.handleCloseNewVersion();
						}}
					/>
				</VersionListItem>
			)}
			{versionsList?.map((version) => (
				<VersionListItem key={version.id}>
					<VersionRow
						version={version}
						closeModal={props.handleCloseModal}
						isActive={version.id === activeVersionId}
						isSelected={version.id === selectedVersionId}
					/>
				</VersionListItem>
			))}
		</StyledListGroup>
	);
};

const VersionListItem = (props: { children: React.ReactNode }) => {
	return <ListGroup.Item style={{ border: 0, padding: 0 }}>{props.children}</ListGroup.Item>;
};

const shouldShowVersion = (versionViewType: VersionViewType, version: QuizVersion) => {
	return (
		(versionViewType === VersionViewType.Drafts && !version.isLocked) ||
		(versionViewType === VersionViewType.Activated && version.isLocked)
	);
};

const StyledListGroup = styled(ListGroup)`
	flex: 1 1;
	overflow: auto;
`;

export const VersionBorderColor = '#cfccca';
export const VersionContainer = styled.div`
	flex: 0 0;

	padding: 16px;
	margin: 4px 0;

	font-size: 12px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	border: 1px solid ${VersionBorderColor};
	border-radius: 17px;
`;

export const VersionActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const VersionActionButton = (props: {
	onClick: () => void;
	children: React.ReactNode;
	disabled?: boolean;
	variant?: 'link' | 'pill';
}) => {
	if (props.variant === 'pill') {
		return (
			<VersionActionButtonPillStyled variant="primary" onClick={props.onClick} disabled={props.disabled}>
				{props.children}
			</VersionActionButtonPillStyled>
		);
	} else {
		return (
			<VersionActionButtonLinkStyled variant="link" onClick={props.onClick} disabled={props.disabled}>
				{props.children}
			</VersionActionButtonLinkStyled>
		);
	}
};

const VersionActionButtonLinkStyled = styled(Button)`
	font-size: 14px;
	color: #000;
	&:hover {
		color: #000;
	}
	&:focus {
		color: #000;
		box-shadow: unset;
	}
`;

const VersionActionButtonPillStyled = styled(Button)`
	font-size: 14px;
	line-height: 1em;
	border: unset;
	white-space: nowrap;

	background-color: #1f767a;
	padding: 8px 24px;

	border-radius: 14px;

	color: #fff;
	&:hover {
		background-color: #232323;
	}
	&:focus {
	}
`;
