import styled from '@emotion/styled';
import { PageBackgroundColor } from 'constants/colors';

export const PageContainer = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;

	padding: 40px 56px;

	background-color: ${PageBackgroundColor};

	display: flex;
	flex-direction: column;
`;

export const PageTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-bottom: 32px;
`;

export const PageTitleSpan = styled.span`
	font-size: 32px;
`;

export const PageSubtitleSpan = styled.span`
	font-size: 22px;
`;
