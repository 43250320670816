import React from 'react';
import styled from '@emotion/styled';

import { TScrollToPlansFn } from '../types';

const TopBarContainer = styled.div`
	height: calc(var(--fpTopBarHeight) + var(--fpTopBarBorderHeight));
`;

const FixedBar = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-bottom: var(--fpTopBarBorderHeight) solid rgba(0, 0, 0, 0.1);
	height: var(--fpTopBarHeight);
	background-color: #ffffff;
	z-index: 1;

	@media (max-width: 1024px) {
		position: absolute;
		background-color: transparent;
	}
`;

const Logo = styled.img`
	width: 230px;
`;

const Nav = styled.nav`
	position: absolute;
	right: 0;
	top: 0;
	height: var(--fpTopBarHeight);
	display: flex;
	align-items: center;
	padding-right: 18px;

	@media (max-width: 1024px) {
		display: none;
	}
`;
const LogInLink = styled.a`
	color: var(--fpTextColor);
	text-decoration: none;
	font-size: 16px;

	&:hover {
		text-decoration: underline;
		color: var(--fpActiveColor);
	}
`;
const RequestButton = styled.div`
	font-size: 16px;
	line-height: 16px;
	padding: 7px 20px;
	border: 1px solid var(--fpAccentColor);
	color: var(--fpAccentColor);
	border-radius: 100px;
	margin-left: 35px;
	background-color: transparent;

	&:hover {
		background-color: var(--fpAccentColor);
		color: #ffffff;
	}
`;

export const TopBar: React.FC<{ scrollToPlans: TScrollToPlansFn }> = ({ scrollToPlans }) => {
	return (
		<TopBarContainer>
			<FixedBar>
				<Logo src="/images/frontPage/header_logo.png" alt="Wishi for brands" />
				<Nav>
					<LogInLink href="/login">Log In</LogInLink>
					<RequestButton onClick={scrollToPlans}>View Pricing</RequestButton>
				</Nav>
			</FixedBar>
		</TopBarContainer>
	);
};
