import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storageParams } from 'constants/general';
import { resetStore } from 'redux/reducers/root';
import { signOut } from 'services/user';

export const useLogout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logoutCallback = useCallback(() => {
		if (localStorage.getItem(storageParams.accessToken)) {
			localStorage.removeItem(storageParams.accessToken);
			dispatch(resetStore());
			dispatch(signOut());
			navigate('/');
		}
	}, [dispatch, navigate]);

	return logoutCallback;
};
