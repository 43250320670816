import { FC, useRef } from 'react';
import styled from '@emotion/styled';

import { palette } from '../../constants/styles';

const Container = styled.div`
	position: relative;

	&:focus ul {
		display: block;
	}

	ul {
		display: none;
	}
`;
const Selection = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	white-space: nowrap;
	cursor: pointer;

	&:after {
		content: '';
		border: 6px solid transparent;
		border-top-color: black;
		margin-left: 10px;
		transform: translateY(3px);
	}
`;

const Dropdown = styled.ul`
	position: absolute;
	list-style: none;
	min-width: 254px;
	padding: 5px 0;
	background: ${palette.elementBackground};
	border: 1px solid ${palette.elementBorderColor};
	z-index: 1;
`;

const DropdownItem = styled.li`
	padding: 4px 10px;
	cursor: pointer;

	&:hover {
		background: ${palette.primaryColor};
		color: ${palette.elementBackground};
	}
`;

export type TOption = {
	value: any;
	label: string;
};

type TProps = {
	value: any;
	options: TOption[];
	onChange: (selectedOption: any) => void;
};

export const Select: FC<TProps> = ({ value, options, onChange }) => {
	const containerElementRef = useRef<HTMLDivElement>(null);
	const selectedOption = options.find((option) => option.value === value);

	const pick = (option: TOption) => {
		onChange(option.value);
		(containerElementRef.current as HTMLElement).blur();
	};

	return (
		<Container tabIndex={0} ref={containerElementRef}>
			<Selection>{selectedOption?.label || ''}</Selection>
			<Dropdown>
				{options.map((option, index) => (
					<DropdownItem key={index} onClick={() => pick(option)}>
						{option.label}
					</DropdownItem>
				))}
			</Dropdown>
		</Container>
	);
};
