import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FamilyColors, GraphBackgroundColor, NeutralColor } from 'constants/colors';
import { Canvas, CanvasPosition, CanvasRef, Edge, EdgeData, EdgeProps, Label, NodeData, NodeProps } from 'reaflow';
import {
	DisplayCondition,
	IdToQuestionDict,
	QuizQuestionUi,
	selectQuestions,
	useGetQuestionsQuery
} from 'services/quiz';
import { getConditionLabel } from 'services/quiz/quiz.utils';
import { useGetPartnerQuery } from 'services/user';
import { selectSelectedVersionId, setSelectedVersionId, useGetQuizVersionsQuery } from 'services/versions';

import { QuestionsGraphTopMenu } from './menu/QuestionsGraphTopMenu';
import { BoxShadowMargins, QuestionsGraphNode } from './QuestionsGraphNode';

interface IQuestionsGraph {}

export const firstQuestionNodeId = 'first-question-node';
export const lastQuestionNodeId = 'last-question-node';

export interface CustomNodeData {
	questionId: number;
	text: string;
	maxRemainingQuestions?: number;
	isSkippable: boolean;
	isConditional: boolean;
	condition: {
		type: DisplayCondition;
		label: string;
	};
	color?: string;
}

const QuestionsGraph: React.FC<IQuestionsGraph> = () => {
	const dispatch = useDispatch();

	const { data: partner } = useGetPartnerQuery();
	const selectedVersionId = useSelector(selectSelectedVersionId);
	if (!selectedVersionId && partner) {
		dispatch(setSelectedVersionId(partner.activeVersionId));
	}

	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	// need to make sure data is fetched since it is copied to state. need to refactor
	// eslint-disable-next-line
	const { data } = useGetQuestionsQuery({ quizVersion: selectedVersionId ?? 0 }, { skip: !selectedVersionId });
	const { questionIds, idToQuestion, selectedQuestionId } = useSelector(selectQuestions);

	const ref = useRef<CanvasRef | null>(null);
	const [zoom, setZoom] = useState<number>(0.8);

	const [graphState, setGraphState] = useState<{
		nodes: NodeData[];
		edges: EdgeData[];
	}>({ nodes: [], edges: [] });

	const firstQuestionId = selectedVersion?.firstQuestionId;

	useEffect(() => {
		const nodes: NodeData[] = [];
		const edges: EdgeData[] = [];

		if (questionIds == null || idToQuestion == null) {
			setGraphState({ nodes, edges });
			return;
		}

		if (firstQuestionId != null) {
			nodes.push(FirstQuestionNode);
			edges.push(createEdgeData(firstQuestionNodeId, firstQuestionId));
		}

		nodes.push(LastQuestionNode);

		questionIds.forEach((questionId) => {
			const question = idToQuestion[questionId];
			if (question == null) {
				console.error(`Graph data is corrupted; no question for id ${questionId}`);
				return;
			}
			nodes.push(questionToNode(question, idToQuestion));

			const answerEdgesText: { [id: number]: string } = {};
			let leadingAnswersCount = 0;
			question.answers?.forEach((answer) => {
				if (answer.leadsTo != null && answer.leadsTo !== question.leadsTo && answer.text != null) {
					if (idToQuestion[answer.leadsTo] == null) {
						console.error(`Graph data is corrupted; 'lead to' config for question ${questionId}`);
						return;
					} else {
						leadingAnswersCount += 1;
						if (answerEdgesText[answer.leadsTo] != null) {
							answerEdgesText[answer.leadsTo] = answerEdgesText[answer.leadsTo] + ', ' + answer.text;
						} else {
							answerEdgesText[answer.leadsTo] = answer.text;
						}
					}
				}
			});

			const allAnswersLead =
				question.answers && question.answers.length > 0 && question.answers.length === leadingAnswersCount;

			if (!allAnswersLead || question.isSkippable) {
				if (question.leadsTo != null) {
					if (idToQuestion[question.leadsTo] == null) {
						// TODO: log error
						return;
					} else {
						edges.push(createEdgeData(question.id, question.leadsTo));
					}
				} else {
					edges.push(createEdgeData(question.id, lastQuestionNodeId));
				}
			}

			for (let targetNodeId in answerEdgesText) {
				edges.push(createEdgeData(question.id, targetNodeId, answerEdgesText[targetNodeId]));
			}
		});

		setGraphState({ nodes, edges });
	}, [questionIds, idToQuestion, firstQuestionId]);

	if (selectedVersion == null) {
		return null;
	}

	return (
		<>
			<QuestionsGraphTopMenu zoom={zoom} canvasRef={ref} />
			<style>
				{`
        .port {
          fill: #3870A3;
          rx: 8px;
          stroke: white;
          stroke-width: 2px;
          translate: 0 -8px
        }
        .canvas {
          background-color: ${GraphBackgroundColor};
          overscroll-behavior: contain;
          padding: 60px;
        }
      `}
			</style>
			<Canvas
				className="canvas"
				nodes={graphState.nodes}
				edges={graphState.edges}
				arrow={null}
				pannable={true}
				zoom={zoom}
				ref={ref}
				onZoomChange={(z) => {
					setZoom(z);
				}}
				selections={selectedQuestionId ? [String(selectedQuestionId)] : []}
				node={(node: NodeProps) => <QuestionsGraphNode key={node.id} nodeProps={node} />}
				edge={(edge: EdgeProps) => (
					<Edge {...edge} style={{ stroke: '#BBA889' }} label={<Label style={{ fill: '#747474' }} />} />
				)}
				maxHeight={8000}
				defaultPosition={CanvasPosition.TOP}
			/>
		</>
	);
};

export default QuestionsGraph;

const questionToNode = (question: QuizQuestionUi, idToQuestion: IdToQuestionDict): NodeData<CustomNodeData> => ({
	id: String(question.id),
	data: {
		questionId: question.id,
		text: question.name ?? question.title,
		maxRemainingQuestions: question.maxNextQuestions,
		isSkippable: question.isSkippable || false,
		isConditional: question.condition !== 'None',
		condition: {
			type: question.condition ?? 'None',
			label: getConditionLabel(question, idToQuestion)
		},
		color:
			typeof question.familyId === 'number' ? FamilyColors[question.familyId % FamilyColors.length] : NeutralColor
	},
	width: 220 + BoxShadowMargins * 2,
	height: 104 + (question.condition !== 'None' ? 37 : 0) + BoxShadowMargins * 2,
	ports: [
		{
			id: `${question.id}-from`,
			side: 'SOUTH',
			width: 16,
			height: 16,
			disabled: true,
			className: 'port'
		},
		{
			id: `${question.id}-to`,
			side: 'NORTH',
			width: 0,
			height: 0,
			disabled: true,
			hidden: true
		}
	]
});

const FirstQuestionNode: NodeData = {
	id: firstQuestionNodeId,
	width: 160 + BoxShadowMargins * 2,
	height: 32 + BoxShadowMargins * 2,
	ports: [
		{
			id: `${firstQuestionNodeId}-from`,
			side: 'SOUTH',
			width: 0,
			height: 0,
			disabled: true,
			hidden: true
		}
	]
};

const LastQuestionNode: NodeData = {
	id: lastQuestionNodeId,
	width: 160 + BoxShadowMargins * 2,
	height: 32 + BoxShadowMargins * 2,
	ports: [
		{
			id: `${lastQuestionNodeId}-to`,
			side: 'NORTH',
			width: 0,
			height: 0,
			disabled: true,
			hidden: true
		}
	]
};

const createEdgeData = (from: number | string, to: number | string, text?: string) => ({
	id: `${from}-${to}`,
	from: String(from),
	to: String(to),
	fromPort: `${from}-from`,
	toPort: `${to}-to`,
	text
});
