import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextEdit } from 'routes/quiz/components/question/form/TextEdit';

import { LoginFormButton, LoginFormContainer, LoginFormTextFieldStyle, LoginFormTitle } from './EmailLoginForm';
import { LoginMessage, LoginMessageVariant } from './LoginMessage';

export const SignupForm = (props: { uuid: string; token: string; partnerKey: string; resetPassword: boolean }) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState<string>('');

	const navigate = useNavigate();

	return (
		<LoginFormContainer
			onSubmit={async (event) => {
				event.preventDefault();
				if (!validatePassword(password)) {
					setErrorMessage(
						'Password must be at least 8 characters, and contain at least one number, upper-case letter, lower-case letter and special character.'
					);
					return;
				}
				try {
					await sendSignupRequest({
						partnerKey: props.partnerKey,
						uuid: props.uuid,
						token: props.token,
						firstName: props.resetPassword ? undefined : firstName,
						lastName: props.resetPassword ? undefined : lastName,
						password
					});
				} catch (error) {
					setErrorMessage('Error signing up.');
					return;
				}
				return navigate('/', { replace: true });
			}}
		>
			<LoginFormTitle>{props.resetPassword ? 'Reset password' : 'Sign up'}</LoginFormTitle>
			{!props.resetPassword && (
				<TextEdit
					id="signup-first-name"
					label="First Name"
					value={firstName}
					setValue={setFirstName}
					style={{ marginBottom: 24 }}
					fieldStyle={LoginFormTextFieldStyle}
				/>
			)}
			{!props.resetPassword && (
				<TextEdit
					id="signup-last-name"
					label="Last Name"
					value={lastName}
					setValue={setLastName}
					style={{ marginBottom: 24 }}
					fieldStyle={LoginFormTextFieldStyle}
				/>
			)}
			<TextEdit
				id="signup-password"
				label="Password"
				value={password}
				setValue={setPassword}
				type="password"
				style={{ marginBottom: 12 }}
				fieldStyle={LoginFormTextFieldStyle}
				passwordAutoCompleteOff={true}
			/>
			{errorMessage && <LoginMessage variant={LoginMessageVariant.Error} message={errorMessage} />}
			<LoginFormButton disabled={!password || (!props.resetPassword && (!firstName || !lastName))} type="submit">
				{props.resetPassword ? 'Reset password' : 'Sign up'}
			</LoginFormButton>
		</LoginFormContainer>
	);
};

const sendSignupRequest = async (params: {
	partnerKey: string;
	uuid: string;
	token: string;
	firstName: string | undefined;
	lastName: string | undefined;
	password: string;
}) => {
	return axios.request({
		url: `${process.env.REACT_APP_API_GW_URL}/partnersUser/users/${params.uuid}`,
		method: 'PUT',
		data: {
			token: params.token,
			firstName: params.firstName,
			lastName: params.lastName,
			password: params.password
		}
	});
};

const validatePassword = (password: string): boolean => {
	const res = password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
	return !!res;
};
