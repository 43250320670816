import { css } from '@emotion/react';

const globalCss = css`
	@font-face {
		font-family: 'ABCDiatype';
		src: local('ABCDiatype'), url(./fonts/ABCDiatype-Regular.otf);
		font-weight: 400;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	body {
		line-height: 1;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	body,
	input,
	button {
		font-family: 'ABCDiatype', sans-serif;
	}

	svg {
		display: block;
	}

	select:not(option) {
		background-color: white;
	}
`;

export default globalCss;
