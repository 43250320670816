import { useEffect } from 'react';
import { QuestionTypeDict, QuizQuestionType, StringToQuizQuestionType } from 'services/quiz';

import { Dropdown } from './form/Dropdown';
import { CategoriesDict, QuestionCategoryInfo, QuizQuestionCategory } from './categories';

const isTypeAllowed = (type: QuizQuestionType, categoryInfo: QuestionCategoryInfo) =>
	!categoryInfo.allowedTypes || categoryInfo.allowedTypes.has(type);

const getFirstAllowedType = (categoryInfo: QuestionCategoryInfo): QuizQuestionType =>
	(categoryInfo.allowedTypes && categoryInfo.allowedTypes.values().next().value) ?? QuizQuestionType.SelectOneRadio;

export const QuestionTypeSelector = (props: {
	questionType: QuizQuestionType;
	setQuestionType: (type: QuizQuestionType) => void;
	disabled?: boolean;
	questionCategory: QuizQuestionCategory;
}) => {
	const categoryInfo = CategoriesDict[props.questionCategory];

	const questionType = props.questionType;
	const setQuestionType = props.setQuestionType;
	useEffect(() => {
		if (!isTypeAllowed(questionType, categoryInfo)) {
			setQuestionType(getFirstAllowedType(categoryInfo));
		}
	}, [questionType, categoryInfo, setQuestionType]);

	return (
		<>
			<Dropdown
				id="question-type"
				label="Question type"
				selectedValue={props.questionType}
				setValue={(value: string) => {
					value != null && props.setQuestionType(StringToQuizQuestionType[value]);
				}}
				options={AllTypes.map((type) => ({
					value: type,
					name: QuestionTypeDict[type].name,
					disabled: !isTypeAllowed(type, categoryInfo)
				}))}
				disabled={props.disabled}
				undefinedOptionText={null}
			/>
		</>
	);
};

const AllTypes = [
	QuizQuestionType.SelectOneRadio,
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneTags,
	QuizQuestionType.SelectManyCheckbox,
	QuizQuestionType.SelectManyButtons,
	QuizQuestionType.SelectManyTags,
	QuizQuestionType.FreeText,
	QuizQuestionType.FreeNumber,
	QuizQuestionType.DropDowns,
	QuizQuestionType.UploadPhoto,
	QuizQuestionType.PhoneNumber
];
