import { Dispatch, FC, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { TDatePeriod } from 'utils';

import { ComparisonDatePicker, Modal } from 'components';

import { QuestionBounceGraph } from './QuestionBounceGraph';

const Container = styled.div`
	background: ${palette.elementBackground};
	border: 1px solid ${palette.elementBorderColor};
	border-radius: 10px;
	padding: 62px 62px 30px 62px;
	width: 1300px;
`;
const Header = styled.div`
	position: relative;
	display: flex;
	margin-bottom: 20px;
`;

const Title = styled.div`
	font-size: 32px;
	margin-right: 20px;
	white-space: nowrap;
`;

const X = styled.div`
	position: absolute;
	right: -25px;
	top: -25px;
	cursor: pointer;
`;

type TProps = {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
};

export const QuizBounceModal: FC<TProps> = (props) => {
	const [period, setPeriod] = useState<TDatePeriod>(TDatePeriod.last4Weeks);

	const onDatesChange = (period: TDatePeriod) => {
		setPeriod(period);
	};

	return (
		<Modal {...props}>
			<Container>
				<Header>
					<Title>Quiz bounce from each step</Title>
					<ComparisonDatePicker period={period} onChange={onDatesChange} hasCompare={false} />
					<X className="btn-close" onClick={() => props.setShow(false)} />
				</Header>
				<QuestionBounceGraph period={period} />
			</Container>
		</Modal>
	);
};
