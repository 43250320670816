import { rootSplitApi } from 'redux/base-api';

import { EditTeamMemberRequestParams, InviteTeamMemberRequestParams, Role, TeamMember } from './teams.types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Team'] });
const pageSize = 20;

interface TeamMemberResponse {
	emailAddress: string;
	firstName: string;
	lastLogin: string;
	lastName: string;
	role: Role;
	uuid: string;
}

const transformTeamMember = (member: TeamMemberResponse): TeamMember => {
	return {
		...member,
		lastLogin: member.lastLogin ? new Date(member.lastLogin) : undefined
	};
};

export const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getTeamMembers: build.query<TeamMember[], number | void>({
			query: (page = 1) => ({
				url: 'partnersUser/users',
				params: { limit: pageSize, page }
			}),
			transformResponse: (response: { items: TeamMemberResponse[] }) => response.items.map(transformTeamMember),
			providesTags: ['Team']
		}),
		inviteTeamMember: build.mutation<void, InviteTeamMemberRequestParams>({
			query: (payload) => ({
				url: '/partnersUser/users',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: ['Team']
		}),
		deleteTeamMember: build.mutation<void, string>({
			query: (userId) => ({
				url: `/partnersUser/users/${userId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Team']
		}),
		editTeamMemberRole: build.mutation<void, EditTeamMemberRequestParams>({
			query: ({ userId, role }) => ({
				url: `/partnersUser/users/${userId}`,
				method: 'PATCH',
				body: { role }
			}),
			invalidatesTags: ['Team']
		})
	})
});

export const {
	useGetTeamMembersQuery,
	useDeleteTeamMemberMutation,
	useInviteTeamMemberMutation,
	useEditTeamMemberRoleMutation
} = extendedApi;
