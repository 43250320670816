import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import heroImage from 'assets/images/login-cover-image.png';
import { ReactComponent as WishiForBrands } from 'assets/images/wishi-for-brands.svg';
import { useLogout } from 'hooks';

import { loginParams } from './consts';
import { EmailLoginForm } from './EmailLoginForm';
import { SignupForm } from './SignupForm';

export const Login = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [signupParams, setSignupParams] = useState<
		| {
				token: string;
				uuid: string;
				partnerKey: string;
				resetPassword: boolean;
		  }
		| undefined
	>(undefined);

	useEffect(() => {
		const tokenParam = searchParams.get(loginParams.token);
		const uuidParam = searchParams.get(loginParams.uuid);
		const partnerKeyParam = searchParams.get(loginParams.partnerKey);
		const resetPasswordParam = searchParams.get(loginParams.resetPassword);
		if (tokenParam && uuidParam && partnerKeyParam) {
			setSignupParams({
				token: tokenParam,
				uuid: uuidParam,
				partnerKey: partnerKeyParam,
				resetPassword: !!resetPasswordParam && resetPasswordParam !== 'false' && resetPasswordParam !== '0'
			});
			searchParams.delete(loginParams.token);
			searchParams.delete(loginParams.uuid);
			searchParams.delete(loginParams.partnerKey);
			searchParams.delete(loginParams.resetPassword);
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	const logout = useLogout();
	const logoutOnceRef = useRef<boolean>(false);
	useEffect(() => {
		if (!logoutOnceRef.current) {
			logoutOnceRef.current = true;
			logout();
		}
		logout();
	}, [logoutOnceRef, logout]);

	return (
		<Container>
			<TopBarContainer>
				<WishiForBrands height="24px" width="100%" />
			</TopBarContainer>
			<FormsContainer>
				<ItemContainer>{signupParams ? <SignupForm {...signupParams} /> : <EmailLoginForm />}</ItemContainer>

				<ItemContainer>
					<CoverImage src={heroImage} />
				</ItemContainer>
			</FormsContainer>
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
`;

const TopBarContainer = styled.div`
	height: 100%;
	width: 100%;

	flex: 0 0;
	padding: 24px;

	border-bottom: 1px solid #f4f4f4;
`;

const FormsContainer = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;

	overflow: hidden;
`;

const ItemContainer = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	max-width: 600px;

	padding: 24px 0px;
	margin: 0px 24px;

	flex: 1 1 0px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: #fbf9f8;
`;

const CoverImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
	margin: 0 auto;
	vertical-align: middle;
`;
