import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { extendedApi as quizApi } from './quiz.service';
import { IdToQuestionDict, QuestionTypesSupportingAnswerDirection, QuizQuestion } from './quiz.types';

interface QuizState {
	questionIds?: number[];
	idToQuestion?: IdToQuestionDict;
	selectedQuestionId?: number;
	isNewQuestion: boolean;
}

const initialState: QuizState = {
	selectedQuestionId: undefined,
	questionIds: undefined,
	idToQuestion: undefined,
	isNewQuestion: false
};

const quizSlice = createSlice({
	name: 'quiz',
	initialState,
	reducers: {
		setSelectedQuiz(state, action: PayloadAction<QuizQuestion[]>) {
			state.selectedQuestionId = undefined;
			state.isNewQuestion = false;
			const questionsState = buildQuestionsState(action.payload);
			state.questionIds = questionsState.ids;
			state.idToQuestion = questionsState.idToQuestion;
		},
		resetQuiz(state) {
			state = initialState;
		},
		unselectQuestion(state) {
			state.isNewQuestion = false;
			state.selectedQuestionId = undefined;
		},
		selectNewQuestion(state) {
			state.isNewQuestion = true;
			state.selectedQuestionId = undefined;
		},
		selectQuestion(state, action: PayloadAction<number>) {
			state.selectedQuestionId = action.payload;
			state.isNewQuestion = false;
		},
		setQuestion(state, action: PayloadAction<QuizQuestion>) {
			if (state.idToQuestion == null || state.questionIds == null) {
				// TODO Illegal state, Log error
				return;
			}
			const ret = createNewQuestionState(state.questionIds, state.idToQuestion, action.payload);
			state.questionIds = ret.newIds;
			state.idToQuestion = ret.newIdsToQuestions;
		},
		setAndSelectQuestion(state, action: PayloadAction<QuizQuestion>) {
			if (state.idToQuestion == null || state.questionIds == null) {
				// TODO Illegal state, Log error
				return;
			}
			const ret = createNewQuestionState(state.questionIds, state.idToQuestion, action.payload);
			state.questionIds = ret.newIds;
			state.idToQuestion = ret.newIdsToQuestions;
			state.selectedQuestionId = action.payload.id;
			state.isNewQuestion = false;
		}
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(quizApi.endpoints.getQuestions.matchFulfilled, (state, { payload }) => {
				const questionsState = buildQuestionsState(payload);
				state.questionIds = questionsState.ids;
				state.idToQuestion = questionsState.idToQuestion;
			})
			.addMatcher(quizApi.endpoints.deleteQuestion.matchFulfilled, (state) => {
				state.isNewQuestion = false;
				state.selectedQuestionId = undefined;
			});
	}
});

export const selectIsNewQuestion = (state: RootState) => state.quiz.isNewQuestion;
export const selectSelectedQuestionId = (state: RootState) => state.quiz.selectedQuestionId;
export const selectQuestions = (state: RootState) => state.quiz;
export const selectSelectedQuestion = (state: RootState) =>
	state.quiz.selectedQuestionId && state.quiz.idToQuestion
		? state.quiz.idToQuestion[state.quiz.selectedQuestionId]
		: undefined;

export const { setSelectedQuiz, resetQuiz, selectQuestion, unselectQuestion, selectNewQuestion } = quizSlice.actions;
export default quizSlice.reducer;

const isNewQuestion = (questions: IdToQuestionDict, id: number) => {
	return questions[id] == null;
};

const createNewQuestionState = (
	currentQuestionIds: number[],
	currentIdToQuestion: IdToQuestionDict,
	question: QuizQuestion
) => {
	const newIdsToQuestions = Object.assign({}, currentIdToQuestion);
	let newIds;

	if (isNewQuestion(currentIdToQuestion, question.id)) {
		newIdsToQuestions[question.id] = question;
		setFollowups(question, newIdsToQuestions);
		addQuestionChildrensParent(question, newIdsToQuestions, true);

		newIds = [...currentQuestionIds];
		newIds.push(question.id);
	} else {
		updateQuestion(question, newIdsToQuestions);

		newIds = currentQuestionIds;
	}

	return { newIds, newIdsToQuestions };
};

const buildQuestionsState = (questions: QuizQuestion[]) => {
	const ids: number[] = [];
	const idToQuestion: IdToQuestionDict = {};

	questions.forEach((question) => {
		ids.push(question.id);
		idToQuestion[question.id] = { ...question };
	});

	questions.forEach((question) => {
		setFollowups(question, idToQuestion);
		addQuestionChildrensParent(question, idToQuestion);
	});

	return { ids, idToQuestion };
};

const getQuestionDestinations = (question: QuizQuestion) => {
	const res: number[] = [];

	if (question.leadsTo != null) {
		res.push(question.leadsTo);
	}

	if (QuestionTypesSupportingAnswerDirection.has(question.type)) {
		question.answers?.forEach((answer) => {
			if (answer.leadsTo != null) {
				res.push(answer.leadsTo);
			}
		});
	}

	return res;
};

const addQuestionChildrensParent = (
	question: QuizQuestion,
	idToQuestion: IdToQuestionDict,
	shouldCopyObjects?: boolean
) => {
	const questionDestinations = getQuestionDestinations(question);

	questionDestinations.forEach((dest) => {
		if (idToQuestion[dest] == null) {
			// TODO: log error
			return;
		}
		if (shouldCopyObjects) {
			const newParents = new Set(idToQuestion[dest].parentsIds);
			newParents.add(question.id);
			idToQuestion[dest] = {
				...idToQuestion[dest],
				parentsIds: newParents
			};
		} else {
			if (idToQuestion[dest].parentsIds != null) {
				idToQuestion[dest].parentsIds?.add(question.id);
			} else {
				idToQuestion[dest].parentsIds = new Set<number>([question.id]);
			}
		}
	});
};

// Always copies objects.
const removeQuestionChildrensParent = (question: QuizQuestion, idToQuestion: IdToQuestionDict) => {
	const questionDestinations = getQuestionDestinations(question);

	questionDestinations.forEach((dest) => {
		const newParents = new Set(idToQuestion[dest].parentsIds);
		newParents.delete(question.id);

		idToQuestion[dest] = {
			...idToQuestion[dest],
			parentsIds: newParents
		};
	});
};

const updateQuestion = (question: QuizQuestion, idsToQuestions: IdToQuestionDict) => {
	removeQuestionChildrensParent(idsToQuestions[question.id], idsToQuestions);

	const questionParentIds = idsToQuestions[question.id].parentsIds;
	idsToQuestions[question.id] = question;
	idsToQuestions[question.id].parentsIds = questionParentIds;

	addQuestionChildrensParent(question, idsToQuestions, true);
};

const setFollowups = (question: QuizQuestion, idsToQuestion: IdToQuestionDict) => {
	const followUps =
		question.answers?.filter((ans) => !!ans.followUpQuestionId).map((ans) => ans.followUpQuestionId) ?? [];
	if (followUps && followUps.length > 0) {
		const familyId = getNextFamilyId(idsToQuestion);
		idsToQuestion[question.id].familyId = familyId;
		followUps.forEach((id) => {
			if (id) {
				idsToQuestion[id].followUpTo = question.id;
				idsToQuestion[id].condition = 'FollowUp';
				idsToQuestion[id].familyId = familyId;
			}
		});
	}
};

const getNextFamilyId = (idsToQuestion: IdToQuestionDict) => {
	return new Set(Object.entries(idsToQuestion).filter(([k, v]) => typeof v.familyId === 'number').map(([k, v]) => v.familyId) ).size;
};
