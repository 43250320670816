import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';

export const ActionButton = styled(Button)`
	font-size: 16px;

	&:not(:first-of-type) {
		margin-left: 8px;
	}
	&:not(:last-child) {
		margin-right: 8px;
	}

	padding: 14px 24px;

	border: unset;

	&.btn-primary {
		background-color: #000;
		color: #fff;
	}

	&.btn-link {
		padding: 0;
		color: #000;
	}
`;
