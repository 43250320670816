import Table from 'react-bootstrap/Table';
import styled from '@emotion/styled';
import { TableBorderColor } from 'constants/colors';

import { useGetShoppersQuery } from './redux/shoppersSlice';
import { PageSection } from './Shoppers';

export const ShoppersTable = () => {
	const { data, isFetching } = useGetShoppersQuery();

	if (isFetching || !data) {
		return <></>;
	}

	return (
		<PageSection>
			<TableContainer>
				<WishiTable>
					<thead>
						<tr>
							<StyledTh>Date</StyledTh>
							<StyledTh>Customer</StyledTh>
							<StyledTh>Items</StyledTh>
							<StyledTh>Recommendation Type</StyledTh>
							<StyledTh>Total</StyledTh>
						</tr>
					</thead>
					<tbody>
						{data.shoppers?.map((shopper, index) => (
							<StyledTr key={index}>
								<StyledTd>
									{shopper.date && new Date(shopper.date).toLocaleDateString('en-US')}
								</StyledTd>
								<StyledTd>{shopper.customer}</StyledTd>
								<StyledTd>{shopper.items}</StyledTd>
								<StyledTd>{shopper.recommendationType}</StyledTd>
								<StyledTd>{shopper.total}</StyledTd>
							</StyledTr>
						))}
					</tbody>
				</WishiTable>
			</TableContainer>
		</PageSection>
	);
};

const TableContainer = styled.div`
	background-color: white;

	border-radius: 18px;

	border: 1px solid ${TableBorderColor};
`;

const WishiTable = styled(Table)`
	background-color: white;

	border-radius: 18px;

	font-size: 16px;

	margin: 0;
`;

const CellAttributes = `
padding: 20px !important;

border-bottom: 1px solid ${TableBorderColor};

&:first-of-type {
  padding-left: 40px !important;
}

&:last-child {
  padding-right: 40px !important;
}
`;

const StyledTh = styled.th`
	font-weight: 400;

	${CellAttributes}
`;

const StyledTr = styled.tr``;

const StyledTd = styled.td`
	${CellAttributes}
`;
