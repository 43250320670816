import { FormEvent, ReactElement, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { RedirectPath } from 'routes';
import { TextEdit } from 'routes/quiz/components/question/form/TextEdit';
import { useForgotPasswordMutation, useLoginMutation } from 'services/user';

import { ActionButton } from 'components/general/ActionButton';

import { loginMessages, loginParams } from './consts';
import { LoginMessage, LoginMessageVariant } from './LoginMessage';

export const EmailLoginForm = () => {
	const navigate = useNavigate();
	const [login] = useLoginMutation();
	const [resetPassword] = useForgotPasswordMutation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [forgotPassword, setForgotPassword] = useState<boolean>(false);

	const [message, setMessage] = useState<
		{ message: string | ReactElement; variant: LoginMessageVariant } | undefined
	>(undefined);

	const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (forgotPassword) {
			resetPassword({ emailAddress: email })
				.unwrap()
				.then(() => {
					setMessage({
						message: (
							<div>
								<span>
									We've sent you an email with instructions to reset your password. Please make sure
									it didn't wind up in your Junk Mail. If you aren't receiving our password reset
									emails,{' '}
								</span>
								<WishiLink href="mailto:hello@wishi.me">email us</WishiLink>
								<span>.</span>
							</div>
						),
						variant: LoginMessageVariant.Success
					});
				})
				.catch(() => {
					setMessage({
						message: 'Error resetting password.',
						variant: LoginMessageVariant.Error
					});
				});
		} else {
			login({ emailAddress: email, password })
				.unwrap()
				.then((result) =>
					navigate(
						`${RedirectPath}?${loginParams.token}=${result.accessToken}&${loginParams.message}=${loginMessages.success}`,
						{
							replace: true
						}
					)
				)
				.catch((error: any) => {
					if (error['status'] === 401) {
						setMessage({
							message: 'Incorrect email or password.',
							variant: LoginMessageVariant.Error
						});
					} else {
						setMessage({
							message: 'Error logging in.',
							variant: LoginMessageVariant.Error
						});
					}
				});
		}
	};

	return (
		<LoginFormContainer onSubmit={onSubmit}>
			<LoginFormTitle>{forgotPassword ? 'Forgot Password' : 'Login'}</LoginFormTitle>
			<TextEdit
				id="login-email"
				label="Email"
				value={email}
				setValue={setEmail}
				style={{ marginBottom: 24 }}
				fieldStyle={LoginFormTextFieldStyle}
			/>
			{!forgotPassword && (
				<TextEdit
					id="login-password"
					label="Password"
					value={password}
					setValue={setPassword}
					type="password"
					style={{ marginBottom: 12 }}
					fieldStyle={LoginFormTextFieldStyle}
				/>
			)}
			{!forgotPassword && (
				<ActionButton
					variant="link"
					onClick={() => {
						setForgotPassword(true);
						setMessage(undefined);
					}}
					style={{ alignSelf: 'flex-end' }}
				>
					Forgot Password
				</ActionButton>
			)}
			{forgotPassword ? (
				<LoginFormButton disabled={!email} type="submit">
					Send Reset Link
				</LoginFormButton>
			) : (
				<LoginFormButton disabled={!email || !password} type="submit">
					Log in
				</LoginFormButton>
			)}
			<ButtonComment>
				Don't have a Wishi account? Request a <WishiLink href="mailto:sales@wishi.me">demo</WishiLink>
			</ButtonComment>
			{message && <LoginMessage variant={message.variant} message={message.message} />}
			<Footer>
				© 2022 Wishi. See <WishiLink href="/">Terms</WishiLink> and <WishiLink href="/">Privacy</WishiLink>.
			</Footer>
		</LoginFormContainer>
	);
};

export const LoginFormContainer = styled(Form)`
	width: 100%;
	height: 100%;
	max-height: 600px;
	padding: 60px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const LoginFormTitle = styled.span`
	font-size: 22px;
	margin-bottom: 16px;
`;

export const LoginFormButton = styled(Button)`
	flex-shrink: 0;
	width: 100%;
	height: 54px;
	border-radius: 27px;
	font-size: 16px;

	margin-top: 24px;

	background-color: #000;
	border: 0;
	color: white;

	&:hover,
	&:focus {
		background-color: #232323;
	}

	&:disabled {
		background-color: #e9e1d5;
	}
`;

export const LoginFormTextFieldStyle = {
	border: '1px solid #000',
	backgroundColor: 'transparent',
	height: 54,
	borderRadius: 27,
	padding: '0 16px'
};

const ButtonComment = styled.div`
	font-size: 16px;

	margin-top: 8px;
	margin-bottom: 20px;
`;

const Footer = styled.div`
	font-size: 13px;

	margin-top: 64px;
`;

export const WishiLink = styled.a`
	display: inline-block;
	color: inherit;

	&:hover {
		color: inherit;
	}
`;
