import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectQuestions } from 'services/quiz';

import { Dropdown } from './form/Dropdown';

export const QuestionLeadsToSelector = (props: {
	id: string;
	questionId?: number;
	questionParentsIds?: Set<number>;
	questionLeadsTo: number | null;
	setQuestionLeadsTo: (questionLeadsTo: number | null) => void;
	disabled?: boolean;
}) => {
	const { questionIds, idToQuestion } = useSelector(selectQuestions);

	// Don't allow selecting ancestors to avoid loops in the quiz.
	// Having no loops will be verified again on server when marking a version active (but not on this call).
	const questionId = props.questionId;
	const questionParents = props.questionParentsIds;
	const allowedLeadingQuestions = useMemo(() => {
		if (questionId == null || idToQuestion == null || questionIds == null) {
			return questionIds;
		}

		const ancestors = new Set<number>(questionParents);
		const stack: number[] = Array.from(questionParents ?? []);

		let currIndex = stack.pop();
		while (currIndex) {
			let currQuestion = idToQuestion[currIndex];

			currQuestion.parentsIds?.forEach((currQuestionParentId) => {
				if (!ancestors.has(currQuestionParentId)) {
					stack.push(currQuestionParentId);
					ancestors.add(currQuestionParentId);
				}
			});

			currIndex = stack.pop();
		}
		return questionIds?.filter((id) => !ancestors.has(id) && id !== questionId);
	}, [questionId, questionIds, questionParents, idToQuestion]);

	if (!idToQuestion) {
		return null;
	}

	return (
		<Dropdown
			id={props.id}
			label="Leads to"
			selectedValue={props.questionLeadsTo?.toString() ?? undefined}
			setValue={(value: string | undefined) => {
				props.setQuestionLeadsTo(value === undefined ? null : +value);
			}}
			options={
				allowedLeadingQuestions?.map((questionId) => ({
					value: questionId.toString(),
					name: idToQuestion[questionId].name ?? idToQuestion[questionId].title
				})) ?? []
			}
			disabled={props.disabled}
			undefinedOptionText="None"
		/>
	);
};
