import React from 'react';
import styled from '@emotion/styled';

import { TScrollToPlansFn } from '../types';

const FooterContainer = styled.footer`
	background: var(--fpAccentColor);
	border-radius: var(--fpSectionBorderRadius) var(--fpSectionBorderRadius) 0 0;
	padding: 35px 105px 16px 91px;
	margin-top: 58px;
	color: #ffffff;

	@media (max-width: 1024px) {
		margin-top: 0;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
	}
`;

const Logo = styled.img`
	width: 82px;
	@media (max-width: 1024px) {
		order: 2;
		margin: 70px auto 0 auto;
		width: 52px;
	}
`;

const Links = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0;
	margin-top: 19px;

	li {
		width: calc(100% / 3);

		&:nth-child(2) {
			text-align: center;
		}

		&:last-child {
			margin-left: auto;
			width: auto;
		}

		a,
		button {
			display: block;
			color: #ffffff;
			font-size: 22px;
			line-height: 45px;
			text-decoration: none;
			background: transparent;
			border: none;

			&:hover {
				color: var(--fpHoverColor);
			}
		}
	}

	@media (max-width: 1024px) {
		display: block;
		order: 1;

		li {
			width: auto;
			text-align: left !important;

			a,
			button {
				font-size: 16px;
				line-height: 32px;
			}
		}
	}
`;

const Separator = styled.div`
	height: 1px;
	background: #ffffff;
	margin-top: 50px;

	@media (max-width: 1024px) {
		order: 0;
		margin-top: 0;
		background: #999999;
	}
`;

const Address = styled.div`
	font-size: 16px;
	line-height: 32px;
	text-align: center;
	margin: 28px 0 16px 0;

	@media (max-width: 1024px) {
		order: 3;
		font-size: 16px;
		line-height: 32px;
		margin: 6px 0 0 0;
	}
`;

const FixedFooterContainer = styled.div`
	--fpFixedFooterHeight: 96px;

	display: none;
	order: 4;
	height: var(--fpFixedFooterHeight);
	margin-top: 20px;

	@media (max-width: 1024px) {
		display: block;
	}
`;

const FixedFooter = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	bottom: 0;
	height: var(--fpFixedFooterHeight);
	background-color: var(--fpAccentColor);
`;

const FixedFooterButton = styled.button`
	font-size: 16px;
	line-height: 16px;
	padding: 18px 20px 17px 20px;
	border: none;
	border-radius: 100px;
	background-color: #ffffff;
	color: var(--fpAccentColor);
`;

export const Footer: React.FC<{ scrollToPlans: TScrollToPlansFn }> = ({ scrollToPlans }) => {
	return (
		<FooterContainer>
			<Logo src="/images/frontPage/wishi_logo.png" />
			<Links>
				<li>
					<a href="http://wishi.me">For Consumers</a>
				</li>
				<li>
					<a href="https://docs.wishi.me">Documentation </a>
				</li>
				<li>
					<button onClick={scrollToPlans}>View Pricing</button>
					<a href="https://wishi.zendesk.com/hc/en-us " target="_blank" rel="noreferrer">
						Help Center
					</a>
				</li>
			</Links>
			<Separator />
			<Address>215 Park Ave S floor 11, New York, NY 10003</Address>
			<FixedFooterContainer>
				<FixedFooter>
					<FixedFooterButton onClick={scrollToPlans}>View Pricing</FixedFooterButton>
				</FixedFooter>
			</FixedFooterContainer>
		</FooterContainer>
	);
};
