import Spinner from 'react-bootstrap/Spinner';
import styled from '@emotion/styled';

export const WishiSpinner = (props: { position: 'top-left' | 'center' }) => (
	<SpinnerContainer style={props.position === 'center' ? { justifyContent: 'center', alignItems: 'center' } : {}}>
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</SpinnerContainer>
);

const SpinnerContainer = styled.div`
	display: flex;
	flex-direction: row;

	width: 100%;
	height: 100%;
`;
