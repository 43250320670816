import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { palette } from '../constants/styles';

import LeftNav from './left-nav';
import TopBar from './top-bar';

const PageLayout: React.FC<PropsWithChildren> = ({ children }) => (
	<Container>
		<TopBarContainer>
			<TopBar />
		</TopBarContainer>
		<InnerContainer>
			<LeftNav />
			<ContentContainer>{children}</ContentContainer>
		</InnerContainer>
	</Container>
);

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
`;

const TopBarContainer = styled.div`
	flex: 0 0 auto;
`;

const InnerContainer = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: row;
	overflow: auto;
`;

const ContentContainer = styled.div`
	flex: 1 1 auto;
	overflow: auto;
	background: ${palette.pageBackground};
`;

export default PageLayout;
