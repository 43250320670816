import styled from '@emotion/styled';
import { ReactComponent as WishiForBrands } from 'assets/images/wishi-for-brands.svg';
import logoImage from 'assets/images/wishi-icon-logo.png';
import { TopBarBackground, TopBarBorder } from 'constants/colors';
import { useGetPartnerQuery } from 'services/user';

import UserPanel from 'components/user-panel';

const TopBar = () => {
	const { data: partner } = useGetPartnerQuery();

	return (
		<Container>
			<LeftItem>
				<LeftContainer>
					{partner && <StyledImage src={partner?.logoUrl ?? logoImage} />}
					{partner && !partner?.logoUrl && <Title>{partner?.name}</Title>}
				</LeftContainer>
			</LeftItem>
			<Item>
				<WishiForBrands height="24px" width="100%" />
			</Item>
			<RightItem>
				<UserPanel />
			</RightItem>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	background-color: ${TopBarBackground};
	padding: 0 24px;
	border-bottom: 1px solid ${TopBarBorder};
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Item = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const LeftItem = styled(Item)`
	justify-content: flex-start;
`;
const RightItem = styled(Item)`
	justify-content: flex-end;
`;

const StyledImage = styled.img`
	max-height: 16px;
	max-width: 100%;
`;

const Title = styled.div`
	font-size: 14px;
	margin-left: 10px;
	font-weight: 100;
`;

export default TopBar;
