import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useURLState } from 'hooks';
import { useGetUserQuery } from 'services/user';
import { TComparePeriod, TDatePeriod } from 'utils';

import { ComparisonDatePicker } from 'components';

import { palette } from '../../../constants/styles';

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 64px 0 35px 0;
	margin-bottom: 70px;
	border-bottom: 1px solid ${palette.elementBorderColor};
`;

const PageTitle = styled.h1`
	font-size: 32px;
	margin-right: 100px;
	white-space: nowrap;
`;

export const PageHeader: FC = () => {
	const { data: user } = useGetUserQuery();
	const { urlState, setURLState } = useURLState();
	const { datePeriod, comparePeriod } = urlState;
	const onDatesChange = (datePeriod?: TDatePeriod, comparePeriod?: TComparePeriod) => {
		setURLState({
			...urlState,
			datePeriod,
			comparePeriod:
				datePeriod === TDatePeriod.allTime || comparePeriod === TComparePeriod.none ? undefined : comparePeriod
		});
	};

	useEffect(() => {
		setURLState({
			...urlState,
			datePeriod: urlState.datePeriod || TDatePeriod.allTime
		});
	}, [urlState, setURLState]);

	return (
		<Container>
			<PageTitle>Welcome back, {user?.name}</PageTitle>
			<ComparisonDatePicker
				period={datePeriod as TDatePeriod}
				compareTo={comparePeriod as TComparePeriod}
				onChange={onDatesChange}
			/>
		</Container>
	);
};
