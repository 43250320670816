import { TDashboardItemTrend, TDashboardResponse } from 'services/insights';

type TDashboardMap = Record<
	string,
	{
		title: string;
		items: string[];
		tooltipTexts?: Record<string, string>;
	}
>;

const dashboardMap: TDashboardMap = {
	conversionFunnel: {
		title: 'Conversion Funnel',
		items: ['quizProgressStarted', 'quizProgressCompleted', 'quizProgressActive', 'quizProgressShoppers'],
		tooltipTexts: {
			quizProgressActive:
				'‘Active Client’ refers to clients who are actively engaging with products through clicks within the chat during your specified timeframe. This metric does not account for actions taken through alternative communication channels such as email or SMS.',
			quizProgressShoppers: 'Shoppers data is provided by the partner'
		}
	},
	adoptionPerformance: {
		title: 'Adoption Performance',
		items: ['activeClients', 'boards', 'itemClicks', 'itemsA2B', 'itemsA2W', 'satisfaction'],
		tooltipTexts: {
			itemsA2B: 'Add to Bag data is provided by the partner',
			itemsA2W: 'Add to Wishlist data is provided by the partner',
			satisfaction: 'Satisfaction rating is between 1-5, 5 being the highest'
		}
	},
	sla: {
		title: 'SLA',
		items: ['avgResponseTime', 'avgLookSentTime']
	},
	boardsFeedback: {
		title: 'Boards Feedback ',
		items: ['boardsWithFeedback', 'love', 'restyle']
	},
	recommendations: {
		title: 'Recommendations',
		items: ['boardsSeen', 'itemsSent', 'itemsPurchased']
	},
	salesReport: {
		title: 'Business Performance',
		items: ['shoppers', 'spendPerShopper', 'purchaseFrequency']
	}
};

const trendLabels = {
	equal: 'No change since previous period',
	inc: 'Increased since previous period',
	dec: 'Decrease since previous period'
};

export type TMappedDashboardItem = {
	header: string;
	value: string;
	secondary?: string;
	trend?: TDashboardItemTrend;
	trendText?: string;
	comparison?: number;
	comparisonPercent?: number;
	tooltipText?: string;
	onClick?: () => void;
};
type TMappedDashboardData = Record<string, { title: string; items: TMappedDashboardItem[] }>;

export const dashboardDataMapper = (dashboardData: TDashboardResponse): TMappedDashboardData => {
	const result: TMappedDashboardData = {};

	Object.entries(dashboardMap).forEach(([section, props]) => {
		const { title, items, tooltipTexts } = props;

		result[section] = {
			title,
			items: items.map((itemKey) => {
				const { view, current, comparison } = dashboardData[itemKey];
				const { value, secondary, header } = view;
				const mappedItemData: TMappedDashboardItem = {
					header,
					value,
					secondary
				};

				if (tooltipTexts && tooltipTexts[itemKey]) {
					mappedItemData.tooltipText = tooltipTexts[itemKey];
				}

				if (typeof comparison === 'number') {
					const trend = comparison === current ? 'equal' : comparison < current ? 'inc' : 'dec';
					const trendText = trendLabels[trend];
					let comparisonPercent = 0;

					if (comparison !== current) {
						if (comparison === 0) {
							comparisonPercent = current * 100;
						} else {
							comparisonPercent = (Math.abs(current - comparison) / comparison) * 100;
						}
					}

					return {
						...mappedItemData,
						trend,
						trendText,
						comparisonPercent,
						comparison
					};
				}

				return mappedItemData;
			})
		};
	});

	return result;
};
