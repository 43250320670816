import { ReactElement } from 'react';
import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';

export const WishiRadio = (props: {
	groupId: string;
	id: string;
	label: ReactElement;
	isSelected: boolean;
	setSelected: () => void;
}) => {
	return (
		<FormCheck type="radio" key={props.id} id={props.id} name={props.groupId}>
			<FormCheckInput
				type="radio"
				checked={props.isSelected}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					if (e.target.checked) {
						props.setSelected();
					}
				}}
			/>
			<Form.Check.Label style={{ width: '100%' }}>{props.label}</Form.Check.Label>
		</FormCheck>
	);
};

const FormCheck = styled(Form.Check)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
`;

const FormCheckInput = styled(Form.Check.Input)`
	margin-top: 0;
	margin-right: 16px;
	margin-left: 0 !important;

	border-color: #cfccca;
	&:checked[type='radio'] {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e");
		background-color: #fff;
		border-color: #cfccca;
	}
	&:focus[type='radio'] {
		box-shadow: 0 0 1px 1px #cfccca;
	}
`;
