import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

import { TMappedDashboardItem } from '../mappers';

import { Metric } from './Metric';

const Container = styled.div`
	--metricItemPadding: 24px;
	--metricTitleFontSize: 14px;
	--metricValueFontSize: 32px;
	--metricValueMargin: 3px 0 25px 0;
	--metricSecondaryValueMargin: 0 0 5px 10px;
	--metricSeparatorMargin: 17px -24px 11px -24px;

	margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 16px;
`;

const Items = styled.div`
	display: inline-flex;
	background: ${palette.elementBackground};
	border: 1px solid ${palette.elementBorderColor};
	border-radius: 18px;
`;

const Item = styled.div`
	min-width: 207px;
	border-left: 1px solid ${palette.elementBorderColorLight};

	&:first-of-type {
		border-left: 0 none;
	}
`;

type TProps = {
	title: string;
	items: TMappedDashboardItem[];
};

export const CombinedSection: FC<TProps> = ({ title, items }) => {
	return (
		<Container>
			<SectionTitle>{title}</SectionTitle>
			<Items>
				{items.map((item, index) => (
					<Item key={index}>
						<Metric {...item} />
					</Item>
				))}
			</Items>
		</Container>
	);
};
