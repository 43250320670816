export const palette = {
	positiveBadgeText: '#0e6245',
	positiveBadgeBackground: '#cbf4c9',
	neutralBadgeText: '#4f66b0',
	neutralBadgeBackground: '#e3e8ee',
	negativeBadgeText: '#983705',
	negativeBadgeBackground: '#f8e5b9',
	primaryColor: '#f88379',
	dangerColor: '#df1b0a',
	primaryGray: '#f4f4f4',
	lightGray: '#8e8e93',
	darkGray: '#3a3a3c',
	pageBackground: '#F4F2EF',
	elementBorderColor: '#CDBFB3',
	elementBorderColorLight: '#E5DDD7',
	elementBackground: '#ffffff',
	positiveTrend: '#58B25B',
	neutralTrend: '#EAA760',
	negativeTrend: '#98191D'
};
