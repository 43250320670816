export enum TDatePeriod {
	today = 'today',
	last7Days = 'last7Days',
	last4Weeks = 'last4Weeks',
	last3Months = 'last3Months',
	last6Months = 'last6Months',
	last12Months = 'last12Months',
	currentMonth = 'currentMonth',
	currentQuarter = 'currentQuarter',
	currentYear = 'currentYear',
	allTime = 'allTime'
}

export enum TComparePeriod {
	previousPeriod = 'previousPeriod',
	previousMonth = 'previousMonth',
	previousQuarter = 'previousQuarter',
	previousYear = 'previousYear',
	none = 'none'
}

export const getPeriodDate = (period: TDatePeriod): Date => {
	switch (period) {
		case TDatePeriod.today:
			return Date.today();
		case TDatePeriod.last7Days:
			return Date.last().week();
		case TDatePeriod.last4Weeks:
			return (4).weeks().ago();
		case TDatePeriod.last3Months:
			return (3).months().ago();
		case TDatePeriod.last6Months:
			return (6).months().ago();
		case TDatePeriod.last12Months:
			return Date.last().year();
		case TDatePeriod.currentMonth:
			return Date.today().set({ day: 1 });
		case TDatePeriod.currentQuarter: {
			const monthNum = Date.today().getMonth();
			return Date.today().set({
				day: 1,
				month: Math.floor(monthNum / 3) * 3
			});
		}
		case TDatePeriod.currentYear: {
			return Date.today().set({
				day: 1,
				month: 0
			});
		}
		case TDatePeriod.allTime: {
			return new Date('01/01/2022');
		}
	}
};

export const getComparedPeriod = (
	period: TDatePeriod,
	compared: TComparePeriod
): [compareFrom: Date, compareTo: Date] => {
	switch (compared) {
		case TComparePeriod.none:
			return [getPeriodDate(period), Date.today()];
		case TComparePeriod.previousPeriod:
			switch (period) {
				case TDatePeriod.today:
					return [Date.previous().day(), Date.previous().day()];
				case TDatePeriod.last7Days:
					return [(2).weeks().ago(), (1).week().ago().addDays(-1)];
				case TDatePeriod.last4Weeks:
					return [(8).weeks().ago(), (4).weeks().ago().addDays(-1)];
				case TDatePeriod.last3Months:
					return [(6).months().ago(), (3).months().ago().addDays(-1)];
				case TDatePeriod.last6Months:
					return [(12).months().ago(), (6).months().ago().addDays(-1)];
				case TDatePeriod.last12Months:
					return [(24).months().ago(), (12).months().ago().addDays(-1)];
				case TDatePeriod.currentMonth:
					return [getPeriodDate(period).addMonths(-1), getPeriodDate(period).addDays(-1)];
				case TDatePeriod.currentQuarter:
					return [getPeriodDate(period).addMonths(-3), getPeriodDate(period).addDays(-1)];
				case TDatePeriod.currentYear:
					return [getPeriodDate(period).addYears(-1), getPeriodDate(period).addDays(-1)];
				case TDatePeriod.allTime:
					return [getPeriodDate(period), Date.today()];
			}
			break;
		case TComparePeriod.previousMonth:
			return [Date.today().set({ day: 1 }).addMonths(-1), Date.today().set({ day: 1 }).addDays(-1)];
		case TComparePeriod.previousQuarter:
			const monthNum = Date.today().getMonth();
			const firstOfThisQuarter = Date.today().set({
				day: 1,
				month: Math.floor(monthNum / 3) * 3
			});
			return [new Date(firstOfThisQuarter).addMonths(-3), firstOfThisQuarter.addDays(-1)];
		case TComparePeriod.previousYear:
			return [
				Date.today().set({ month: 0, day: 1 }).addYears(-1),
				Date.today().set({ month: 0, day: 1 }).addDays(-1)
			];
	}
};

const shortDateFormat = 'MMM d, yyyy';

export const getShortDatePeriod = (period: TDatePeriod) =>
	`${getPeriodDate(period).toString(shortDateFormat)} - ${Date.today().toString(shortDateFormat)}`;

export const getShortComparePeriod = (period: TDatePeriod, compare: TComparePeriod) => {
	const [from, to] = getComparedPeriod(period, compare);
	return `${from.toString(shortDateFormat)} - ${to.toString(shortDateFormat)}`;
};

const calculateDatesFromPeriods = (period: TDatePeriod, compare: TComparePeriod = TComparePeriod.none) => {
	const [compareFrom, compareTo] = getComparedPeriod(period, compare);
	return {
		periodFrom: getPeriodDate(period),
		periodTo: Date.today(),
		compareFrom,
		compareTo
	};
};

export const getFormattedPeriods = (period: TDatePeriod, compare?: TComparePeriod, format = 'yyyy-MM-dd') => {
	const { periodFrom, periodTo, compareFrom, compareTo } = calculateDatesFromPeriods(period, compare);
	return {
		periodFrom: periodFrom.toString(format),
		periodTo: periodTo.toString(format),
		compareFrom: compareFrom.toString(format),
		compareTo: compareTo.toString(format)
	};
};
export const getISOFormattedPeriods = (period: TDatePeriod, compare?: TComparePeriod) => {
	const { periodFrom, periodTo, compareFrom, compareTo } = calculateDatesFromPeriods(period, compare);
	return {
		periodFrom: periodFrom.toISOString(),
		periodTo: periodTo.toISOString(),
		compareFrom: compareFrom.toISOString(),
		compareTo: compareTo.toISOString()
	};
};
