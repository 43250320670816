import { FC, useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';
import ClientChat, {
	ChatStatuses,
	Config,
	OnboardingAnswer,
	OnboardingResponseWithAnswerNumber,
	UpdateOnboardingResponse
} from '@wishi_fashion/web-capsule-sdk';
import { AnswerOption, IdToQuestionDict, QuizQuestionUi } from 'services/quiz';
import { useGetPartnerQuery, useGetUserQuery } from 'services/user/user.service';
import { v4 as uuidv4 } from 'uuid';

import { WishiSpinner } from 'components/general/WishiSpinner';

import '@wishi_fashion/web-capsule-sdk/lib/wishi.css';

const EditLoadingDelayMs = 600;
const LoadingKey = 'loading-key';
const partners = ['diesel'];

type TProps = {
	isSingleQuestion?: boolean;
	firstQuestionId: number;
	idToQuestion: IdToQuestionDict;
};

export const SdkWrapper: FC<TProps> = ({ isSingleQuestion = false, firstQuestionId, idToQuestion }) => {
	const { data: user } = useGetUserQuery();
	const { data: partner } = useGetPartnerQuery();
	const [questionIdsStack, setQuestionIdsStack] = useState<number[]>([]);
	const [followUps, setFollowUps] = useState<number[]>([]);
	const [sdkConfig, setSDKConfig] = useState<Partial<Config>>({});

	const [clientChatKey, setClientChatKey] = useState<string>(LoadingKey);

	const [hasEnded, setHasEnded] = useState<boolean>(false);

	const reloadQuiz = () => {
		setQuestionIdsStack([]);
		setFollowUps([]);
		setClientChatKey(uuidv4());
	};

	const getNextQuestion = useCallback(
		(response: OnboardingResponseWithAnswerNumber | undefined, questionIdsStack: number[]) => {
			if (isSingleQuestion) {
				const singleQuestion = getQuestion(firstQuestionId, idToQuestion);
				return {
					...singleQuestion,
					previouslyAnswered: undefined,
					previousAdditionalComments: undefined,
					answerNumber: 0
				};
			}

			if (questionIdsStack.length) {
				const currQuestion = getQuestion(questionIdsStack[questionIdsStack.length - 1], idToQuestion);

				if (response == null) {
					// This is a call for first question on rerendering- coming back to current state.
					return {
						...currQuestion,
						previouslyAnswered: undefined,
						previousAdditionalComments: undefined,
						answerNumber: questionIdsStack.length
					};
				} else {
					const { nextQuestionId, newFollowUps } = getAnswerLeadTo(
						currQuestion,
						response.response.answer,
						followUps,
						idToQuestion
					);
					setFollowUps(newFollowUps);
					if (nextQuestionId == null) {
						setHasEnded(true);
						return {
							...currQuestion,
							previouslyAnswered: undefined,
							previousAdditionalComments: undefined,
							answerNumber: questionIdsStack.length
						};
					} else {
						const nextQuestion = getQuestion(nextQuestionId, idToQuestion);

						const res = {
							...nextQuestion,
							previouslyAnswered: undefined,
							previousAdditionalComments: undefined,
							answerNumber: questionIdsStack.length + 1
						};
						setQuestionIdsStack([...questionIdsStack!, res.id]);
						return res;
					}
				}
			} else {
				const firstQuestion = getQuestion(firstQuestionId, idToQuestion);
				const res = {
					...firstQuestion,
					previouslyAnswered: undefined,
					previousAdditionalComments: undefined,
					answerNumber: 0
				};
				setQuestionIdsStack([res.id]);
				return res;
			}
		},
		[idToQuestion, firstQuestionId, isSingleQuestion, followUps]
	);
	const getPrevQuestion = useCallback(
		(questionIdsStack: number[]) => {
			if (isSingleQuestion) {
				const singleQuestion = getQuestion(firstQuestionId, idToQuestion);
				return {
					...singleQuestion,
					previouslyAnswered: undefined,
					previousAdditionalComments: undefined,
					answerNumber: 0
				};
			}

			if (questionIdsStack.length < 2) {
				throw new Error('Illegal questions state');
			}

			const newQuestionIdsStack = [...questionIdsStack];
			newQuestionIdsStack.pop();
			setQuestionIdsStack(newQuestionIdsStack);

			const prevQuestion = getQuestion(questionIdsStack[newQuestionIdsStack.length - 1], idToQuestion);

			return {
				...prevQuestion,
				previouslyAnswered: undefined,
				previousAdditionalComments: undefined,
				answerNumber: newQuestionIdsStack!.length
			};
		},
		[idToQuestion, firstQuestionId, isSingleQuestion]
	);

	useEffect(() => {
		if (isSingleQuestion) {
			setClientChatKey(LoadingKey);
			const timer = setTimeout(reloadQuiz, EditLoadingDelayMs);
			return () => clearTimeout(timer);
		} else {
			reloadQuiz();
		}
	}, [isSingleQuestion]);

	useEffect(() => {
		if (partner) {
			const name = partners.includes(partner.name) ? partner.name : 'default';

			require(`./partners/${name}/style.css`);
			setSDKConfig(require(`./partners/${name}/config.ts`).config);
		}
	}, [partner]);

	if (!user || !partner) {
		return <></>;
	}

	const voidFn = () => {};
	const voidAsyncFn = async () => {};

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.WishiAnalytics = { track: voidFn, setSuperProps: voidFn };

	return (
		<Container>
			{hasEnded ? (
				<EndScreenContainer>
					<EndScreenContentContainer>
						<EndScreenTextSpan>End of quiz</EndScreenTextSpan>
						<EndScreenButtonsContainer>
							<Button onClick={() => setHasEnded(false)}>Back</Button>
							<div style={{ width: '24px' }} />
							<Button
								onClick={() => {
									reloadQuiz();
									setHasEnded(false);
								}}>
								Restart
							</Button>
						</EndScreenButtonsContainer>
					</EndScreenContentContainer>
				</EndScreenContainer>
			) : clientChatKey === LoadingKey ? (
				<WishiSpinner position="center" />
			) : (
				<ClientChat
					config={sdkConfig as Config}
					partnerKey={'rQaeBiUQgH0KaEt8'}
					partnerUserId={user.id}
					status={ChatStatuses.ONBOARDING}
					respondAndGetNextOnboardingQuestion={async (userId, response?) =>
						getNextQuestion(response, questionIdsStack)
					}
					getPreviousOnboardingQuestion={async () => getPrevQuestion(questionIdsStack)}
					twilioUserToken={''}
					twilioChannelSid={''}
					wishiUserId={''}
					createUserAccessToken={async () => ''}
					sendMessageReply={() => new Promise(() => {})}
					onItemClick={voidFn}
					// uploadPhoto={async () => ({ url: '', fileName: '' })}
					// uploadPhotoOnQuiz={async () => ({ url: '', fileName: '' })}
					uploadQuizPhoto={() => Promise.resolve(null)}
					addToCart={voidFn}
					removeFromCart={voidFn}
					onNewLookReceived={voidFn}
					cartItems={[]}
					onQuizStepChanged={voidFn}
					log={voidAsyncFn}
					logEvent={async () => null}
					onEventTracking={voidFn}
					// getPiecesPrice={voidAsyncFn}
					// getUserPieces={voidAsyncFn}
					// getUserStyleboards={voidAsyncFn}
					onChatReady={voidFn}
					reportAllMessagesRead={voidAsyncFn}
					onOnboardingStepChanged={voidFn}
					updateOnboardingStatus={() => Promise.resolve({} as UpdateOnboardingResponse)}
				/>
			)}
		</Container>
	);
};

const getQuestion = (questionId: number, idToQuestion: IdToQuestionDict) => {
	const question = idToQuestion[questionId];
	if (question == null) {
		throw new Error('Illegal questions state');
	}

	return question;
};

const getAnswerLeadTo = (
	currQuestion: QuizQuestionUi,
	answers: OnboardingAnswer[] | undefined,
	currentFollowUps: number[],
	idToQuestion: IdToQuestionDict
): { nextQuestionId: number | null; newFollowUps: number[] } => {
	let nextQuestionId = currQuestion.leadsTo;
	let allFollowUps = [...currentFollowUps];

	if (answers && answers.length > 0) {
		const allKeys = answers.map((a) => a.key);
		const firstAnswerKey = answers[0].key;
		currQuestion.answers?.forEach((answerOption: AnswerOption) => {
			if (answerOption.key === firstAnswerKey && answerOption.leadsTo !== undefined) {
				nextQuestionId = answerOption.leadsTo;
			}
			if (allKeys.includes(answerOption.key) && answerOption.followUpQuestionId !== undefined) {
				allFollowUps.push(answerOption.followUpQuestionId);
			}
		});
	}
	if (nextQuestionId) {
		const nextQuestion = getQuestion(nextQuestionId, idToQuestion);
		if (nextQuestion.condition === 'FollowUp' && !allFollowUps.includes(nextQuestionId)) {
			return getAnswerLeadTo(nextQuestion, undefined, allFollowUps, idToQuestion);
		}
	}
	return { nextQuestionId, newFollowUps: allFollowUps };
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const EndScreenContainer = styled.div`
	padding-top: 48px;

	width: 100%;
	height: 100%;
`;

const EndScreenContentContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const EndScreenTextSpan = styled.span`
	font-size: 2em;
	text-align: center;
	margin-bottom: 48px;
`;

const EndScreenButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
`;
