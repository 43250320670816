import styled from '@emotion/styled';

export const Pill = styled.div`
	padding: 8px 24px;
	line-height: 1;
	color: #fff;
	font-weight: 400;
	font-size: 11px;
	border-radius: 14px;
	background-color: #000;
`;
