import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';
import { ButtonBorderColor, SecondaryButtonTextColor } from 'constants/colors';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

export const AddButton = (props: {
	onClick: () => void;
	text: string;
	disabled?: boolean;
	borderRadius?: number;
	fontSize?: number;
}) => (
	<AddAnswerButtonInnerContainer>
		<AddAnswerButtonStyled
			variant="plain"
			onClick={props.onClick}
			disabled={props.disabled}
			style={{
				borderRadius: props.borderRadius != null ? props.borderRadius : undefined,
				fontSize: props.fontSize != null ? props.fontSize : undefined
			}}
		>
			<PlusIcon width={12} height={12} style={{ marginRight: 8 }} />
			<span>{props.text}</span>
		</AddAnswerButtonStyled>
	</AddAnswerButtonInnerContainer>
);

const AddAnswerButtonInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const AddAnswerButtonStyled = styled(Button)`
	border: 1px solid ${ButtonBorderColor};
	color: ${SecondaryButtonTextColor};

	font-size: 13px;

	padding: 8px 16px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;
