import { rootSplitApi } from 'redux/base-api';
import { getISOFormattedPeriods } from 'utils';

import { TQuizProgressionQueryParams, TQuizProgressionQueryResponse } from './analytics.types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Analytics'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getQuizProgression: build.query<TQuizProgressionQueryResponse, TQuizProgressionQueryParams>({
			query: ({ versionId, period }) => {
				const { periodFrom: date, periodTo: to } = getISOFormattedPeriods(period);

				return {
					url: `/analytics/partners/quiz/${versionId}/quizQuestionAnswerStats`,
					method: 'GET',
					params: { date, to }
				};
			},
			transformResponse: (response: { questionAnswerStats: TQuizProgressionQueryResponse }) =>
				response.questionAnswerStats,
			providesTags: () => [{ type: 'Analytics', id: 'QuizProgression' }]
		})
	}),
	overrideExisting: false
});

export const { useGetQuizProgressionQuery } = extendedApi;
