import { storageParams } from 'constants/general';
import { Dispatch, MiddlewareAPI } from 'redux';
import { signOut } from 'services/user';

export const apiMiddleware =
	({ dispatch }: MiddlewareAPI<Dispatch>) =>
	(next: Dispatch) =>
	(action: { type: string; payload: { status: number } }) => {
		const response = next(action);
		if (action.type === 'api/executeQuery/rejected' && action.payload?.status === 401) {
			localStorage.removeItem(storageParams.accessToken);
			dispatch(signOut());
		}
		return response;
	};
