import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RootState } from 'redux/store';
import { useCloseQuestionDetails } from 'routes/quiz/hooks/CloseQuestionDetailsHook';
import { useQuizEditAllowed } from 'routes/quiz/hooks/QuizEditAllowedHook';
import { useGetQuizVersionsQuery } from 'services/versions';

import { ActionButton } from 'components/general/ActionButton';

import { QuestionDetails } from '../QuestionDetailsHook';

export const QuestionActions = (props: {
	questionId: number | undefined;
	questionDetails: QuestionDetails;
	disabled?: boolean;
}) => {
	const selectedVersionId = useSelector((state: RootState) => state.versions.selectedVersionId);
	const { selectedVersion } = useGetQuizVersionsQuery(undefined, {
		selectFromResult: ({ data }) => ({
			selectedVersion: data?.find((version) => version.id === selectedVersionId)
		})
	});

	const closeQuestionDetails = useCloseQuestionDetails();
	const quizEditAllowed = useQuizEditAllowed();

	if (!selectedVersion) {
		return null;
	}

	return (
		<ActionsContainer>
			<ActionButton variant="link" onClick={closeQuestionDetails} style={{ padding: '14px 0' }}>
				Cancel
			</ActionButton>

			{quizEditAllowed && (
				<ActionButton
					variant="primary"
					onClick={() => {
						props.questionDetails.saveQuestion();
						closeQuestionDetails();
					}}
					disabled={props.disabled}
				>
					Save Question
				</ActionButton>
			)}
		</ActionsContainer>
	);
};

const ActionsContainer = styled.div`
	flex-grow: 0;

	border-top: 1px solid #a3a3a3;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding: 8px 48px;
`;
